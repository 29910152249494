import {
  Callout,
  Color,
  Grid,
  Modal,
  Content,
  FontAwesome,
  Link,
  Text,
} from 'component-library';
import { observer } from 'mobx-react';
import RdMultipleProjectsTable from './RdMultipleProjectsTable';
import { RdProject } from 'lib/interfaces';
import { RdProjectWeightsTable } from './RdProjectWeightsTable';
import React, { useEffect, useState } from 'react';
import { useTaxCreditsStores } from 'stores/useStores';
import { FourPartTestModalContent } from './FourPartTestModalContent';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  bulletPoint: {
    display: 'flex',
    justifyContent: 'space-around',
    '& svg': {
      flexBasis: '32px',
      stroke: '#f6f7f2', // info100
      strokeWidth: '1px',
    },
    '& p': {
      flex: 1,
    },
    '& > div': {
      flex: 1,
    },
  },
  bulletPointGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: '16px',
    padding: '16px 0',
    '& svg': {
      flexBasis: '32px',
      stroke: '#f6f7f2', // info100
      strokeWidth: '1px',
    },
    '& p': {
      flex: 1,
    },
    '& > div': {
      flex: 1,
    },
  },
  contentWithIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '8px',
    '& i': {
      marginRight: '11px',
    },
  },
  listItems: {
    marginLeft: 15,
    paddingLeft: 0,
    fontSize: '15px',
    color: Color.neutral._80,
  },
  icon: {
    marginTop: '4px',
  },
}));

interface RdBusinessComponentsProps {
  programId: number;
  projects?: RdProject[];
}

export const RdBusinessComponents = observer(
  ({ programId, projects = [] }: RdBusinessComponentsProps) => {
    const classes = useStyles();
    const { businessComponents } = useTaxCreditsStores();

    const [showMoreRdBusinessCallout, setShowMoreRdBusinessCallout] =
      useState<boolean>(true);

    useEffect(() => {
      if (businessComponents.projects.length !== projects.length) {
        businessComponents.setProjects(projects);
      }
    }, [projects]);

    const ContentWithIcon: React.FC<{
      text: React.ReactElement;
      isSuccess?: boolean;
    }> = ({ text, isSuccess }) => {
      return (
        <div className={classes.contentWithIcon}>
          <FontAwesome
            name={isSuccess ? 'circle-check sharp' : 'circle-xmark sharp'}
            variant='solid'
            color={
              isSuccess ? Color.semantic.$success50 : Color.semantic.$error50
            }
            size={18}
            className={classes.icon}
          />
          {text}
        </div>
      );
    };

    const renderRdMultipleProjectsTable = () => {
      return (
        <Grid columns={12}>
          <Grid.Cell columns={4}></Grid.Cell>
          <Grid.Cell columns={8}>
            <RdMultipleProjectsTable
              programId={programId}
              onContinue={() => {
                businessComponents.setProjectsSubmitted(true);
              }}
              projects={businessComponents.projects}
            />
          </Grid.Cell>
        </Grid>
      );
    };

    const renderRdProjectWeightsTable = () => {
      return (
        <Grid columns={12}>
          <Grid.Cell columns={4}></Grid.Cell>
          <Grid.Cell columns={8}>
            <RdProjectWeightsTable
              programId={programId}
              onContinue={() => {
                businessComponents.setBusinessComponentSectionComplete(true);
              }}
              projects={businessComponents.projects}
            />
          </Grid.Cell>
        </Grid>
      );
    };

    const renderRDProjectEligibility = () => {
      return (
        <Callout
          title='Eligibility of R&D business components or projects'
          hasShowMore
          showMore={showMoreRdBusinessCallout}
          showMoreOnClick={() =>
            setShowMoreRdBusinessCallout(!showMoreRdBusinessCallout)
          }
          color={Color.blue._60}
          cta={
            <Link
              external
              href={`https://mainstreet1.my.site.com/help/s/article/R-D-business-components`}
              variant='medium'
              text='More about business components'
            />
          }
        >
          <>
            <Text variant='medium'>
              An R&D &quot;business component&quot; is a distinct business
              effort that...
              <br />
              <Text tag='span'>
                <ul className={classes.listItems}>
                  <li>Independently meets the four-part test</li>
                  <li>
                    Is at least 80% involved in a process of technical
                    experimentation
                  </li>
                  <li>
                    Can be brand-new or an improvement upon an existing product
                    or process
                  </li>
                </ul>
              </Text>
            </Text>
            <Text variant='medium' paddingBottom={16}>
              Many early-stage startups have one R&D business component, but you
              may have several.
            </Text>
            <Text variant='medium'>
              Common business components that are eligible for R&D, provided
              they meet the criteria above:
            </Text>
            <Content
              flex
              paddingTopBottom={16}
              justifyContent='space-around'
              className={classes.bulletPoint}
            >
              <ContentWithIcon
                isSuccess
                text={
                  <Text variant='medium'>
                    Computer software
                    <br />
                    <Text tag='span'>
                      <ul className={classes.listItems}>
                        <li>Developing a brand-new computer software</li>
                        <li>Developing a new feature set</li>
                        <li>Improving performance and scalability</li>
                        <li>
                          Improving the functionality of existing features
                        </li>
                      </ul>
                    </Text>
                  </Text>
                }
              />
              <ContentWithIcon
                isSuccess
                text={
                  <Text variant='medium'>
                    Physical products
                    <br />
                    <Text tag='span'>
                      <ul className={classes.listItems}>
                        <li>Developing a new canned beverage</li>
                        <li>Improving the durability of packaging</li>
                        <li>Improving manufacturing processes</li>
                        <li>Designing and constructing prototypes</li>
                      </ul>
                    </Text>
                  </Text>
                }
              />
            </Content>
            <Text variant='medium'>
              Common business components that are not eligible for R&D:
              <br />
            </Text>

            <div className={classes.bulletPointGrid}>
              <ContentWithIcon
                text={
                  <Text variant='medium'>
                    Maintenance and routine bug fixes on existing software
                  </Text>
                }
              />
              <ContentWithIcon
                text={
                  <Text variant='medium'>
                    Integrating a third-party tool like Salesforce or Asana
                  </Text>
                }
              />
              <ContentWithIcon
                text={
                  <Text variant='medium'>
                    Making aesthetic changes to products (color, taste, etc.)
                  </Text>
                }
              />
            </div>
          </>
        </Callout>
      );
    };

    return (
      <div>
        <Grid columns={12} padding={[0, 0, 16, 0]}>
          <Grid.Cell columns={4} padding={[0, 24, 0, 0]}>
            <Text
              text={'R&D Business Components'}
              size={23}
              variant={'medium'}
              paddingBottom={16}
            />
            <Text
              text={
                'Identify your R&D business components here. These will be included in your final IRS credit forms, to demonstrate how your research expenses contributed to your research projects.'
              }
              paddingBottom={16}
            />
            <div
              onClick={() => businessComponents.setFourPartTestModalOpen(true)}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '4px',
                paddingBottom: '40px',
                cursor: 'pointer',
              }}
            >
              <FontAwesome
                name={'file-lines'}
                size={15}
                color={Color.blue._60}
              />
              <Text
                text={'View Four-part test details'}
                size={15}
                color={Color.blue._60}
              />
            </div>
          </Grid.Cell>
          <Grid.Cell columns={8}>{renderRDProjectEligibility()}</Grid.Cell>
        </Grid>
        {renderRdMultipleProjectsTable()}
        {businessComponents.projectsSubmitted && renderRdProjectWeightsTable()}

        {/* Four Part Test Modal */}
        <Modal
          showModal={businessComponents.fourPartTestModalOpen}
          closeToggle={() => businessComponents.setFourPartTestModalOpen(false)}
          rounded
        >
          <FourPartTestModalContent />
        </Modal>
      </div>
    );
  },
);
