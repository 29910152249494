import React, { useEffect, useState } from 'react';
import {
  Color,
  Content,
  Delay,
  Divider,
  Flex,
  Grid,
  Spinner,
  Text,
} from 'component-library';
import {
  AttestationEventTypeEnum,
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
  Page,
  ProgramNameEnum,
  Programs,
  ProgramSubStageEnum,
  RdVendorExpenseType,
  SurveyNameEnum,
} from 'lib/constants';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import { CmsRenderTree, ProgramData } from 'lib/interfaces';
import {
  useCommonStores,
  useCompany,
  useFeatureFlags,
  useLegacyClients,
  useTaxCreditsStores,
} from 'stores/useStores';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { useExpenseClassificationAnswerPrefill } from 'lib/useExpenseClassificationAnswerPrefill';
import { renderTaxYearInQuestionText } from 'lib/useQuestionGroup';
import { datadogLogs } from '@datadog/browser-logs';
import { SaveSurveyAttestation } from '../../../../../../pages/tax-processing/expense-classification/components/expense-classification/SurveyAttestation';
import RdExpensesSurveyQuestion from '../../../../../../pages/tax-processing/expense-classification/components/rd-expenses/RdExpensesSurveyQuestion';
import { SurveyAttestation } from '../../../../../../pages/tax-processing/expense-classification/components';
import ErrorPage from '../../../../../../pages/ErrorPage';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import { useEffectOnce } from 'lib/helpers';
import { PrefillAlert } from '../../components/PrefillAlert';
import renderTreeJson from '../../../../../../hygraph/renderTree/unifiedRDSuppliesAndServices.json';
import { RdBusinessComponents } from '../../../../../../pages/tax-processing/expense-classification/components/rd-expenses/RdBusinessComponents';
import {
  completedCompanyDetailsSection,
  completedSuppliesAndServicesSection,
} from '../helpers';

const logger = datadogLogs.createLogger('RDExpenses');

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    minHeight: '100vh',
    width: '100%',
    backgroundColor: Color.neutral.white,
  },
  mainContent: {
    width: '100%',
    margin: '0 auto',
    padding: '0 0 80px',
    boxSizing: 'border-box',
    position: 'relative',
  },
  divider: {
    marginTop: 24,
    maxWidth: '100%',
  },
  alert: {
    backgroundColor: Color.blue._10,
    border: `0.8px solid ${Color.blue._60}`,
  },
  errorPage: {
    justifyContent: 'center',
  },
}));

interface RDExpensesStepProps {
  onNext: () => void;
  onBack: () => void;
  taxYear: number;
}

export const RDSuppliesAndServices: React.FC<RDExpensesStepProps> = observer(
  ({ taxYear, onNext, onBack }) => {
    const { company } = useCompany();
    const programId = company.programs.find(
      (item) =>
        item.taxYear === taxYear && item.name === ProgramNameEnum.FED_RD_TAX,
    )?.id;
    const classes = useStyles();
    const { client } = useLegacyClients();
    const {
      taxCreditsPage,
      surveyFlow,
      unifiedTaxCredits,
      businessComponents,
    } = useTaxCreditsStores();
    const featureFlags = useFeatureFlags();
    const { app, auth, chatbot, companyStore } = useCommonStores();
    const [program, setProgram] = useState<ProgramData | null>(null);
    const [cloudTableFinished, setCloudTableFinished] = useState(false);
    const [suppliesTableFinished, setSuppliesTableFinished] = useState(false);
    const [hasConfirmedAnswers, setHasConfirmedAnswers] =
      useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const [isExpensesValid, setIsExpensesValid] = useState(false);

    useEffect(() => {
      const getProgram = companyStore.accessToken
        ? client.GetProgramPublic(companyStore.accessToken, programId!)
        : client.GetProgram(programId!);
      getProgram.then((res) => {
        if (res.errorMsg || !res.data) {
          logger.error(
            res.errorMsg ?? `Error fetching program with id: ${programId}`,
          );
          return;
        }

        const { program } = res.data;
        setProgram(program);
      });
    }, [programId, client]);

    useEffectOnce(async () => {
      if (featureFlags.showShareAssessment) {
        unifiedTaxCredits.setShareAssessmentOnNext(onNext);
      }
      await client.SetYeaPrefill(taxYear);
      await app.common.companyStore.refreshCurrentCompany();
    });

    const surveyName: SurveyNameEnum =
      SurveyNameEnum.UNIFIED_RD_SUPPLIES_AND_SERVICES;

    const {
      isLoading,
      questionsToRender,
      surveyAnswers,
      addSurveyAnswers,
      getQuestionData,
      saveAnswers,
      isSurveyComplete,
      getSubGroupRenderData,
      addSurveyAnswersWithoutRerender,
      isQuestionRenderTreeSubGroupsPresent,
    } = useSurveyQuestions(
      surveyName,
      program?.taxYear || 0,
      undefined,
      renderTreeJson as CmsRenderTree,
    );

    // Prefill answers from other EC/Qual questions
    useExpenseClassificationAnswerPrefill(
      surveyName,
      program,
      addSurveyAnswersWithoutRerender,
    );

    useEffect(() => {
      if (!programId) return;
      const getRdVendorExpenses = companyStore.accessToken
        ? client.GetRdVendorExpensesPublic(companyStore.accessToken, programId)
        : client.GetRdVendorExpenses(programId);
      getRdVendorExpenses.then((response) => {
        if (response.data && !response.errorMsg) {
          const expensesForCloud = response.data.filter(
            (expense) => expense.expenseType === RdVendorExpenseType.CLOUD,
          );
          const expensesForSupplies = response.data.filter(
            (expense) =>
              expense.expenseType === RdVendorExpenseType.SERVICES_SUPPLY,
          );
          const isExpensesForCloudInvalid =
            company.qualificationQuestionsByYear?.[taxYear]?.[
              GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_CLOUD
            ] ===
              GraphCmsQuestionIdToAnswers[
                GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_CLOUD
              ].YES && expensesForCloud.length === 0;

          const isExpensesForSupplyInvalid =
            company.qualificationQuestionsByYear?.[taxYear]?.[
              GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_SUPPLIES
            ] ===
              GraphCmsQuestionIdToAnswers[
                GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_SUPPLIES
              ].YES && expensesForSupplies.length === 0;

          setIsExpensesValid(
            !(isExpensesForCloudInvalid || isExpensesForSupplyInvalid),
          );
        }
      });
    }, [company.qualificationQuestionsByYear?.[taxYear]]);

    interface SubGroupTitle {
      title?: string;
      subtitle?: string;
    }

    // handle logic on continue here
    const onContinue = async () => {
      if (companyStore.accessToken) {
        unifiedTaxCredits.setShowInviteeSuccessModal(true);
      }

      surveyFlow.setSurveyContinueLoading(true);
      try {
        await saveAnswers();

        if (featureFlags.saveYeaSurveyAttestation) {
          await SaveSurveyAttestation({
            hasConfirmedAnswers,
            eventType:
              AttestationEventTypeEnum.YEAR_END_ASSESSMENT_VENDOR_EXPENSES_COMPLETE,
            userEmail: auth.user?.email || company?.adminEmail,
            taxYear: program!.taxYear,
            companyId: company.id,
            client,
            accessToken: companyStore.accessToken,
          });
        }

        if (
          !taxCreditsPage.isYEAEditMode &&
          programId &&
          company.qualificationQuestionsByYear
        ) {
          if (
            completedCompanyDetailsSection(
              company.qualificationQuestionsByYear,
              taxYear,
            ) &&
            completedSuppliesAndServicesSection(
              company.qualificationQuestionsByYear,
              taxYear,
            )
          ) {
            await unifiedTaxCredits.updateProgram(
              programId,
              {
                subStage:
                  ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EMPLOYEES,
              },
              companyStore.accessToken,
            );
          }

          !companyStore.accessToken && onNext();
        }
      } catch (err) {
        logger.error(err);
        setShowError(true);
      }
      surveyFlow.setSurveyContinueLoading(false);
    };

    const continueText = companyStore.accessToken
      ? 'Finished'
      : 'Continue to R&D Tax credit: Employees';

    const titleByOperator: Record<string, (...args: any[]) => SubGroupTitle> = {
      hasProgram: ({ programName }: { programName: ProgramNameEnum }) => {
        return {
          title: `Specific details for your ${Programs[programName].display} program.`,
        };
      },

      equals: ({
        questionId,
        answerValue,
      }: {
        questionId: string;
        answerValue: string;
      }) => {
        const question = getQuestionData(questionId as string);
        return {
          title: `Because you answered "${
            question!.answerIDs.find((a) => a.id === answerValue)?.text
          }" to "${question!.text}"`,
        };
      },

      and: (conditions) => {
        // HACK: Prioritizes the first condition when generating the header.
        const first = conditions[0];
        const [operator, condition] = Object.entries(first)[0];
        return titleByOperator[operator](condition);
      },

      or: (conditions) => {
        const first = conditions[0];
        const [operator, condition] = Object.entries(first)[0];
        return titleByOperator[operator](condition);
      },
    };

    const replaceTaxYear = (questionId: string) => {
      const question = getQuestionData(questionId as string);
      if (question) {
        const taxYear = program ? program.taxYear : new Date().getUTCFullYear(); // Every program should have a tax year
        question.text = renderTaxYearInQuestionText(question.text, taxYear);
      }
      return question!;
    };

    useEffect(() => {
      if (!program) {
        return;
      }
      unifiedTaxCredits.GetRDProjects(program, companyStore.accessToken);
    }, [client, programId, program]);

    useEffectOnce(
      async () =>
        // update program stage to expense_classification onload
        await unifiedTaxCredits.updateProgramStageOnSurveyEntry(
          taxYear,
          ProgramNameEnum.FED_RD_TAX,
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_RD_EXPENSES,
        ),
    );

    return showError ? (
      <div className={`${classes.container} ${classes.errorPage}`}>
        <ErrorPage
          errorTitle="We've encountered a technical problem"
          errorMsg={`We are working on this issue. Please return in one business day to complete this form.`}
          showBackToDashboard
        />
      </div>
    ) : (
      <div className={classes.container} data-testid='new-rd-expenses'>
        <div className={classes.mainContent}>
          <SurveyFlowContainer
            title='R&D Expenses | Supplies and Services'
            onContinue={onContinue}
            isDisabled={
              !businessComponents.businessComponentSectionComplete ||
              !isSurveyComplete ||
              (featureFlags.saveYeaSurveyAttestation && !hasConfirmedAnswers) ||
              !isExpensesValid
            }
            isLoading={surveyFlow.surveyContinueLoading}
            continueText={continueText}
            currentPage={Page.expenseClassificationSuppliesServices}
            onBack={companyStore.accessToken ? undefined : onBack}
            onSkip={() =>
              surveyFlow.skipSurveyStep(
                taxYear,
                program?.id ?? -1,
                ProgramNameEnum.FED_RD_TAX,
                onNext,
                true,
              )
            }
          >
            <>
              <PrefillAlert />
              <Flex>
                <Divider className={classes.divider} />
              </Flex>
              {isLoading ? (
                <Flex justifyContent='center' padding={24}>
                  <Spinner size='small' color='emerald' />
                </Flex>
              ) : (
                <>
                  {/* Business Components */}
                  {program && (
                    <React.Fragment key={'subgroup-vendorProjects'}>
                      <RdBusinessComponents
                        programId={program.id}
                        projects={program.rdProjects}
                      />
                    </React.Fragment>
                  )}

                  {/* Cloud Computing (Subgroup) and Supplies Expenses (Subgroup) */}
                  {businessComponents.businessComponentSectionComplete &&
                    Object.keys(questionsToRender).map((subGroupName) => {
                      return program &&
                        questionsToRender[subGroupName].length > 0 ? (
                        <React.Fragment key={`subgroup-${subGroupName}`}>
                          {questionsToRender[subGroupName].map((question) => {
                            question = replaceTaxYear(question.id);

                            return (
                              <Delay waitBeforeShow={350} key={question.id}>
                                <RdExpensesSurveyQuestion
                                  program={program}
                                  question={question}
                                  subGroupName={subGroupName}
                                  addSurveyAnswers={addSurveyAnswers}
                                  saveAnswers={saveAnswers}
                                  surveyAnswers={surveyAnswers}
                                  chatbot={chatbot}
                                  cloudTableFinished={cloudTableFinished}
                                  setCloudTableFinished={setCloudTableFinished}
                                  suppliesTableFinished={suppliesTableFinished}
                                  setSuppliesTableFinished={
                                    setSuppliesTableFinished
                                  }
                                />
                              </Delay>
                            );
                          })}
                        </React.Fragment>
                      ) : null;
                    })}
                  {!isQuestionRenderTreeSubGroupsPresent && (
                    <>
                      <Content paddingTopBottom={16}>
                        <Text
                          textAlign={'center'}
                          text={
                            'We are currently experiencing issues, try refreshing or contact us at support@mainstreet.com'
                          }
                          variant='medium'
                        />
                      </Content>
                    </>
                  )}
                  {isQuestionRenderTreeSubGroupsPresent &&
                    featureFlags.saveYeaSurveyAttestation &&
                    isSurveyComplete && (
                      <Grid columns={3} padding={[24, 0]}>
                        <Grid.Cell columns={1} padding={[0, 24, 0, 0]} />
                        <Grid.Cell columns={2}>
                          <SurveyAttestation
                            checked={hasConfirmedAnswers}
                            onAttestation={() =>
                              setHasConfirmedAnswers(!hasConfirmedAnswers)
                            }
                          />
                        </Grid.Cell>
                      </Grid>
                    )}
                </>
              )}
            </>
          </SurveyFlowContainer>
        </div>
      </div>
    );
  },
);
