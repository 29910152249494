import { Color, Content, Flex, FontAwesome, Text } from 'component-library';
import React from 'react';

interface DisclaimerSectionProps {
  partnerName: string;
}
export const DisclaimerSection = ({ partnerName }: DisclaimerSectionProps) => {
  return (
    <Content
      bgColor={Color.neutral._90}
      paddingTopBottom={40}
      paddingLeftRight={24}
      flex
      alignItems={'center'}
      justifyContent={'center'}
    >
      <div style={{ maxWidth: '650px' }}>
        <Flex gap={8}>
          <div style={{ paddingTop: '4px' }}>
            <FontAwesome name={'circle-info'} color={Color.neutral.white} />
          </div>
          <Text size={15} color={Color.neutral.white}>
            Tax Credit screener is a product of MainStreet and any associated
            services are not fulfilled by {partnerName}. Any information you
            provide on this page will be sent directly to Mainstreet and is not
            collected, accessed, or processed by {partnerName}. As such, please
            review MainStreet’s Privacy Policy and Terms of Service for more
            information on how your data will be used.
          </Text>
        </Flex>
      </div>
    </Content>
  );
};
