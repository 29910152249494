import {
  Button,
  Card,
  CardFooter,
  Checkbox,
  DropdownOption,
  Expandable,
  ExpandableHeader,
  Flex,
  SideDrawer,
  Table,
  TableColumns,
  Text,
} from 'component-library';
import { RdProject, CreateRdProject } from 'lib/interfaces';
import { RdProjectCategoryEnum } from 'lib/constants';
import React, { useState } from 'react';
import { datadogLogs } from '@datadog/browser-logs';
import {
  useCommonStores,
  useLegacyClients,
  useTaxCreditsStores,
} from 'stores/useStores';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { BusinessComponentSideDrawerContent } from './BusinessComponentSideDrawerContent';

const useStyles = makeStyles(() => ({
  textCell: {
    margin: 0,
    padding: '16px',
  },
  sideDrawerButton: {
    marginLeft: '16px',
  },
}));

const descriptionOptions: DropdownOption[] = [
  { name: 'Computer software', value: RdProjectCategoryEnum.COMPUTER_SOFTWARE },
  { name: 'Physical product', value: RdProjectCategoryEnum.PHYSICAL_PRODUCT },
  { name: 'Process', value: RdProjectCategoryEnum.PROCESS },
];

export interface ProjectsTableProps {
  projects: Array<RdProject>;
  programId?: number;
  onContinue: () => void;
}

const RdMultipleProjectsTable = observer(
  ({ projects, programId, onContinue }: ProjectsTableProps) => {
    const classes = useStyles();
    const { client } = useLegacyClients();
    const { companyStore } = useCommonStores();
    const { businessComponents } = useTaxCreditsStores();

    // If the user is editing an existing project, the selected project data will be populated
    const selectedProject = businessComponents.selectedProjectData;

    const [editMode, setEditMode] = useState(() => projects.length === 0);
    const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

    const projectColumns: TableColumns<RdProject>[] = [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Category',
        accessor: 'category',
        Cell: (cell) => {
          const opt = descriptionOptions.find(
            (o) => o.value === cell.data.category,
          );
          return (
            <Text
              className={classes.textCell}
              text={opt?.name ?? 'Not answered'}
            />
          );
        },
      },
    ];

    const openSideDrawer = (projectToEdit?: RdProject) => {
      if (projectToEdit) {
        businessComponents.setSelectedProjectData(projectToEdit);
      }
      setSideDrawerOpen(true);
    };

    const closeSideDrawer = () => {
      setSideDrawerOpen(false);
      businessComponents.resetSelectedProjectData();
    };

    const handleAddProject = async () => {
      const name = selectedProject.name;
      const category = selectedProject.category;
      const passed4PartTest = selectedProject.passed4PartTest;
      const rdProjectSoftwareId = selectedProject.rdProjectSoftwareId;
      if (name && category && programId && passed4PartTest) {
        const project: CreateRdProject = {
          name,
          category,
          passed4PartTest,
          rdProjectSoftwareId,
        };

        try {
          const res = companyStore.accessToken
            ? await client.CreateRDProjectPublic(
                companyStore.accessToken,
                programId,
                project,
              )
            : await client.CreateRDProject(programId, project);

          if (res.errorMsg) {
            datadogLogs.logger.error('Failed to create R&D project', {
              programId,
              errorMsg: res.errorMsg,
              projectData: project,
            });
          } else {
            businessComponents.setProjects([...projects, res.data!]);
          }
        } catch (error) {
          datadogLogs.logger.error('Failed to create R&D project', {
            programId,
            error,
            projectData: project,
          });
        }

        closeSideDrawer();
      }
    };

    const handleSaveProject = () => {
      const name = selectedProject.name;
      const category = selectedProject.category;
      const passed4PartTest = selectedProject.passed4PartTest;
      const programId = selectedProject.programId;
      const projectId = selectedProject.id;
      if (name && category && passed4PartTest && programId && projectId) {
        client
          .UpdateRDProject(
            programId,
            projectId,
            businessComponents.selectedProjectData,
          )
          .then((res) => {
            if (res.errorMsg) {
              datadogLogs.logger.error('Failed to update R&D project', {
                programId,
                errorMsg: res.errorMsg,
                projectData: selectedProject,
              });
            } else {
              const updatedProjects = projects.filter(
                (val) => val.id !== projectId,
              );
              updatedProjects.push(selectedProject as RdProject);
              businessComponents.setProjects(updatedProjects);
            }
          })
          .catch((reason) => {
            datadogLogs.logger.error('Failed to update R&D project', {
              programId,
              reason,
              projectData: selectedProject,
            });
          });
        closeSideDrawer();
      }
    };

    const handleDeleteProject = () => {
      const programId = selectedProject.programId;
      const projectId = selectedProject.id;
      if (programId && projectId) {
        client
          .DeleteRDProject(programId, projectId)
          .then((res) => {
            if (res.errorMsg) {
              datadogLogs.logger.error('Failed to delete R&D project', {
                programId,
                errorMsg: res.errorMsg,
                projectData: selectedProject,
              });
            } else {
              businessComponents.setProjects(
                projects.filter((val) => val.id !== projectId),
              );
            }
          })
          .catch((reason) => {
            datadogLogs.logger.error('Failed to delete R&D project', {
              programId,
              reason,
              projectData: selectedProject,
            });
          });
        closeSideDrawer();
      }
    };

    return (
      <>
        <Card>
          <ExpandableHeader
            editMode={editMode}
            setEditMode={setEditMode}
            title='Add all of your major R&D projects'
            subtitle={
              projects ? projects.map((project) => project.name).join(', ') : ''
            }
          />
          <Expandable expand={editMode}>
            <Table
              dataTestId={'rd-projects-table'}
              columns={projectColumns}
              data={projects}
              paginationSizePerPage={5}
              paginationHideSinglePage
              highlightOnHover={true}
              rowOnClick={(data) => openSideDrawer(data)}
            />
            <CardFooter
              primaryCtaLabel='Continue'
              primaryCtaDisabled={projects.length <= 0}
              primaryOnClick={() => {
                setEditMode(false);
                onContinue();
              }}
              secondaryCtaLabel='Add project'
              secondaryOnClick={() => openSideDrawer()}
              secondaryTestId='rd-projects-table-add'
              variant='secondary'
            />
          </Expandable>
        </Card>
        <SideDrawer
          closeToggle={() => closeSideDrawer()}
          drawerActions={
            // Existing project actions
            selectedProject.id ? (
              <Flex justifyContent='space-between' direction='row-reverse'>
                <Button
                  label='Save changes'
                  dataTestId={'rd-project-side-drawer-save'}
                  onClick={() => handleSaveProject()}
                  disabled={businessComponents.saveProjectButtonDisabled}
                />
                <Button
                  label='Delete project'
                  dataTestId={'rd-project-side-drawer-delete'}
                  type='error'
                  disabled={!selectedProject.programId || !selectedProject.id}
                  onClick={() => handleDeleteProject()}
                />
              </Flex>
            ) : (
              // New project actions
              <Flex direction='row-reverse'>
                <Button
                  label='Add project'
                  dataTestId={'rd-project-side-drawer-add'}
                  onClick={handleAddProject}
                  disabled={businessComponents.addProjectButtonDisabled}
                  className={classes.sideDrawerButton}
                />
                <Button
                  label='Cancel'
                  variant={'outlined'}
                  onClick={() => closeSideDrawer()}
                />
              </Flex>
            )
          }
          drawerContent={<BusinessComponentSideDrawerContent />}
          drawerFooterContent={
            <Checkbox
              options={[
                {
                  checked: !!selectedProject.passed4PartTest,
                  text: 'To the best of my knowledge, this is an R&D business component that meets the conditions of the four-part test.',
                  value: 'checked',
                },
              ]}
              onChange={() =>
                businessComponents.setSelectedProjectPassed4PartTest(
                  !selectedProject.passed4PartTest,
                )
              }
              dataTestId={'rd-project-side-drawer-checkbox'}
            />
          }
          title='Add R&D Business Component'
          show={sideDrawerOpen}
        />
      </>
    );
  },
);

export default RdMultipleProjectsTable;
