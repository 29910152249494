import { Flex, Text } from 'component-library';
import React from 'react';

interface OnPayUploadInstructionsProps {
  payrollYear: number;
}

export const OnPayUploadInstructions = ({
  payrollYear,
}: OnPayUploadInstructionsProps) => {
  return (
    <Flex gap={8} direction='column'>
      <Flex alignItems='flex-start'>
        <Flex.Cell basis={40}>
          <img
            src={`${process.env.PUBLIC_URL}/images/disclaimer.png`}
            alt='Upload Disclaimer'
          />
        </Flex.Cell>
        <Flex.Cell>
          <Text variant='medium'>
            Please download your {payrollYear} Employee Summary Report from
            OnPay and upload it here.
          </Text>
          <br />
          <Text variant='medium'>
            <a
              href='https://help.onpay.com/hc/en-us/articles/202646135-How-to-run-a-Payroll-Summary-Report'
              target='_blank'
              rel='noopener noreferrer'
            >
              How do I get a Employee Summary Report from OnPay?
            </a>
          </Text>
          <br />
          <Text variant='medium'>It should include:</Text>
        </Flex.Cell>
      </Flex>
      <Flex>
        <Flex.Cell basis={30}>
          <></>
        </Flex.Cell>
        <Flex.Cell>
          <Text>
            • all US-based employees and contractors you paid in {payrollYear}
          </Text>
          <Text>• their job titles</Text>
          <Text>• the state in which they work</Text>
          <Text>
            • the total amount you paid each individual in {payrollYear}
          </Text>
          <Text>
            • any benefits contributions you made for each employee in{' '}
            {payrollYear}
          </Text>
        </Flex.Cell>
      </Flex>
    </Flex>
  );
};
