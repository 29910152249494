import React from 'react';
import { UpperCaseDisplay } from 'lib/helpers';
import { SUPPORTED_PAYROLL_SYSTEMS } from 'lib/constants';
import { Card, Content, Flex, Link, Text } from 'component-library';
import { Button } from 'components/util/Button';
import {
  CardSuccessMessage,
  ConnectCard,
  ConnectCardHeader,
  ExpiredTokenMessage,
} from 'pages/dashboard/integrations';
import { makeStyles } from '@material-ui/core/styles';
import { useCommonStores } from 'stores/useStores';
import { ImportType } from '../../../../../lib/interfaces';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
}));

interface PayrollConnectedCardProps {
  payrollSystem: string;
  expiredPayrollSystem?: boolean;
  connectAnother?: boolean;
  setPayrollConnectInProgress?: (bool: boolean) => void;
  onPayrollConnected?: () => Promise<void>;
  useCardComponent?: boolean;
  importType: ImportType;
  taxYear?: number;
  noMargin?: boolean;
}

export const PayrollConnectedCard = ({
  payrollSystem,
  expiredPayrollSystem,
  connectAnother,
  setPayrollConnectInProgress,
  onPayrollConnected,
  useCardComponent,
  importType,
  taxYear,
  noMargin,
}: PayrollConnectedCardProps) => {
  const classes = useStyles();
  const { app } = useCommonStores();

  const manualSystem = payrollSystem === 'Manual';
  const supportedSystem = SUPPORTED_PAYROLL_SYSTEMS.indexOf(payrollSystem) >= 0;
  const message = manualSystem
    ? 'Payroll information submitted manually'
    : `Connected to ${
        supportedSystem ? payrollSystem : UpperCaseDisplay(payrollSystem)
      }`;

  const includeExpirationMessage =
    importType === ImportType.ExpenseClassification ||
    importType === ImportType.UnifiedAssessment ||
    payrollSystem === 'rippling';

  const getHeader = () => {
    if (expiredPayrollSystem && onPayrollConnected) {
      if (payrollSystem === 'rippling') {
        return 'Enter payroll manually';
      }
      return 'Connect payroll software';
    }

    return "You're connected!";
  };

  const renderExpirationMessage = () => {
    if (expiredPayrollSystem && onPayrollConnected) {
      if (payrollSystem === 'rippling') {
        return (
          <Text>
            There is a problem with your payroll connection. Please fill out
            your tax credits assessments manually, or send a csv of your 2024
            payroll data to{' '}
            <Link href={'mailto:support@mainstreet.com'}>
              support@mainstreet.com.
            </Link>
          </Text>
        );
      }

      return (
        <Text
          text={'Your payroll connection has expired. Please reconnect now.'}
        />
      );
    }

    return (
      <Text
        text={
          'Please continue your assessment while we import your payroll data.'
        }
      />
    );
  };

  const header = <ConnectCardHeader title={getHeader()} />;
  const content =
    expiredPayrollSystem && onPayrollConnected ? (
      <>
        {includeExpirationMessage && (
          <Content paddingBottom={24}>{renderExpirationMessage()}</Content>
        )}
        <ExpiredTokenMessage
          service={payrollSystem}
          onPayrollConnected={onPayrollConnected}
          importType={importType}
          taxYear={taxYear}
        />
      </>
    ) : (
      <CardSuccessMessage message={message} />
    );
  const connectAnotherPayrollProviderButtonText =
    '+ Connect another payroll provider';

  const cardComponentVersion = (
    <>
      <Card noMargin={noMargin}>
        <Flex
          justifyContent='space-between'
          alignItems='center'
          direction={app.isMobile ? 'column' : 'row'}
          padding={app.isMobile ? 16 : 24}
        >
          <Flex direction='column' gap={8}>
            <Text size={18} variant='medium'>
              {getHeader()}
            </Text>
            {includeExpirationMessage ? (
              renderExpirationMessage()
            ) : (
              <Text
                text={'This will make your tax process much more efficient'}
              />
            )}
          </Flex>
          {!expiredPayrollSystem && onPayrollConnected && (
            <Flex basis={300} justifyContent='flex-end'>
              <CardSuccessMessage message={message} />
            </Flex>
          )}
        </Flex>
        {expiredPayrollSystem && onPayrollConnected && (
          <Content paddingTop={0} paddingLeftRight={32} paddingBottom={32}>
            <ExpiredTokenMessage
              service={payrollSystem}
              onPayrollConnected={onPayrollConnected}
              importType={importType}
              taxYear={taxYear}
            />
          </Content>
        )}
      </Card>
      {connectAnother && setPayrollConnectInProgress && (
        <Content paddingTop={0} paddingLeftRight={0} paddingBottom={32}>
          <Link onClick={() => setPayrollConnectInProgress(true)}>
            {connectAnotherPayrollProviderButtonText}
          </Link>
        </Content>
      )}
    </>
  );
  const nonCardComponentVersion = (
    <div className={classes.root}>
      <ConnectCard header={header} mainContent={content} />
      {connectAnother && setPayrollConnectInProgress && (
        <div>
          <Button
            onClick={() => setPayrollConnectInProgress(true)}
            variant={'link'}
          >
            {connectAnotherPayrollProviderButtonText}
          </Button>
        </div>
      )}
    </div>
  );

  return useCardComponent ? cardComponentVersion : nonCardComponentVersion;
};
