import { runInAction } from 'mobx';
import { makeSubclassObservable } from 'lib/mobx-utils';
import { RootStore } from 'stores/RootStore';
import { BaseTaxCreditsStore } from '../BaseTaxCreditsStore';
import {
  AssessmentProgressStatus,
  ClientReviewTaxForms,
  Document,
  InactiveProgramStages,
  ProgramData,
  TaxCreditAssessmentGeneralHygraphId,
  TaxCreditAssessmentProgramsHygraphId,
  TaxCreditAssessments,
  TaxCreditAssessmentsByYear,
  UpdateCompanyQBDataRequest,
  UpdateProgramRequest,
} from 'lib/interfaces';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import {
  AssessmentSurveyStepsIndex,
  AttestationEventTypeEnum,
  CompanyAccessToken,
  ExpectedCreditTypeEnum,
  GraphCmsQuestionIdEnum,
  HealthcarePlanCreditQuestions,
  Page,
  PayrollTierEnum,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationStatusEnum,
  RetirementPlanCreditQuestions,
  StatePrograms,
} from 'lib/constants';
import {
  FloatToDollarString,
  getRetirementEstimatedCreditContext,
  HasManuallyAcceptedOrderForm,
} from 'lib/helpers';
import { Company } from '../../../entities/Company';

export class UnifiedTaxCreditsStore extends BaseTaxCreditsStore {
  public loadingAssessments = true;
  public assessmentByYear: TaxCreditAssessmentsByYear = {};
  public showTimelineSideDrawer = false;

  // survey states
  public flowCurrentIndex = 0;
  public flowPrevIndex = 0;
  public totalCreditSoFar = 0;
  public showAllSectionsDrawer = false;
  public showSubmitReviewModal = false;
  public showExpandCreditBreakdown = true;
  public showInReviewTitleSideDrawer = false;
  public showInProgressTitleSideDrawer = false;
  public showAccumulatedModal = false;
  public surveyAccumulated: TaxCreditAssessments | null = null;

  public hasAllAssessmentsInReview = false;
  public hasAllAssessmentsInClientReview = false;
  public hasFiledIncomeTaxStage = false;
  public hasJustCompletedAllAssessments = false;
  public hasAllAssessmentsDisqualified = false;
  public showClientTitleSideDrawer = false;
  public confirmedFilingCheckbox = false;
  public hasBeganRedeeming = false;

  // Determine 8974 completion
  public hasCompleted8974 = false;

  public linkCopied = false;
  public filingFedProgram: ProgramData | null = null;
  public totalCreditAmountCents = 0;

  // Share Assessment Modal
  public showShareAssessmentModal = false;
  public sourcePage: undefined | Page;
  public shareAssessmentOnNext: (() => void) | null = null;
  public showInviteeModal = true;
  public showInviteeSuccessModel = false;

  public showTaxFormsSideDrawer = false;
  public viewTaxForms: ClientReviewTaxForms[] = [];
  public allTaxYearDocuments: Document[] = [];
  public showCongratsModal = false;
  public congratsModalText = '';
  public loadingClientReview = false;

  public showIncompleteProgramModal = false;

  public finishedStageCreditType: Partial<ExpectedCreditTypeEnum> =
    ExpectedCreditTypeEnum.PAYROLL_TAX;

  public defaultChargePercentageString = '20%';

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public setLoadingAssessments(bool: boolean) {
    runInAction(() => (this.loadingAssessments = bool));
  }

  public async getTaxCreditAssessments(
    taxYear: number,
    accessToken?: CompanyAccessToken,
  ) {
    const res = await this.api.GetTaxCreditAssessments(accessToken);
    const currentTaxYear = taxYear;

    // reset assessment data
    runInAction(() => (this.assessmentByYear = {}));

    // create the programs for company
    if (!this.hasUnifiedAccountCreation) {
      await this.createAllPrograms(currentTaxYear);
      // refresh current company after program updates
      await this.rootStore.common.companyStore.refreshCurrentCompany();
    }

    if (res && res.errorMsg) {
      datadogLogs.logger.error(
        `[HYGRAPH_ERROR]: Error getting list of tax credit assessments from hygraph`,
        logContext({
          company: this.company,
          error: res.errorMsg,
        }),
      );
    }

    if (res.data && res.data?.taxCredits) {
      const taxCredits = res.data?.taxCredits;

      if (taxCredits) {
        // refresh current company to get the latest program stage
        await this.rootStore.common.companyStore.refreshCurrentCompany();

        const programs = this.company.programs;
        const getGeneralBusinessDetails = taxCredits.find(
          (item) =>
            item.id ===
            TaxCreditAssessmentGeneralHygraphId.GENERAL_BUSINESS_DETAILS,
        );

        // determine the credit tax type
        this.determineCreditTaxType(programs, taxYear);

        // add assessments based on active programs
        for (const program of programs) {
          const getAssessment = taxCredits.find(
            (credit) =>
              credit.id ===
                TaxCreditAssessmentProgramsHygraphId[program.name] &&
              !InactiveProgramStages.includes(program.stage),
          );

          // push assessments based on tax year + sort by priority
          if (getAssessment && program.taxYear === currentTaxYear) {
            let taxYearData = this.assessmentByYear[program.taxYear];

            if (!taxYearData) {
              taxYearData = [];
              runInAction(() => {
                this.assessmentByYear[program.taxYear] = taxYearData;
              });
            }

            const creditAmount = FloatToDollarString(
              this.getProgramCreditAmountCents(program) / 100,
            );

            // handle Retirement credit case
            if (
              getAssessment.id ===
              TaxCreditAssessmentProgramsHygraphId[
                ProgramNameEnum.FED_RETIREMENT_CREDIT
              ]
            ) {
              getAssessment.estimatedCreditContext =
                getRetirementEstimatedCreditContext(
                  this.company.qualificationQuestionsByYear,
                  taxYear,
                );
            }

            // for now: setting progress status to incomplete unless program is in ms_review
            const addProgressStatus: TaxCreditAssessments = {
              ...getAssessment,
              status: this.getProgressStatus(program),
              subStage: program.subStage ?? undefined,
              creditAmount,
              creditAmountCents: this.getProgramCreditAmountCents(program),
              creditType: program.filingCreditType,
            };

            runInAction(() => {
              if (
                !this.assessmentByYear[program.taxYear].some(
                  (i) => i.id === getAssessment.id,
                )
              ) {
                this.assessmentByYear[program.taxYear].push(addProgressStatus);
              }
            });
          }
        }

        // only push general business details assessment if tax year exist
        runInAction(() => {
          Object.keys(this.assessmentByYear).map((keys) => {
            if (getGeneralBusinessDetails) {
              if (
                !this.assessmentByYear[keys].some(
                  (i) => i.id === getGeneralBusinessDetails.id,
                )
              ) {
                const addStatusToGeneralBusiness = {
                  ...getGeneralBusinessDetails,
                  status: this.getGeneralBusinessStatus(currentTaxYear),
                };
                runInAction(() => {
                  this.assessmentByYear[keys].push(addStatusToGeneralBusiness);
                });
              }
            }

            // sort assessments by hygraph priority
            return this.assessmentByYear[keys].sort(
              (a, b) => a.priority - b.priority,
            );
          });
        });
      }

      runInAction(() => {
        this.setLoadingAssessments(false);
        this.hasAllAssessmentsInReview =
          this.isAllAssessmentReadyForReview(taxYear);
        this.hasAllAssessmentsInClientReview =
          this.isAllAssessmentsClientReview(taxYear);
        this.hasFiledIncomeTaxStage = this.hasFiledIncomeTax(taxYear);
        this.hasBeganRedeeming = this.hasBeganRedeemingStage(taxYear);
        this.hasAllAssessmentsDisqualified =
          this.isAllAssessmentsDisqualified(taxYear);
      });
    }
  }

  private getProgramCreditAmountCents(program: ProgramData) {
    const programOrderEstimate = program.orderForm?.estimatedTotalCreditCents;

    if (program.creditAmountCents > 0) {
      // if fed r&d - include state credit amount in total estimate
      if (program.name === ProgramNameEnum.FED_RD_TAX) {
        const stateRdPrograms = this.company.programs.filter(
          (p) =>
            StatePrograms.includes(p.name) && p.taxYear === program.taxYear,
        );
        let stateCreditAmount = 0;
        let stateOrderFormEstimate = 0;

        // add all available state rd credit amount
        for (const state of stateRdPrograms) {
          if (!InactiveProgramStages.includes(state.stage)) {
            stateCreditAmount += state.creditAmountCents;
            if (state.orderForm?.estimatedTotalCreditCents) {
              stateOrderFormEstimate +=
                state.orderForm?.estimatedTotalCreditCents;
            }
          }
        }

        // use program.creditAmount over orderForm.estimate
        const estimateCent =
          stateCreditAmount + program.creditAmountCents ||
          stateOrderFormEstimate + programOrderEstimate!;

        return estimateCent;
      } else {
        return program.creditAmountCents;
      }
    } else if (programOrderEstimate && programOrderEstimate > 0) {
      // using order form estimate as backup
      if (program.name === ProgramNameEnum.FED_RD_TAX) {
        const stateRdPrograms = this.company.programs.filter(
          (p) =>
            StatePrograms.includes(p.name) && p.taxYear === program.taxYear,
        );
        let stateOrderFormEstimate = 0;

        // add all available state rd order form estimate amount
        for (const state of stateRdPrograms) {
          if (state.orderForm?.estimatedTotalCreditCents) {
            stateOrderFormEstimate +=
              state.orderForm?.estimatedTotalCreditCents;
          }
        }

        return stateOrderFormEstimate + programOrderEstimate;
      } else {
        return programOrderEstimate;
      }
    } else {
      return 0;
    }
  }

  private getProgressStatus(program: ProgramData) {
    switch (program.stage) {
      case ProgramStageEnum.DISQUALIFIED:
        return AssessmentProgressStatus.DQ;
      case ProgramStageEnum.EXPENSE_CLASSIFICATION:
        if (
          program.subStage ===
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT
        ) {
          return AssessmentProgressStatus.READY_TO_SUBMIT;
          // TODO: After merging the new skipped substage
          // } else if (program.subStage === ProgramSubStageEnum.SKIPPED) {
          //   return AssessmentProgressStatus.SKIPPED;
        } else {
          return AssessmentProgressStatus.IN_PROGRESS;
        }
      case ProgramStageEnum.MS_REVIEW:
        return AssessmentProgressStatus.MS_REVIEW;
      case ProgramStageEnum.CLIENT_REVIEW:
        return AssessmentProgressStatus.CLIENT_REVIEW;
      case ProgramStageEnum.FINISHED:
        return AssessmentProgressStatus.FINISHED;
      default:
        return AssessmentProgressStatus.NOT_STARTED;
    }
  }

  public getGeneralBusinessStatus(taxYear: number) {
    if (this.hasFinishedGeneralBusiness(taxYear)) {
      return AssessmentProgressStatus.MS_REVIEW;
    } else if (this.hasStartedGeneralBusiness(taxYear)) {
      return AssessmentProgressStatus.IN_PROGRESS;
    } else {
      return AssessmentProgressStatus.NOT_STARTED;
    }
  }

  private hasStartedGeneralBusiness(taxYear: number) {
    const company = this.company;
    const questionByUnifiedCreationTaxYear =
      company.qualificationQuestionsByYear &&
      company.qualificationQuestionsByYear[taxYear];

    if (questionByUnifiedCreationTaxYear) {
      const hasAnsweredControlGroup =
        questionByUnifiedCreationTaxYear[
          GraphCmsQuestionIdEnum.ARE_YOU_IN_CONTROL_GROUP
        ];
      const hasAnsweredMoreThan50Percent =
        questionByUnifiedCreationTaxYear[
          GraphCmsQuestionIdEnum.ANYONE_OWN_MORE_THAN_50PERCENT
        ];

      if (hasAnsweredControlGroup && hasAnsweredMoreThan50Percent) {
        return true;
      }
    }

    return false;
  }

  private hasFinishedGeneralBusiness(taxYear: number) {
    const company = this.company;
    const questionByUnifiedCreationTaxYear =
      company.qualificationQuestionsByYear &&
      company.qualificationQuestionsByYear[taxYear];

    if (questionByUnifiedCreationTaxYear) {
      const hasTransitionedEntity =
        questionByUnifiedCreationTaxYear[
          GraphCmsQuestionIdEnum.HAS_TRANSITIONED_CORPORATE_ENTITY_TYPE
        ];
      const hasEntityTransitionYear =
        questionByUnifiedCreationTaxYear[
          GraphCmsQuestionIdEnum.ENTITY_TYPE_TRANSITION_YEAR
        ];
      const hasEntityFoundingYear =
        questionByUnifiedCreationTaxYear[
          GraphCmsQuestionIdEnum.ORIGINAL_ENTITY_FOUNDING_YEAR
        ];

      if (
        hasTransitionedEntity ||
        hasEntityTransitionYear ||
        hasEntityFoundingYear
      ) {
        return true;
      }
    }

    return false;
  }

  // determining new users
  private get hasUnifiedAccountCreation() {
    const company = this.company;
    const processingTaxYear = this.featureFlags.currentProcessingTaxYear;
    const questionByUnifiedCreationTaxYear =
      company.qualificationQuestionsByYear &&
      company.qualificationQuestionsByYear[processingTaxYear];

    if (questionByUnifiedCreationTaxYear) {
      const hasAnsweredOfferingRetirementQuestion =
        questionByUnifiedCreationTaxYear[
          RetirementPlanCreditQuestions.START_OFFERING_RETIREMENT_PLAN
        ];
      const hasAnsweredHealthcareInsuranceQuestion =
        questionByUnifiedCreationTaxYear[
          HealthcarePlanCreditQuestions.DID_YOU_PAY_50PERCENT_HEALTH_INSURANCE
        ];

      if (
        hasAnsweredOfferingRetirementQuestion &&
        hasAnsweredHealthcareInsuranceQuestion
      ) {
        return true;
      }
    }

    return false;
  }

  public async createAllPrograms(taxYear: number) {
    // We only want to prefill QuickBooks customers, and prefill requires a valid
    // access code.
    const isValidQuickBooksReferral =
      this.company.misc?.partnerReferral === 'quickbooks' &&
      !!this.company.misc?.partnerAccessCode;

    const programsToCreate = [
      ProgramNameEnum.FED_RD_TAX,
      ProgramNameEnum.FED_RETIREMENT_CREDIT,
      ProgramNameEnum.FED_DISABLED_ACCESS,
      ProgramNameEnum.FED_EMPLOYER_HEALTHCARE,
      ProgramNameEnum.ERC,
      ProgramNameEnum.WOTC,
    ];

    const checkFeatureFlag = (
      programName: ProgramNameEnum,
    ): boolean | undefined => {
      switch (programName) {
        case ProgramNameEnum.FED_RETIREMENT_CREDIT:
          if (this.featureFlags.showRetirementCreditInEstimates) {
            return true;
          }
          break;
        case ProgramNameEnum.FED_DISABLED_ACCESS:
          if (this.featureFlags.showDisabilityCreditInEstimates) {
            return true;
          }
          break;
        case ProgramNameEnum.FED_EMPLOYER_HEALTHCARE:
          if (this.featureFlags.showHealthcarePlanCreditInEstimates) {
            return true;
          }
          break;
        case ProgramNameEnum.ERC:
          // We only want to create ERC programs for QuickBooks referrals that
          // have a valid access code
          if (
            this.featureFlags.showERCCreditInEstimates &&
            isValidQuickBooksReferral
          ) {
            return true;
          }
          break;
        case ProgramNameEnum.WOTC:
          if (this.featureFlags.showWorkOpportunityCreditInEstimates) {
            return true;
          }
          break;
        case ProgramNameEnum.FED_RD_TAX:
          return true;
      }
    };

    const { programs } = this.company;

    for (const programName of programsToCreate) {
      const hasTaxCreditProgram = programs.some(
        (p) => p.name === programName && p.taxYear === taxYear,
      );

      if (!hasTaxCreditProgram && checkFeatureFlag(programName)) {
        if (programName === ProgramNameEnum.ERC) {
          // ERC programs are a special case and are created for two specific tax years
          await this.client.CreateProgramV1({
            name: programName,
            taxYear: 2020,
            prefill: isValidQuickBooksReferral,
          });
          await this.client.CreateProgramV1({
            name: programName,
            taxYear: 2021,
            prefill: isValidQuickBooksReferral,
          });
        } else if (programName === ProgramNameEnum.FED_RD_TAX) {
          // Create an R&D program for the current year and the previous year (DQ'd)
          await this.client.CreateProgramV1({
            name: programName,
            taxYear,
            prefill: isValidQuickBooksReferral,
          });
          await this.client.CreateProgramV1({
            name: programName,
            taxYear: taxYear - 1,
            stage: ProgramStageEnum.DISQUALIFIED,
            prefill: isValidQuickBooksReferral,
          });
        } else {
          await this.client.CreateProgramV1({
            name: programName,
            taxYear,
            prefill: isValidQuickBooksReferral,
          });
        }

        datadogLogs.logger.info(
          `[COMPANY_CREATION]: Created ${taxYear} ${programName} program for company ${this.company.id}.`,
          logContext({ company: this.company }),
        );
      }
    }
  }

  public setShowTimelineSideDrawer(bool: boolean) {
    runInAction(() => (this.showTimelineSideDrawer = bool));
  }

  // survey related
  public setFlowIndex(step: number) {
    runInAction(() => {
      if (this.flowCurrentIndex !== this.flowPrevIndex) {
        this.flowPrevIndex = this.flowCurrentIndex;
      }
      this.flowCurrentIndex = step;
    });
  }

  public setCurrentStepOnLoad(pathname: string, taxYear: number) {
    const taxYearString = taxYear.toString();
    const assessmentRootPath = `/${Page.taxCredits}/${Page.assessment}/${taxYearString}`;

    runInAction(() => {
      switch (pathname) {
        case `${assessmentRootPath}`:
          this.setFlowIndex(AssessmentSurveyStepsIndex.BUSINESS_DETAILS);
          break;
        case `${assessmentRootPath}/${Page.retirementPlan}`:
          this.setFlowIndex(AssessmentSurveyStepsIndex.RETIREMENT_PLAN);
          break;
        case `${assessmentRootPath}/${Page.healthcare}`:
          this.setFlowIndex(
            AssessmentSurveyStepsIndex.SMALL_BUSINESS_HEALTHCARE,
          );
          break;
        case `${assessmentRootPath}/${Page.expenseClassificationCompanyDetails}`:
          this.setFlowIndex(AssessmentSurveyStepsIndex.RD_COMPANY_DETAILS);
          break;
        case `${assessmentRootPath}/${Page.expenseClassificationSuppliesServices}`:
          this.setFlowIndex(AssessmentSurveyStepsIndex.RD_SUPPLIES_SERVICES);
          break;
        case `${assessmentRootPath}/${Page.expenseClassificationEmployees}`:
          this.setFlowIndex(AssessmentSurveyStepsIndex.RD_EMPLOYEES);
          break;
        case `${assessmentRootPath}/${Page.disabledAccess}`:
          this.setFlowIndex(AssessmentSurveyStepsIndex.DISABLED_ACCESS);
          break;
      }
    });
  }

  public getTotalCreditSofar(taxYear: number) {
    const { programs } = this.company;
    const filteredPrograms = programs.filter(
      (p) => p.taxYear === taxYear && p.stage !== ProgramStageEnum.DISQUALIFIED,
    );
    let totalCreditSoFar = 0;

    if (filteredPrograms.length > 0) {
      for (const program of filteredPrograms) {
        const programOrderEstimate =
          program.orderForm?.estimatedTotalCreditCents || 0;

        if (program.creditAmountCents > 0) {
          totalCreditSoFar += program.creditAmountCents;
        } else if (program.creditAmountCents === 0) {
          totalCreditSoFar += programOrderEstimate;
        }
      }
    }

    runInAction(() => (this.totalCreditSoFar = totalCreditSoFar));
  }

  public setShowAllSectionsDrawer(bool: boolean) {
    runInAction(() => (this.showAllSectionsDrawer = bool));
  }

  public async updateProgramStageOnSurveyEntry(
    taxYear: number,
    programName: ProgramNameEnum,
    programSubStage?: ProgramSubStageEnum,
  ) {
    const { programs } = this.company;
    const updateProgram = programs.find(
      (p) => p.taxYear === taxYear && p.name === programName,
    );

    if (updateProgram && updateProgram.stage === ProgramStageEnum.QUALIFYING) {
      await this.rootStore.taxcredits.surveyFlow.updateProgramStageStatus(
        programName,
        taxYear,
        ProgramStageEnum.EXPENSE_CLASSIFICATION,
        QualificationStatusEnum.QUALIFICATION_IN_PROGRESS,
        programSubStage ??
          ProgramSubStageEnum.EXPENSE_CLASSIFICATION_COMPANY_DETAILS,
      );
    }
  }

  public async getEstimatesForAssessment(
    programName: ProgramNameEnum,
    programId: number,
    accessToken: CompanyAccessToken | undefined,
  ) {
    if (
      programName === ProgramNameEnum.FED_RETIREMENT_CREDIT ||
      programName === ProgramNameEnum.FED_DISABLED_ACCESS ||
      programName === ProgramNameEnum.FED_EMPLOYER_HEALTHCARE
    ) {
      const res = accessToken
        ? await this.client.GetProgramCreditEstimatePublic(
            accessToken,
            programId,
          )
        : await this.client.GetProgramCreditEstimate(programId);

      if (res.data && res.data?.estimate) {
        const creditEstimate = res.data?.estimate.totalEstimate.amount;

        if (creditEstimate > 0) {
          const updateReq = {
            creditAmountCents: creditEstimate,
            qualificationStatus: QualificationStatusEnum.QUALIFIED,
            stage: ProgramStageEnum.EXPENSE_CLASSIFICATION,
            subStage:
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT,
          };

          await this.updateProgram(programId, updateReq, accessToken);

          accessToken
            ? await this.client.CreateOrderFormPublic(accessToken, programId)
            : await this.client.CreateOrderForm(programId);
        } else {
          // disqualify program is 0
          const updateReq: UpdateProgramRequest = {
            qualificationStatus: QualificationStatusEnum.DISQUALIFIED,
            stage: ProgramStageEnum.DISQUALIFIED,
            subStage:
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT,
          };

          await this.updateProgram(programId, updateReq, accessToken);
        }
      }

      if (res.errorMsg) {
        datadogLogs.logger.error(
          `[PROGRAM PLAN CREDIT_ESTIMATE]: Error retrieving credit estimates for program_name: ${programName} AND program_id: ${programId} ${
            accessToken ? `AND accessToken: ${accessToken}` : ''
          }`,
          logContext({
            company: this.company,
            error: res.errorMsg,
          }),
        );
      }
    }

    // refresh current company and assessment credits/statuses
    runInAction(async () => {
      await this.rootStore.common.companyStore.refreshCurrentCompany();
    });
  }

  public async updateProgram(
    programId: number,
    updateReq: UpdateProgramRequest,
    accessToken?: CompanyAccessToken,
  ) {
    if (accessToken) {
      await this.client.UpdateProgramPublic(accessToken, programId, updateReq);
    } else {
      await this.client.UpdateProgramV1(programId, updateReq);
    }
  }

  public setShowSubmitReviewModal(bool: boolean) {
    runInAction(() => (this.showSubmitReviewModal = bool));
  }

  public setShowShareAssessmentModal(bool: boolean, sourcePage?: Page) {
    runInAction(() => {
      this.sourcePage = sourcePage;
      this.showShareAssessmentModal = bool;
    });
  }

  public setShareAssessmentOnNext(onNext: () => void) {
    runInAction(() => {
      this.shareAssessmentOnNext = onNext;
    });
  }

  public setShowInviteeModal(bool: boolean) {
    runInAction(() => {
      this.showInviteeModal = bool;
    });
  }

  public setShowInviteeSuccessModal(bool: boolean) {
    runInAction(() => {
      this.showInviteeSuccessModel = bool;
    });
  }

  public setShowExpandCreditBreakdown(bool: boolean) {
    runInAction(() => (this.showExpandCreditBreakdown = bool));
  }

  public setShowInReviewTitleSideDrawer(bool: boolean) {
    runInAction(() => (this.showInReviewTitleSideDrawer = bool));
  }

  public setShowInProgressSideDrawer(bool: boolean) {
    runInAction(() => (this.showInProgressTitleSideDrawer = bool));
  }

  public setShowClientTitleSideDrawer(bool: boolean) {
    runInAction(() => (this.showClientTitleSideDrawer = bool));
  }

  public async checkLatestEstimatesInSection(
    taxYear: number,
    programName: ProgramNameEnum,
    accessToken?: CompanyAccessToken,
  ) {
    // reset assessment data
    runInAction(() => {
      this.assessmentByYear = {};
      this.surveyAccumulated = null;
    });
    // get latest assessment data
    await this.getTaxCreditAssessments(taxYear, accessToken);
    const surveyAccumulated = this.assessmentByYear[taxYear].find(
      (credit) =>
        credit.id === TaxCreditAssessmentProgramsHygraphId[programName] &&
        credit.creditAmount,
    );

    if (surveyAccumulated) {
      runInAction(() => (this.surveyAccumulated = surveyAccumulated));
    }
  }

  public async setShowAccumulatedModal(onNext?: () => void) {
    runInAction(() => (this.showAccumulatedModal = true));

    await runInAction(async () => {
      setTimeout(() => {
        this.showAccumulatedModal = false;
        // move forward in survey after delay
        onNext && onNext();
      }, 5000);
    });
  }

  public isAllAssessmentReadyForReview(taxYear: number) {
    const filterDqPrograms =
      this.assessmentByYear[taxYear] &&
      this.assessmentByYear[taxYear].filter(
        (p) =>
          p.status !== AssessmentProgressStatus.DQ &&
          p.id !== TaxCreditAssessmentGeneralHygraphId.GENERAL_BUSINESS_DETAILS,
      );

    const hasAllAssessmentsInReview =
      filterDqPrograms &&
      filterDqPrograms.length > 0 &&
      filterDqPrograms.every(
        (p) =>
          p.status === AssessmentProgressStatus.MS_REVIEW ||
          p.status === AssessmentProgressStatus.CLIENT_REVIEW ||
          p.status === AssessmentProgressStatus.FINISHED ||
          (p.status === AssessmentProgressStatus.IN_PROGRESS &&
            p.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT) ||
          p.subStage ===
            ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED,
      );

    return hasAllAssessmentsInReview;
  }

  public areAllAssessmentsDisqualified(taxYear: number) {
    const activePrograms = this.assessmentByYear[taxYear].filter(
      (p) =>
        p.status !== AssessmentProgressStatus.DQ &&
        p.id !== TaxCreditAssessmentGeneralHygraphId.GENERAL_BUSINESS_DETAILS,
    );

    return activePrograms.length === 0;
  }

  public setHasJustCompletedAllAssessments(bool: boolean) {
    runInAction(() => (this.hasJustCompletedAllAssessments = bool));
  }

  public isAllAssessmentsClientReview(taxYear: number) {
    const filterDqPrograms =
      this.assessmentByYear[taxYear] &&
      this.assessmentByYear[taxYear].filter(
        (p) =>
          p.status !== AssessmentProgressStatus.DQ &&
          p.id !== TaxCreditAssessmentGeneralHygraphId.GENERAL_BUSINESS_DETAILS,
      );

    const hasAllAssessmentsInClientReview =
      filterDqPrograms &&
      filterDqPrograms.length > 0 &&
      filterDqPrograms.every(
        (p) => p.status === AssessmentProgressStatus.CLIENT_REVIEW,
      );

    return hasAllAssessmentsInClientReview;
  }

  public isAllAssessmentsDisqualified(taxYear: number) {
    const notGeneralBusinessDetail = this.assessmentByYear[taxYear].filter(
      (p) =>
        p.id !== TaxCreditAssessmentGeneralHygraphId.GENERAL_BUSINESS_DETAILS,
    );

    const hasAllAssessmentsInDQ =
      notGeneralBusinessDetail.length > 0 &&
      notGeneralBusinessDetail.every(
        (p) => p.status === AssessmentProgressStatus.DQ,
      );

    return hasAllAssessmentsInDQ;
  }

  public hasFiledIncomeTax(taxYear: number) {
    const { programs } = this.company;
    const hasFilingDate = programs.filter(
      (p) =>
        p.stage !== ProgramStageEnum.DISQUALIFIED &&
        p.taxYear === taxYear &&
        p.taxFilingDate,
    );

    if (hasFilingDate.length > 0) {
      const filterDqPrograms = this.assessmentByYear[taxYear].filter(
        (p) =>
          p.status !== AssessmentProgressStatus.DQ &&
          p.id !== TaxCreditAssessmentGeneralHygraphId.GENERAL_BUSINESS_DETAILS,
      );

      const hasFiledTaxes = filterDqPrograms.every(
        (p) => p.status === AssessmentProgressStatus.FINISHED,
      );

      return hasFiledTaxes;
    } else {
      return false;
    }
  }

  public hasBeganRedeemingStage(taxYear: number) {
    const { programs } = this.company;
    const isInRedeemingStage = programs.filter(
      (p) =>
        p.stage !== ProgramStageEnum.DISQUALIFIED &&
        p.taxYear === taxYear &&
        p.stage === ProgramStageEnum.FINISHED &&
        p.subStage === ProgramSubStageEnum.REDEEMING,
    );

    if (isInRedeemingStage.length > 0) {
      const filterDqPrograms = this.assessmentByYear[taxYear].filter(
        (p) =>
          p.status !== AssessmentProgressStatus.DQ &&
          p.id !== TaxCreditAssessmentGeneralHygraphId.GENERAL_BUSINESS_DETAILS,
      );

      const hasProgramsInFinished = filterDqPrograms.every(
        (p) => p.status === AssessmentProgressStatus.FINISHED,
      );

      return hasProgramsInFinished;
    } else {
      return false;
    }
  }

  public setLinkCopied(bool: boolean) {
    runInAction(() => (this.linkCopied = bool));
  }

  public async setConfirmFiling(taxYear: number) {
    const company = this.company;
    const eventType =
      AttestationEventTypeEnum.CLIENT_REVIEW_DOCUMENTS_DOWNLOADED;
    const userEmail = this.common.auth.user?.email || this.company.adminEmail;
    const companyId = company.id;
    const res = await this.client.UpdateCompanyEventAttestation(
      companyId,
      eventType,
      taxYear,
      userEmail,
    );

    if (res.errorMsg || !res.data) {
      datadogLogs.logger.error(
        '[COMPANY FILING]: Error saving company confirmation filing attestation',
        logContext({ company: this.company, error: res.errorMsg }),
      );
      return;
    }
  }

  public async getCompanyAttestation(taxYear: number) {
    const company = this.company;
    const eventType =
      AttestationEventTypeEnum.CLIENT_REVIEW_DOCUMENTS_DOWNLOADED;
    const fedProgram = company.programs.find(
      (p) => p.taxYear === taxYear && p.name === ProgramNameEnum.FED_RD_TAX,
    );

    const res = await this.client.GetCompanyEventAttestation(
      company.id,
      taxYear,
      eventType,
    );

    if (res.data && res.data.confirmedFiling) {
      const confirmedFiling = res.data.confirmedFiling;

      if (fedProgram) {
        runInAction(() => (this.filingFedProgram = fedProgram));
      }

      runInAction(() => (this.confirmedFilingCheckbox = confirmedFiling));
    }

    if (res.errorMsg) {
      datadogLogs.logger.error(
        '[COMPANY FILING]: Error getting company confirmation filing attestation',
        logContext({ company: this.company, error: res.errorMsg }),
      );
      return;
    }
  }

  public getTotalCreditAmountCents(taxYear: number) {
    const totalCredit = this.assessmentByYear[taxYear].reduce((total, curr) => {
      if (
        curr['creditAmountCents'] &&
        (curr['status'] === AssessmentProgressStatus.CLIENT_REVIEW ||
          curr['status'] === AssessmentProgressStatus.FINISHED)
      ) {
        return total + curr['creditAmountCents'];
      } else {
        return total; // Don't forget to return total in case the condition is not met
      }
    }, 0);

    runInAction(() => (this.totalCreditAmountCents = totalCredit));
  }

  public setShowTaxFormsSideDrawer(bool: boolean) {
    runInAction(() => (this.showTaxFormsSideDrawer = bool));
  }

  public async getTaxYearDocuments(taxYear: number) {
    const { programs } = this.company;
    const res = await this.client.GetCompanyDocuments({ source: 'mainstreet' });

    const clientReviewPrograms = programs.filter(
      (p) =>
        p.taxYear === taxYear &&
        p.stage === ProgramStageEnum.CLIENT_REVIEW &&
        !StatePrograms.includes(p.name),
    );

    const taxYearDocuments: Document[] = [];

    if (res.data) {
      const documents = res.data.documents;

      const filtersProgramDocuments = clientReviewPrograms.map((program) => {
        const programDocuments = documents.filter(
          (document) => document.program_id === program.id,
        );
        // push tax year documents into an array
        taxYearDocuments.push(...programDocuments);

        return { programName: program.name, documents: programDocuments };
      });

      runInAction(() => {
        this.viewTaxForms = filtersProgramDocuments;
        this.allTaxYearDocuments = taxYearDocuments;
      });
    }

    if (res.errorMsg) {
      datadogLogs.logger.error(
        '[VIEW TAX FORMS]: Client review - failed to get tax form documents',
        logContext({ company: this.company, error: res.errorMsg }),
      );
      return;
    }
  }

  public setShowCongratsModal(shouldShow: boolean, taxYear: number) {
    if (shouldShow) {
      const { programs } = this.company;

      // Find the current R&D program credit type
      const fedRdProgram = programs.find(
        (program) =>
          program.taxYear === taxYear &&
          program.name === ProgramNameEnum.FED_RD_TAX,
      );
      const fedRdCreditType = fedRdProgram?.filingCreditType || null;

      // Find if any tax credit programs are income credits
      const hasIncomeCreditType = programs
        .filter((program) => program.taxYear === taxYear)
        .some(
          (program) =>
            program.filingCreditType === ExpectedCreditTypeEnum.INCOME_TAX,
        );

      if (fedRdCreditType !== ExpectedCreditTypeEnum.PAYROLL_TAX) {
        this.congratsModalText =
          'When you filed your taxes, you redeemed your tax credits to reduce your income tax liability. If you had any leftover credit, it will be used to reduce future taxes.';
      } else {
        if (hasIncomeCreditType) {
          this.congratsModalText =
            'Some of your tax credits have already been applied to reduce your current or future income tax liability. Now, you just have one more step to redeem your R&D credits.';
        } else {
          this.congratsModalText =
            'You now have one last step to redeem your credits. You can think of your R&D credit like a gift card – filing your taxes is like buying the gift card, and this last step is like using the gift card.';
        }
      }
    }

    runInAction(() => (this.showCongratsModal = shouldShow));
  }

  public setLoadingClientReview(bool: boolean) {
    runInAction(() => (this.loadingClientReview = bool));
  }

  public async updateAllProgramStageToFinished(taxYear: number) {
    const { programs } = this.company;
    const programsInClientReview = programs.filter(
      (p) =>
        p.stage === ProgramStageEnum.CLIENT_REVIEW && p.taxYear === taxYear,
    );

    // determine filing credit tax type
    this.determineCreditTaxType(programsInClientReview, taxYear);

    if (programsInClientReview.length > 0) {
      for (const program of programsInClientReview) {
        await this.rootStore.taxcredits.form8974.updateProgramStage(
          program.id,
          ProgramStageEnum.FINISHED,
          ProgramSubStageEnum.READY_TO_REDEEM,
        );
      }
    }
  }

  public determineCreditTaxType(programs: ProgramData[], taxYear: number) {
    const nonStatePrograms = programs.filter(
      (p) => !StatePrograms.includes(p.name) && p.taxYear === taxYear,
    );

    const hasPayrollTaxType = nonStatePrograms.some(
      (p) => p.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX,
    );
    const hasAllIncomeTaxType = nonStatePrograms.every(
      (p) => p.filingCreditType === ExpectedCreditTypeEnum.INCOME_TAX,
    );

    if (hasAllIncomeTaxType) {
      runInAction(
        () =>
          (this.finishedStageCreditType = ExpectedCreditTypeEnum.INCOME_TAX),
      );
    } else if (hasPayrollTaxType) {
      runInAction(
        () =>
          (this.finishedStageCreditType = ExpectedCreditTypeEnum.PAYROLL_TAX),
      );
    }
  }

  public async get6765Data(
    programId: number,
    accessToken?: CompanyAccessToken,
  ) {
    const get6765Data = accessToken
      ? this.client.Get6765DataPublic(accessToken, programId)
      : this.client.Get6765Data(programId);

    const res = await get6765Data;

    if (res.data) {
      const formData = res.data.formData;
      const update6765 = accessToken
        ? this.client.Update6765Public(accessToken, programId, formData)
        : this.client.Update6765(programId, formData);

      const formResponse = await update6765;

      if (formResponse.errorMsg) {
        datadogLogs.logger.error(
          '[ERROR UPDATING 6765]: error updating form data for 6765 to get accurate estimate',
          logContext({ company: this.company, error: res.errorMsg }),
        );
        return;
      }
    }
  }

  public async getStateRDData(
    programId: number,
    programName: ProgramNameEnum,
    accessToken?: CompanyAccessToken,
  ) {
    // Only CA has a credit generation service currently
    if (programName === ProgramNameEnum.STATE_RD_CA) {
      const get3523Data = accessToken
        ? this.client.Get3523DataPublic(accessToken, programId)
        : this.client.Get3523Data(programId);

      const res = await get3523Data;

      if (res.errorMsg) {
        datadogLogs.logger.error(
          '[ERROR UPDATING 3523]: error calculating form data for 3523 to get accurate estimate',
          logContext({ company: this.company, error: res.errorMsg }),
        );
        return;
      }
    }
  }

  public setShowIncompleteProgramModal(bool: boolean) {
    runInAction(() => (this.showIncompleteProgramModal = bool));
  }

  public async updateAccountCreationPrefill(taxYear: number) {
    const company = this.company;
    const { id: companyId } = company;
    const shortCode = company.misc?.partnerAccessCode;

    if (shortCode) {
      const req: UpdateCompanyQBDataRequest = {
        shortCode,
        taxYear,
        companyId,
      };
      const res = await this.client.UpdateCompanyQBData(req);
      // refresh current company after program updates
      await this.rootStore.common.companyStore.refreshCurrentCompany();

      if (res.errorMsg) {
        datadogLogs.logger.error(
          '[ERROR UPDATED QB Prefill]: error updating QB prefill data',
          logContext({ company: this.company, error: res.errorMsg }),
        );
        return;
      }
    }
  }

  public handleShowSubmitForReview = (
    taxYear: number,
    company: Company,
    openPayment: () => void,
    allowIncompleteModal: boolean,
  ) => {
    const hasAllAssessmentsInReview =
      this.isAllAssessmentReadyForReview(taxYear);
    const filterDqPrograms =
      this.assessmentByYear[taxYear] &&
      this.assessmentByYear[taxYear].filter(
        (p) =>
          p.status !== AssessmentProgressStatus.DQ &&
          p.id !== TaxCreditAssessmentGeneralHygraphId.GENERAL_BUSINESS_DETAILS,
      );
    const atLeastOneSurveyNotInMsReview =
      filterDqPrograms &&
      filterDqPrograms.filter(
        (p) => p.status !== AssessmentProgressStatus.MS_REVIEW,
      ).length > 0;

    const companyHasManuallyAcceptedOrderForm = HasManuallyAcceptedOrderForm(
      company.orderForms,
    );

    if (hasAllAssessmentsInReview && !this.showAccumulatedModal) {
      if (!companyHasManuallyAcceptedOrderForm) {
        openPayment();
      } else {
        this.setShowSubmitReviewModal(true);
      }
    } else if (atLeastOneSurveyNotInMsReview && allowIncompleteModal) {
      this.setShowIncompleteProgramModal(true);
    }
  };

  public hasQualifyingERCProgram() {
    const { programs } = this.company;
    return programs.some(
      (p) =>
        p.name === ProgramNameEnum.ERC &&
        p.stage !== ProgramStageEnum.DISQUALIFIED &&
        p.stage !== ProgramStageEnum.CANCELED,
    );
  }

  public hasCompletedFedProgram() {
    const { programs } = this.company;

    return programs.some(
      (program) =>
        program.name === ProgramNameEnum.FED_RD_TAX &&
        program.stage !== ProgramStageEnum.DISQUALIFIED &&
        program.qualificationStatus !== QualificationStatusEnum.DISQUALIFIED &&
        ((program.stage === ProgramStageEnum.FINISHED &&
          program.subStage === ProgramSubStageEnum.REDEEMING) ||
          program.stage === ProgramStageEnum.COMPLETED),
    );
  }

  public getPartnerChargePercentage = async (): Promise<string> => {
    const partner: string =
      this.company.misc?.partnerReferral?.toLowerCase() || '';

    if (!partner) {
      return this.defaultChargePercentageString;
    }

    try {
      const response = await this.client.GetPartnerReferral(partner);
      if (
        response.data &&
        response.data.referral &&
        response.data.referral.length > 0
      ) {
        return `${response.data.referral[0].feePercentage}%`;
      } else {
        // No partner landing pages found or missing fee percentage
        return this.defaultChargePercentageString;
      }
    } catch (error) {
      datadogLogs.logger.error(error);
      return this.defaultChargePercentageString;
    }
  };

  public getPartnerFinalCreditAmountText = (): string => {
    const partner: string =
      this.company.misc?.partnerReferral?.toLowerCase() || '';
    switch (partner) {
      case 'onpay':
        return 'of your final credit amount for your first tax year';
      default:
        return 'of your final credit amount';
    }
  };

  public shareAssessmentPage = async (
    taxYear: number,
    page: Page,
    emailAddresses: string[],
  ) => {
    await this.client.ShareAssessmentPage(taxYear, page, emailAddresses);
  };

  public async GetRDProjects(
    program: ProgramData,
    accessToken?: CompanyAccessToken,
  ) {
    const res = accessToken
      ? await this.client.GetRDProjectsPublic(accessToken, program.id)
      : await this.client.GetRDProjects(program.id);

    if (res.data?.rdProjects) {
      program.rdProjects = res.data.rdProjects;
    } else {
      program.rdProjects = [];
    }
  }

  public async Determine8974Completion(companyId: number, taxYear: number) {
    const form8974Store = this.rootStore.taxcredits.form8974;

    // await form8974Store.fetchCompanyPayrollProvider(companyId);
    await form8974Store.refreshCompanyCreditBalance();

    const { payrollTier } = form8974Store;
    const isTier1or2Provider =
      payrollTier === PayrollTierEnum.TIER_1 ||
      payrollTier === PayrollTierEnum.TIER_2;

    // if tier 1 or 2
    if (isTier1or2Provider) {
      const programs = this.rootStore.common.companyStore.company.programs;
      const payrollTypeRDProgram = programs.filter(
        (p) =>
          p.taxYear === taxYear &&
          p.name === ProgramNameEnum.FED_RD_TAX &&
          p.filingCreditType === ExpectedCreditTypeEnum.PAYROLL_TAX,
      );

      const completed8974RdProgram = payrollTypeRDProgram.filter(
        (p) => p.payrollProviderSetupCompleted8974,
      );

      const isTier1or2Completed8974 = completed8974RdProgram.length > 0;

      runInAction(() => (this.hasCompleted8974 = isTier1or2Completed8974));
    } else {
      const tier3LedgerBalance = form8974Store.creditBalanceCents;
      const tier3Completed8974 = tier3LedgerBalance === 0;

      runInAction(() => (this.hasCompleted8974 = tier3Completed8974));
    }
  }
}
