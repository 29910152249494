import React, { useEffect } from 'react';
import {
  Card,
  Color,
  Content,
  Dollar,
  Icon,
  IconEnum,
  Link,
  Text,
  Flex,
  Tooltip,
} from 'component-library';
import { ErrorMessage } from 'components/util/ErrorMessage';
import { makeStyles } from '@material-ui/core';
import {
  CentsToDisplayString,
  CentsToDisplayStringNoSymbol,
} from 'lib/helpers';
import { CarryoverAndAdjustStatusEnum, PayrollTierEnum } from 'lib/constants';
import { observer } from 'mobx-react';
import { useTaxCreditsStores } from 'stores/useStores';
import { TaxCreditsBalanceCardContent } from 'pages/dashboard/taxCredits/TaxCreditsBalanceCardContent';
import { Form8974Tier3SideDrawer } from './Form8974Tier3SideDrawer';
import { Tier1OrTier2Form8974SideDrawer } from '../../fed-rd-program/8974/Tier1OrTier2Form8974SideDrawer';

const useStyles = makeStyles(() => ({
  dollar: {
    fontFamily: "'Alliance Platt', serif",
    fontWeight: 700,
  },
  footerContainer: {
    padding: '16px 24px 16px',
    backgroundColor: Color.neutral._light_20,
    borderTop: `1px solid ${Color.neutral._20}`,
  },
  alert: { marginTop: '24px' },
  totalCreditContainer: {
    backgroundColor: Color.blue._10,
    borderLeft: `1px solid ${Color.neutral._20}`,
  },
  tooltip: {
    verticalAlign: 'sub',
    color: '#67747D',
    display: 'inline-flex',
  },
  labelWithIcon: { display: 'flex', alignItems: 'center' },
  infoIcon: {
    fontSize: '18px',
    marginLeft: '2px',
    color: Color.neutral._60,
  },
  calculatingGraphic: {
    backgroundColor: Color.neutral._light_20,
  },
  rowBorder: {
    borderBottom: `1px solid ${Color.neutral._20}`,
    borderRight: 'none',
  },
}));

export interface CreditBalanceCardProps {
  className: string;
}

export const CreditBalanceCard = observer(
  ({ className }: CreditBalanceCardProps) => {
    const { form8974, redemption } = useTaxCreditsStores();
    const label = `${form8974.mostRecentFinishedProgram?.taxYear} Federal R&D Credits`;
    const classes = useStyles();
    const showTier3CreditAmounts =
      form8974.payrollTier === PayrollTierEnum.TIER_3 &&
      form8974.totalRdCreditWithdrawnCents !== 0;

    useEffect(() => {
      form8974.getTotalTaxCredits();
      form8974.getAllIncomeCreditTotal();
    }, [form8974]);

    return (
      <div data-testid={'credit-balance-card'}>
        {form8974.requestError && (
          <ErrorMessage error={form8974.requestError} />
        )}
        <Card className={className}>
          {!redemption.currentFiling &&
            form8974.carryoverStatus ===
              CarryoverAndAdjustStatusEnum.PROGRAM_LEDGER_SETUP_REQUIRED && (
              <Content
                flex
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={16}
                className={classes.calculatingGraphic}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/calculating-graphic.svg`}
                  alt='Calculating graphic'
                />
                <Text size={13} variant={'medium'}>
                  We will calculate your remaining credits after you confirm
                  your tax filing date.
                </Text>
              </Content>
            )}
          {(redemption.currentFiling ||
            form8974.carryoverStatus !==
              CarryoverAndAdjustStatusEnum.PROGRAM_LEDGER_SETUP_REQUIRED) && (
            <Flex direction='column'>
              <Flex
                direction='column'
                padding={[16, 24]}
                className={classes.rowBorder}
              >
                <div className={classes.labelWithIcon}>
                  <Text
                    text={
                      form8974.payrollTier === PayrollTierEnum.TIER_3
                        ? 'Credits not yet redeemed'
                        : label
                    }
                    size={15}
                  />
                  <Tooltip
                    className={classes.tooltip}
                    text={`You have one or more actions remaining to actually use these credits`}
                    position='top'
                  >
                    <Icon
                      className={classes.infoIcon}
                      name={IconEnum.info_circle}
                      color='#67747D'
                    />
                  </Tooltip>
                </div>
                {showTier3CreditAmounts ? (
                  <Text size={23} className={classes.dollar}>
                    <>{CentsToDisplayString(form8974.creditBalanceCents)}</>
                  </Text>
                ) : (
                  <Dollar
                    value={CentsToDisplayStringNoSymbol(
                      form8974.totalTaxCredits,
                    )}
                    variant='medium'
                    tag='h2'
                  />
                )}
              </Flex>
              {showTier3CreditAmounts && (
                <Flex
                  direction='column'
                  padding={[16, 24]}
                  className={classes.rowBorder}
                >
                  <div>
                    <Text
                      text={
                        form8974.payrollTier === PayrollTierEnum.TIER_3
                          ? 'Credits redeemed so far'
                          : 'Redeemed so far'
                      }
                      size={15}
                    />
                    <Text size={23} className={classes.dollar}>
                      <>
                        {CentsToDisplayString(
                          form8974.totalRdCreditWithdrawnCents +
                            form8974.totalIncomeAllCredits,
                        )}
                      </>
                    </Text>
                  </div>
                </Flex>
              )}
              {showTier3CreditAmounts && (
                <Flex
                  direction='column'
                  padding={[16, 24]}
                  className={classes.totalCreditContainer}
                >
                  <div>
                    <Text text='Total tax credits' size={15} />
                    <Text size={23} className={classes.dollar}>
                      <>{CentsToDisplayString(form8974.totalTaxCredits)}</>
                    </Text>
                  </div>
                </Flex>
              )}
            </Flex>
          )}

          {
            // All programs have been fully withdrawn
            // There are no programs that need to have their ledger set up
            // The most recent finished program has confirmed that they completed 8974 setup
            form8974.creditBalanceCents <= 0 &&
              form8974.carryoverStatus !==
                CarryoverAndAdjustStatusEnum.PROGRAM_LEDGER_SETUP_REQUIRED &&
              form8974.mostRecentFinishedProgram
                ?.payrollProviderSetupCompleted8974 && (
                <div className={classes.footerContainer}>
                  <Flex padding={[0, 0, 8, 0]} direction='column' gap={8}>
                    <Text
                      text={`Congratulations! You've fully redeemed all the credits you've filed with MainStreet. Enjoy that cashflow!`}
                    />
                    <Link
                      variant='medium'
                      href={
                        'https://mainstreet-help.force.com/help/s/article/Final-Steps-To-Claim-the-R-D-Tax-Credit-Toward-Payroll'
                      }
                      external
                    >
                      More details
                    </Link>
                  </Flex>
                </div>
              )
          }
          {form8974.mostRecentFinishedProgram &&
            // The customer still has a redeemable balance
            // There are no programs that need to have their ledger set up
            ((form8974.creditBalanceCents > 0 &&
              form8974.carryoverStatus !==
                CarryoverAndAdjustStatusEnum.PROGRAM_LEDGER_SETUP_REQUIRED) ||
              // OR, the customer has fully withdrawn their balance
              // There are no programs that need to have their ledger set up
              // But the customer has not confirmed that they completed 8974 set up on the most recent finished program
              (form8974.creditBalanceCents <= 0 &&
                form8974.carryoverStatus ===
                  CarryoverAndAdjustStatusEnum.ZERO_BALANCE &&
                !form8974.mostRecentFinishedProgram
                  ?.payrollProviderSetupCompleted8974) ||
              redemption.currentFiling) && (
              <div className={classes.footerContainer}>
                <TaxCreditsBalanceCardContent
                  program={form8974.mostRecentFinishedProgram}
                />
              </div>
            )}
          {form8974.mostRecentFinishedProgram ? (
            form8974.payrollTier === PayrollTierEnum.TIER_3 ? (
              <Form8974Tier3SideDrawer
                program={form8974.mostRecentFinishedProgram}
              />
            ) : (
              <Tier1OrTier2Form8974SideDrawer
                program={form8974.mostRecentFinishedProgram}
                show={form8974.sideDrawerOpen}
                onCancel={() => form8974.toggleSideDrawer(false)}
                onComplete={(programId: number) =>
                  form8974.sideDrawerOnComplete(programId)
                }
              />
            )
          ) : (
            <></>
          )}
        </Card>
      </div>
    );
  },
);
