import { SurveyFlowStore, UnifiedTaxCreditsStore } from '../../../stores';
import {
  AttestationEventTypeEnum,
  CompanyAccessToken,
  Page,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationModalStatusEnum,
  QualificationStatusEnum,
} from '../../../../../lib/constants';
import { SaveSurveyAttestation } from '../../../../../pages/tax-processing/expense-classification/components/expense-classification/SurveyAttestation';
import { CompanyStore } from '../../../../../stores/common/CompanyStore';
import { ProgramData } from '../../../../../lib/interfaces';
import { ServerClient } from '../../../../../services/ServerClient';
import { AuthStore } from '../../../../../stores/common/AuthStore';
import { ModuleStore } from '../../../../dashboard/stores';
import { AppStore } from '../../../../../stores/common/AppStore';
import { SaveAnswersResponse } from 'lib/useSurveyQuestions';

/**
 * Handles onNext function calling given the showReviewPage ff and accessToken
 */
export async function handleOnNext(
  unifiedTaxCredits: UnifiedTaxCreditsStore,
  surveyFlow: SurveyFlowStore,
  taxYear: number,
  onNext: () => void,
  onNextOverride: () => void,
  hasAccessToken?: boolean,
): Promise<void> {
  if (unifiedTaxCredits.surveyAccumulated !== null) {
    surveyFlow.qualificationStatus = null;

    if (!hasAccessToken) {
      await unifiedTaxCredits.setShowAccumulatedModal(
        unifiedTaxCredits.isAllAssessmentReadyForReview(taxYear)
          ? onNextOverride
          : onNext,
      );
    }
  } else {
    !hasAccessToken && onNext();
  }
}

export const buildCommonHandlers = (
  surveyFlow: SurveyFlowStore,
  unifiedTaxCredits: UnifiedTaxCreditsStore,
  companyStore: CompanyStore,
  auth: AuthStore,
  modules: ModuleStore,
  app: AppStore,
  client: ServerClient,
  taxYear: number,
  program: ProgramData | undefined,
  qualificationStatus: QualificationModalStatusEnum,
  attestationEventType: AttestationEventTypeEnum,
  hasConfirmedAnswers: boolean,
  saveYeaSurveyAttestation: boolean,
  onNext: () => void,
  saveAnswers: () => Promise<SaveAnswersResponse>,
) => {
  const { company, accessToken } = companyStore;
  const reviewPage = `/${Page.taxCredits}/${Page.assessment}/${taxYear}/${Page.unifiedAssessmentReview}`;

  async function disqualifyProgram() {
    if (!program) return;

    await surveyFlow.updateProgramStageStatus(
      program.name,
      taxYear,
      ProgramStageEnum.DISQUALIFIED,
      QualificationStatusEnum.DISQUALIFIED,
      ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT,
      accessToken,
    );
  }

  const handleSubmitModal = () =>
    unifiedTaxCredits.handleShowSubmitForReview(
      taxYear,
      company,
      () => modules.toggleShowUnifiedCreditPaymentModal(true),
      true,
    );

  const handleAttestation = async () => {
    if (saveYeaSurveyAttestation) {
      await SaveSurveyAttestation({
        hasConfirmedAnswers,
        eventType: attestationEventType,
        userEmail: auth.user?.email || company?.adminEmail,
        taxYear,
        companyId: company.id,
        client,
        accessToken,
      });
    }
  };

  const handleOnContinue = async () => {
    if (accessToken) {
      unifiedTaxCredits.setShowInviteeSuccessModal(true);
    }

    if (!program) return;

    surveyFlow.setSurveyContinueLoading(true);

    // Ensure we save the answers before continuing
    await saveAnswers();

    const hasDQd = surveyFlow.allQualificationStatuses.find(
      (status) => status.status === qualificationStatus,
    );

    if (hasDQd) {
      if (accessToken) {
        await disqualifyProgram();
        await unifiedTaxCredits.checkLatestEstimatesInSection(
          taxYear,
          program.name,
          accessToken,
        );

        if (!unifiedTaxCredits.isAllAssessmentReadyForReview(taxYear)) {
          surveyFlow.qualificationStatus = null;
        }
      } else {
        surveyFlow.setShowQualificationStatusModal(true);
      }
    } else {
      // check if current program exist
      // get estimate on continue
      await unifiedTaxCredits.getEstimatesForAssessment(
        program.name,
        program.id,
        accessToken,
      );

      await handleAttestation();

      await unifiedTaxCredits.checkLatestEstimatesInSection(
        taxYear,
        program.name,
        accessToken,
      );

      await handleOnNext(
        unifiedTaxCredits,
        surveyFlow,
        taxYear,
        onNext,
        () => reviewPage,
        !!accessToken,
      );
    }

    surveyFlow.setSurveyContinueLoading(false);
  };

  const handleOnDisqualified = async () => {
    if (!program) return;

    surveyFlow.setModalContinueIsLoading(true);

    await disqualifyProgram();

    await unifiedTaxCredits.checkLatestEstimatesInSection(
      taxYear,
      program.name,
      accessToken,
    );

    const hasAllAssessmentsInReview =
      unifiedTaxCredits.isAllAssessmentReadyForReview(taxYear);

    if (hasAllAssessmentsInReview) {
      app.history.push(reviewPage);
    } else {
      // clear qualification statuses
      surveyFlow.qualificationStatus = null;
      onNext();
    }

    surveyFlow.setShowQualificationStatusModal(false);
    surveyFlow.setModalContinueIsLoading(false);
  };

  return {
    handleOnContinue,
    handleOnDisqualified,
    disqualifyProgram,
  };
};

export const handleContinueText = (
  accessToken: CompanyAccessToken | undefined,
  allSurveysViewed: boolean,
  continueText: string,
): string => {
  if (accessToken) {
    return 'Finished';
  } else {
    if (allSurveysViewed) {
      return 'Review all assessments';
    } else {
      return continueText;
    }
  }
};
