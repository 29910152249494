import React, { useMemo } from 'react';
import { useCommonStores, useTaxCreditsStores } from 'stores/useStores';
import {
  Page,
  AssessmentSurveyStepsIndex,
  ProgramNameEnum,
  ProgramSubStageEnum,
  ProgramStageEnum,
} from 'lib/constants';
import {
  GeneralBusinessDetails,
  RDEmployeesStep,
  RDSuppliesAndServices,
  UnifiedDisabledAccessCredit,
  UnifiedRdCompanyDetails,
  UnifiedRetirementPlan,
  UnifiedSmallBusinessHealthcare,
} from '../assessments';
import {
  AssessmentProgressStatus,
  TaxCreditAssessmentProgramsHygraphId,
} from 'lib/interfaces';

interface AssessmentSurveyRoutesInterface {
  exact?: boolean;
  path?: string;
  component: React.ReactElement | JSX.Element;
}

const useAssessmentSurveyRoutes = (
  taxYear: number,
): {
  AssessmentSurveyRoutes: AssessmentSurveyRoutesInterface[];
} => {
  const { unifiedTaxCredits } = useTaxCreditsStores();
  const { app, companyStore } = useCommonStores();
  const assessmentSurveyRootPath = `/${Page.taxCredits}/${Page.assessment}/${taxYear}`;
  const getRdAssessment =
    unifiedTaxCredits.assessmentByYear[taxYear] &&
    unifiedTaxCredits.assessmentByYear[taxYear].find(
      (credit) =>
        credit.id ===
        TaxCreditAssessmentProgramsHygraphId[ProgramNameEnum.FED_RD_TAX],
    );
  const isRDDisqualified =
    getRdAssessment && getRdAssessment.status === AssessmentProgressStatus.DQ;

  const allSurveysViewed = useMemo(() => {
    return companyStore.company.programs
      .filter((program) => program.taxYear === taxYear)
      .every(
        (program) =>
          program.subStage ===
            ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED ||
          program.subStage ===
            ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT ||
          (program.subStage === null &&
            program.stage === ProgramStageEnum.DISQUALIFIED),
      );
  }, [companyStore.company.programs, taxYear]);

  return {
    AssessmentSurveyRoutes: [
      {
        exact: true,
        path: assessmentSurveyRootPath,
        component: (
          <GeneralBusinessDetails
            onNext={() => {
              if (allSurveysViewed) {
                return app.history.push(
                  `/${Page.taxCredits}/${Page.assessment}/${taxYear}/${Page.unifiedAssessmentReview}`,
                );
              } else {
                app.history.push(
                  `${assessmentSurveyRootPath}/${Page.retirementPlan}`,
                );
                unifiedTaxCredits.setFlowIndex(
                  AssessmentSurveyStepsIndex.RETIREMENT_PLAN,
                );
              }
            }}
            taxYear={taxYear}
          />
        ),
      },
      {
        path: `${assessmentSurveyRootPath}/${Page.retirementPlan}`,
        component: (
          <UnifiedRetirementPlan
            onNext={() => {
              if (allSurveysViewed) {
                return app.history.push(
                  `/${Page.taxCredits}/${Page.assessment}/${taxYear}/${Page.unifiedAssessmentReview}`,
                );
              } else {
                app.history.push(
                  `${assessmentSurveyRootPath}/${Page.healthcare}`,
                );
                unifiedTaxCredits.setFlowIndex(
                  AssessmentSurveyStepsIndex.SMALL_BUSINESS_HEALTHCARE,
                );
              }
            }}
            onBack={() => {
              app.history.push(assessmentSurveyRootPath);
              unifiedTaxCredits.setFlowIndex(
                AssessmentSurveyStepsIndex.BUSINESS_DETAILS,
              );
            }}
            taxYear={taxYear}
          />
        ),
      },
      {
        path: `${assessmentSurveyRootPath}/${Page.healthcare}`,
        component: (
          <UnifiedSmallBusinessHealthcare
            onNext={() => {
              if (allSurveysViewed) {
                return app.history.push(
                  `/${Page.taxCredits}/${Page.assessment}/${taxYear}/${Page.unifiedAssessmentReview}`,
                );
              } else {
                app.history.push(
                  `${assessmentSurveyRootPath}/${Page.expenseClassificationCompanyDetails}`,
                );
                unifiedTaxCredits.setFlowIndex(
                  AssessmentSurveyStepsIndex.RD_COMPANY_DETAILS,
                );
              }
            }}
            onBack={() => {
              app.history.push(
                `${assessmentSurveyRootPath}/${Page.retirementPlan}`,
              );
              unifiedTaxCredits.setFlowIndex(
                AssessmentSurveyStepsIndex.RETIREMENT_PLAN,
              );
            }}
            taxYear={taxYear}
          />
        ),
      },
      {
        path: `${assessmentSurveyRootPath}/${Page.expenseClassificationCompanyDetails}`,
        component: (
          <UnifiedRdCompanyDetails
            onNext={(skipRD?: boolean) => {
              app.history.push(
                `${assessmentSurveyRootPath}/${
                  skipRD
                    ? Page.disabledAccess
                    : Page.expenseClassificationSuppliesServices
                }`,
              );
              unifiedTaxCredits.setFlowIndex(
                skipRD
                  ? AssessmentSurveyStepsIndex.DISABLED_ACCESS
                  : AssessmentSurveyStepsIndex.RD_SUPPLIES_SERVICES,
              );
            }}
            onBack={() => {
              app.history.push(
                `${assessmentSurveyRootPath}/${Page.healthcare}`,
              );
              unifiedTaxCredits.setFlowIndex(
                AssessmentSurveyStepsIndex.SMALL_BUSINESS_HEALTHCARE,
              );
            }}
            taxYear={taxYear}
            onError={() => null}
          />
        ),
      },
      {
        path: `${assessmentSurveyRootPath}/${Page.expenseClassificationSuppliesServices}`,
        component: (
          <RDSuppliesAndServices
            onNext={(skipRD?: boolean) => {
              app.history.push(
                `${assessmentSurveyRootPath}/${
                  skipRD
                    ? Page.disabledAccess
                    : Page.expenseClassificationEmployees
                }`,
              );
              unifiedTaxCredits.setFlowIndex(
                skipRD
                  ? AssessmentSurveyStepsIndex.DISABLED_ACCESS
                  : AssessmentSurveyStepsIndex.RD_EMPLOYEES,
              );
            }}
            onBack={() => {
              app.history.push(
                `${assessmentSurveyRootPath}/${Page.expenseClassificationCompanyDetails}`,
              );
              unifiedTaxCredits.setFlowIndex(
                AssessmentSurveyStepsIndex.RD_COMPANY_DETAILS,
              );
            }}
            taxYear={taxYear}
          />
        ),
      },
      {
        path: `${assessmentSurveyRootPath}/${Page.expenseClassificationEmployees}`,
        component: (
          <RDEmployeesStep
            onNext={() => {
              if (allSurveysViewed) {
                return app.history.push(
                  `/${Page.taxCredits}/${Page.assessment}/${taxYear}/${Page.unifiedAssessmentReview}`,
                );
              } else {
                app.history.push(
                  `${assessmentSurveyRootPath}/${Page.disabledAccess}`,
                );
                unifiedTaxCredits.setFlowIndex(
                  AssessmentSurveyStepsIndex.DISABLED_ACCESS,
                );
              }
            }}
            onBack={() => {
              app.history.push(
                `${assessmentSurveyRootPath}/${Page.expenseClassificationSuppliesServices}`,
              );
              unifiedTaxCredits.setFlowIndex(
                AssessmentSurveyStepsIndex.RD_SUPPLIES_SERVICES,
              );
            }}
            taxYear={taxYear}
            onError={() => null}
            hasPayrollGap={false}
            possiblePayrollMigration={false}
          />
        ),
      },
      {
        path: `${assessmentSurveyRootPath}/${Page.disabledAccess}`,
        component: (
          <UnifiedDisabledAccessCredit
            onNext={() => {
              app.history.push(
                `${assessmentSurveyRootPath}/${Page.unifiedAssessmentReview}`,
              );
            }}
            onBack={() => {
              if (isRDDisqualified) {
                app.history.push(
                  `${assessmentSurveyRootPath}/${Page.expenseClassificationCompanyDetails}`,
                );
                unifiedTaxCredits.setFlowIndex(
                  AssessmentSurveyStepsIndex.RD_COMPANY_DETAILS,
                );
              } else {
                app.history.push(
                  `${assessmentSurveyRootPath}/${Page.expenseClassificationEmployees}`,
                );
                unifiedTaxCredits.setFlowIndex(
                  AssessmentSurveyStepsIndex.RD_EMPLOYEES,
                );
              }
            }}
            taxYear={taxYear}
          />
        ),
      },
    ],
  };
};

export default useAssessmentSurveyRoutes;
