import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import { Color, Flex, Heading, Image, Text } from 'component-library';
import { useCommonStores } from 'stores/useStores';
import { PartnerInfo } from 'lib/interfaces';

const useStyles = makeStyles(() => ({
  howItWorks: {
    width: 'calc(100% - 48px)',
    maxWidth: '1024px',
    margin: '0 auto',
    padding: 'clamp(40px, 50vw, 80px) 24px',
    textAlign: 'center',
    alignItems: 'center',
  },
  howItWorksIntro: {
    maxWidth: 680,
  },
}));

interface HowItWorksSectionProps {
  qbContent?: JSX.Element;
  referralInfo?: PartnerInfo;
}

export const HowItWorksSection: React.FC<HowItWorksSectionProps> = observer(
  ({ qbContent, referralInfo }) => {
    const classes = useStyles();
    const { app } = useCommonStores();
    const isGuideline = referralInfo?.partnerReferralName === 'guideline';

    return (
      <Flex
        direction='column'
        className={classes.howItWorks}
        gap={app.isTablet ? 24 : 40}
      >
        <Flex direction='column' className={classes.howItWorksIntro}>
          <Heading color={Color.green._70} size={app.isTablet ? 15 : 18}>
            HOW IT WORKS
          </Heading>
          <Heading size={app.isTablet ? 32 : 48}>
            In partnership with your CPA, we maximize your tax&nbsp;credits.
          </Heading>
        </Flex>
        <Flex
          gap={app.isTablet ? 32 : 40}
          direction={app.isTablet ? 'column' : 'row'}
        >
          <Flex
            direction='column'
            gap={app.isTablet ? 12 : 24}
            basis={'33%'}
            justifyContent='flex-start'
          >
            <Flex basis={80} alignItems='center' justifyContent='center'>
              <Image
                src={`${process.env.PUBLIC_URL}/images/logo/partners/confirm-credit-amount-icon.svg`}
                width={72}
              />
            </Flex>
            <Heading size={app.isTablet ? 24 : 32} marginBottom={0}>
              Confirm your final credit&nbsp;amount
            </Heading>
            {qbContent ? (
              qbContent
            ) : (
              <Text>
                Just connect your payroll data and confirm a few&nbsp;questions.
              </Text>
            )}
          </Flex>
          <Flex
            direction='column'
            gap={app.isTablet ? 12 : 24}
            basis={'33%'}
            justifyContent='flex-start'
          >
            <Flex basis={80} alignItems='center' justifyContent='center'>
              <Image
                src={`${process.env.PUBLIC_URL}/images/logo/partners/easy-handoff-cpa-icon.svg`}
                width={72}
              />
            </Flex>
            <Heading size={app.isTablet ? 24 : 32} marginBottom={0}>
              Easy handoff to your&nbsp;CPA
            </Heading>
            <Text>
              We provide forms for all credits you might be qualified for. Send
              them to your CPA to be included in your income&nbsp;taxes.
            </Text>
          </Flex>
          <Flex
            direction='column'
            gap={app.isTablet ? 12 : 24}
            basis={'33%'}
            justifyContent='flex-start'
          >
            <Flex basis={80} alignItems='center' justifyContent='center'>
              <Image
                src={`${process.env.PUBLIC_URL}/images/logo/partners/start-saving-icon.svg`}
                width={72}
              />
            </Flex>
            <Heading size={app.isTablet ? 24 : 32} marginBottom={0}>
              Successfully claim your&nbsp;credits
            </Heading>
            <Text>
              Depending on the credit, you&apos;ll either offset payroll or
              income taxes directly. We only charge{' '}
              {referralInfo ? referralInfo.feePercentage : 20}% of the final
              credit on your&nbsp;forms.{' '}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  },
);
