import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Alert,
  Button,
  Callout,
  Card,
  Color,
  Divider,
  Expandable,
  Flex,
  Icon,
  IconEnum,
  Link,
  SideDrawer,
  Spinner,
  Text,
} from 'component-library';
import { ActivityTimelineSideDrawer } from './ActivityTimelineSideDrawer';
import {
  useCommonStores,
  useDashboardStores,
  useTaxCreditsStores,
} from 'stores/useStores';
import { AssessmentRow } from './AssessmentRow';
import {
  Page,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
} from 'lib/constants';
import { useEffectOnce } from '../../../../../lib/helpers';

const useStyles = makeStyles(() => ({
  title: {
    whiteSpace: 'nowrap',
  },
  timelineBtn: {
    paddingLeft: `0 !important`,
    paddingRight: `0 !important`,
  },
  assessmentContainer: {
    backgroundColor: Color.neutral._10,
    border: `1px solid ${Color.neutral._20}`,
    borderRadius: '4px',
  },
  list: {
    margin: 0,
    padding: '0 24px',
  },
  paymentButton: {
    width: '100%',
  },
  showAssessmentsBtn: {
    height: 'auto',
    padding: '8px 0 0',
  },
  showAssessmentsIcon: {
    transform: 'rotate(0deg)',
    transition: 'transform .2s',
  },
  assessmentsIconClose: {
    transform: 'rotate(-180deg)',
  },
}));

interface AssessmentInProgressProps {
  redirectPath: string;
  taxYear: number;
}

export const AssessmentInProgress: React.FC<AssessmentInProgressProps> =
  observer(({ redirectPath, taxYear }) => {
    const classes = useStyles();
    const { companyStore } = useCommonStores();
    const { modules } = useDashboardStores();
    const { unifiedTaxCredits } = useTaxCreditsStores();
    const hasNotStartedSurvey = !!useMemo(() => {
      return companyStore.company.programs.find(
        (program) =>
          program.name === ProgramNameEnum.FED_RETIREMENT_CREDIT &&
          program.taxYear === taxYear &&
          program.stage === ProgramStageEnum.QUALIFYING,
      );
    }, [companyStore.company.programs, taxYear]);
    const allSurveysCompleted = useMemo(() => {
      return companyStore.company.programs
        .filter((program) => program.taxYear === taxYear)
        .every(
          (program) =>
            program.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT ||
            (program.subStage === null &&
              program.stage === ProgramStageEnum.DISQUALIFIED),
        );
    }, [companyStore.company.programs, taxYear]);
    const hasSkippedSurveys = useMemo(() => {
      return companyStore.company.programs
        .filter((program) => program.taxYear === taxYear)
        .every(
          (program) =>
            program.subStage ===
            ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED,
        );
    }, [companyStore.company.programs, taxYear]);

    let assessmentButtonLabel: string;
    switch (true) {
      case hasNotStartedSurvey:
        assessmentButtonLabel = 'Start assessment';
        break;
      case allSurveysCompleted:
        assessmentButtonLabel = 'Finalize assessment';
        break;
      case hasSkippedSurveys:
        assessmentButtonLabel = 'Complete assessment';
        break;
      default:
        assessmentButtonLabel = 'Continue assessment';
        break;
    }

    const [showAssessments, setShowAssessments] = useState<boolean>(false);

    useEffectOnce(() => {
      // First check if the user has already submitted for review then evaluate whether to show the payment or review modal
      if (!companyStore.company.misc?.submittedTaxYears?.includes(taxYear)) {
        unifiedTaxCredits.handleShowSubmitForReview(
          taxYear,
          companyStore.company,
          () => modules.toggleShowUnifiedCreditPaymentModal(true),
          false,
        );
      }
    });

    return (
      <>
        {Object.keys(unifiedTaxCredits.assessmentByYear).map((taxYear) => {
          return (
            <Card noMargin key={`${taxYear}-assessments`}>
              <Flex direction='column' gap={16} padding={24}>
                <Flex
                  justifyContent='space-between'
                  alignItems='center'
                  wrap='wrap'
                >
                  <Flex
                    gap={4}
                    alignItems='center'
                    justifyContent='flex-start'
                    className={classes.title}
                    basis={315}
                  >
                    <Text variant='medium' size={23}>
                      {taxYear} Tax Year Assessment
                    </Text>
                    <Icon
                      name={IconEnum.question_circle}
                      onClick={() =>
                        unifiedTaxCredits.setShowInProgressSideDrawer(true)
                      }
                    />
                  </Flex>
                  <Button
                    className={classes.timelineBtn}
                    label={
                      <Flex gap={4}>
                        <Icon
                          name={IconEnum.clock_circle}
                          color={Color.blue._60}
                        />{' '}
                        <Text color={Color.blue._60}>
                          View activity timeline
                        </Text>
                      </Flex>
                    }
                    flexAlignSelf='flex-end'
                    onClick={() =>
                      unifiedTaxCredits.setShowTimelineSideDrawer(true)
                    }
                    variant='tertiary'
                    small
                  />
                </Flex>
                <Text>
                  Your journey to optimal tax benefits starts here. Complete the
                  full assessment to unlock every credit your business deserves.
                  Once complete, we will review and generate the paperwork. All
                  you will have to do is send the docs to your tax preparer.
                  MainStreet is with you every step of the way.
                </Text>
                <Button
                  onClick={{
                    location: allSurveysCompleted
                      ? `/${Page.taxCredits}/${Page.assessment}/${taxYear}/${Page.unifiedAssessmentReview}`
                      : redirectPath,
                    target: '_self',
                  }}
                  label={assessmentButtonLabel}
                  flexAlignSelf='flex-start'
                />
                <Button
                  className={classes.showAssessmentsBtn}
                  variant='tertiary'
                  onClick={() => setShowAssessments(!showAssessments)}
                  label={
                    <>
                      {showAssessments ? 'Hide' : 'Show'} assessment sections{' '}
                      <Icon
                        className={`${classes.showAssessmentsIcon} ${
                          showAssessments ? classes.assessmentsIconClose : ''
                        }`}
                        name={IconEnum.chevron_down}
                        color={Color.blue._60}
                      />
                    </>
                  }
                  flexAlignSelf='flex-start'
                />
                <Expandable expand={showAssessments}>
                  <>
                    <div className={classes.assessmentContainer}>
                      {unifiedTaxCredits.assessmentByYear[taxYear] &&
                      unifiedTaxCredits.assessmentByYear[taxYear].length > 0 ? (
                        unifiedTaxCredits.assessmentByYear[taxYear].map(
                          (credit, index) => {
                            return (
                              <AssessmentRow
                                key={`${credit.id}-${index}`}
                                index={index}
                                credit={credit}
                                taxYear={taxYear}
                              />
                            );
                          },
                        )
                      ) : (
                        <Flex
                          alignItems='center'
                          justifyContent='center'
                          padding={16}
                        >
                          <Spinner size='small' />
                        </Flex>
                      )}
                    </div>
                    <Alert
                      backgroundColor={Color.blue._10}
                      text={
                        <Flex direction='column'>
                          <Text size={15}>
                            If you do not wish to claim any credit from the list
                            above, please reach out to{' '}
                            <Link
                              href='mailto:support@mainstreet.com'
                              target='_blank'
                            >
                              support@mainstreet.com
                            </Link>
                          </Text>
                        </Flex>
                      }
                      type='info'
                      variant='in_card'
                    />
                  </>
                </Expandable>
              </Flex>

              <ActivityTimelineSideDrawer taxYear={taxYear} stepIndex={2} />
              <SideDrawer
                show={unifiedTaxCredits.showInProgressTitleSideDrawer}
                title='Tax Year Assessment'
                closeToggle={() =>
                  unifiedTaxCredits.setShowInProgressSideDrawer(false)
                }
                drawerContent={
                  <Flex direction='column' gap={16}>
                    <Text variant='medium'>What&apos;s this step?</Text>
                    <Divider variant='no-bottom-margin' />
                    <Text>
                      Complete your assessment to give us specifics about your
                      business activities and expenses from the last 12 months.
                      This allows us to refine our estimate, accurately
                      calculate your final credit amount, and generate the
                      documents needed to file with the IRS.
                    </Text>
                    <Callout
                      color={Color.blue._60}
                      title={`It's essential that you complete this step ASAP in order to claim your credit. MainStreet will not file your forms on your behalf. Please work with your tax preparer to file your forms.`}
                    />
                    <Text variant='medium'>How long will it take?</Text>
                    <Divider variant='no-bottom-margin' />
                    <ul className={classes.list}>
                      <li>
                        <Text paddingBottom={8}>
                          This process should take less than 1 hour to complete.
                        </Text>
                      </li>
                      <li>
                        <Text>
                          After you submit, you&apos;ll hear from us in just a
                          few business days. We&apos;ll review all the
                          information and generate the necessary documents
                          you&apos;ll need to file with the IRS. This process
                          typically takes between 2 and 4 weeks.
                        </Text>
                      </li>
                    </ul>
                  </Flex>
                }
              />
            </Card>
          );
        })}
      </>
    );
  });
