import { ExpandableSurveyQuestion, PrependNonSurveyElement } from '../index';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { FeatureFlag, FlagOption } from '@mainstreet/feature-flags-react';
import RdVendorsAndExpenses from './RdVendorsAndExpenses';
import {
  CmsQuestionData,
  ProgramData,
  QualificationQuestions,
} from 'lib/interfaces';
import { SaveAnswersResponse, SurveyAnswer } from 'lib/useSurveyQuestions';
import {
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
  RdVendorExpenseType,
  SubGroupNameEnum,
} from 'lib/constants';
import {
  FeatureFlagNameEnum,
  FeatureFlagValueEnum,
} from 'lib/constants/featureFlagConstants';
import { Alert, Color, Flex, Grid, Text } from 'component-library';
import { getWarningTextBySubGroup } from '../../../../../lib/helpers';
import { ChatBotStore } from '../../../../../stores/common/ChatBotStore';

const useStyles = makeStyles({
  question: {
    paddingTop: 12,
    paddingBottom: 12,
  },
});

interface RdExpensesSurveyQuestionProps {
  program: ProgramData;
  question: CmsQuestionData;
  subGroupName: string;
  addSurveyAnswers: (
    questionId: string,
    subgroupName: string,
    answers: SurveyAnswer[],
  ) => void;
  saveAnswers?: () => Promise<SaveAnswersResponse>;
  surveyAnswers: QualificationQuestions;
  chatbot?: ChatBotStore;
  cloudTableFinished?: boolean;
  setCloudTableFinished?: (finished: boolean) => void;
  suppliesTableFinished?: boolean;
  setSuppliesTableFinished?: (finished: boolean) => void;
  checkForExpensesUpdate?: () => void;
}

const RdExpensesSurveyQuestion = ({
  program,
  question,
  subGroupName,
  addSurveyAnswers,
  saveAnswers,
  surveyAnswers,
  chatbot,
  cloudTableFinished,
  setCloudTableFinished,
  suppliesTableFinished,
  setSuppliesTableFinished,
  checkForExpensesUpdate,
}: RdExpensesSurveyQuestionProps) => {
  const styles = useStyles();

  const hasRdCloudVendorExpenses =
    question.id === GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_CLOUD &&
    question.answerValue ===
      GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_CLOUD]
        .YES;

  const handleCloudExpensesFinished = (finished: boolean) => {
    if (setCloudTableFinished) {
      setCloudTableFinished(finished);
    }

    if (checkForExpensesUpdate) {
      checkForExpensesUpdate();
    }
  };

  const hasRdSuppliesVendorExpenses =
    question.id === GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_SUPPLIES &&
    question.answerValue ===
      GraphCmsQuestionIdToAnswers[
        GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_SUPPLIES
      ].YES;

  const handleSuppliesExpensesFinished = (finished: boolean) => {
    if (setSuppliesTableFinished) {
      setSuppliesTableFinished(finished);
    }
    if (checkForExpensesUpdate) {
      checkForExpensesUpdate();
    }
  };

  const featureFlagFallbackComponent = (
    <ExpandableSurveyQuestion
      questionProps={question}
      onQuestionPropsChange={(answers) =>
        addSurveyAnswers(question.id, subGroupName, answers)
      }
      chatbot={chatbot}
    />
  );

  const renderRdVendorExpenses = (
    expenseType: RdVendorExpenseType,
    subGroupName: SubGroupNameEnum,
    show: boolean,
    setIsFinished: (boolean: boolean) => void,
  ) => {
    const warningText = getWarningTextBySubGroup(subGroupName);
    return (
      <Grid columns={12} padding={[24, 0, 0, 0]}>
        <Grid.Cell columns={4} padding={[0, 24, 0, 0]}>
          {warningText && show && (
            <Alert
              text={
                <Flex direction='column' gap={12}>
                  <Text text={warningText.text} variant='medium' />
                  <Text
                    text={warningText.subText}
                    size={13}
                    color={Color.neutral._80}
                  />
                </Flex>
              }
              backgroundColor={Color.semantic.$warning10}
              inCardBorder='left'
              type='caution'
              variant={'in_card'}
            />
          )}
        </Grid.Cell>
        <Grid.Cell columns={8}>
          <RdVendorsAndExpenses
            expenseType={expenseType}
            program={program}
            onRdVendorExpensesFinished={() => setIsFinished(true)}
          />
        </Grid.Cell>
      </Grid>
    );
  };

  const renderExpandableSurveyQuestion = () => {
    return (
      <Grid columns={12}>
        <Grid.Cell columns={4}></Grid.Cell>
        <Grid.Cell columns={8}>
          <ExpandableSurveyQuestion
            questionProps={question}
            onQuestionPropsChange={(answers) => {
              addSurveyAnswers(question.id, subGroupName, answers);
            }}
            allowButtonDeselect={false}
            saveAnswers={saveAnswers}
            chatbot={chatbot}
          />
        </Grid.Cell>
      </Grid>
    );
  };

  if (
    (question.id === GraphCmsQuestionIdEnum.WHAT_PERCENT_CLOUD_RD &&
      !cloudTableFinished) ||
    (question.id === GraphCmsQuestionIdEnum.WHAT_PERCENT_SUPPLIES_RD &&
      !suppliesTableFinished)
  ) {
    return <></>;
  }

  return (
    <div className={styles.question}>
      <PrependNonSurveyElement question={question} columnPadding={24} />
      <FeatureFlag
        flagName={FeatureFlagNameEnum.EXPENSE_CLASSIFICATION_RD_VENDOR_EXPENSES}
        fallbackComponent={featureFlagFallbackComponent}
      >
        <FlagOption flagValue={FeatureFlagValueEnum.ON}>
          {renderExpandableSurveyQuestion()}
          {hasRdCloudVendorExpenses &&
            renderRdVendorExpenses(
              RdVendorExpenseType.CLOUD,
              SubGroupNameEnum.VENDOR_CLOUD_EXPENSES,
              true,
              handleCloudExpensesFinished,
            )}
          {hasRdSuppliesVendorExpenses &&
            renderRdVendorExpenses(
              RdVendorExpenseType.SERVICES_SUPPLY,
              SubGroupNameEnum.VENDOR_SUPPLY_EXPENSES,
              true,
              handleSuppliesExpensesFinished,
            )}
        </FlagOption>
        <FlagOption flagValue={FeatureFlagValueEnum.OFF}>
          {featureFlagFallbackComponent}
        </FlagOption>
      </FeatureFlag>
    </div>
  );
};

export default RdExpensesSurveyQuestion;
