import { observer } from 'mobx-react';
import {
  Alert,
  Button,
  Color,
  Content,
  Dropdown,
  DropdownOption,
  Flex,
  FontAwesome,
  IconEnum,
  Text,
  TextField,
} from 'component-library';
import React from 'react';
import {
  RdProjectCategoryEnum,
  RdProjectEligibleInternalSoftwareEnum,
  RdProjectSoftwarePurposeEnum,
} from 'lib/constants';
import { useLegacyClients, useTaxCreditsStores } from 'stores/useStores';
import { useEffectOnce } from 'component-library/_helpers/use-utils';

const categoryOptions: DropdownOption[] = [
  { name: 'Computer software', value: RdProjectCategoryEnum.COMPUTER_SOFTWARE },
  { name: 'Physical product', value: RdProjectCategoryEnum.PHYSICAL_PRODUCT },
  { name: 'Process', value: RdProjectCategoryEnum.PROCESS },
];

export const BusinessComponentSideDrawerContent = observer(() => {
  const { businessComponents } = useTaxCreditsStores();
  const { client } = useLegacyClients();

  const selectedProject = businessComponents.selectedProjectData;

  useEffectOnce(async () => {
    const response = await client.GetRDProjectSoftware();
    if (response?.data?.rdProjectSoftware) {
      businessComponents.setRdProjectSoftware(response.data.rdProjectSoftware);
    }
  });

  return (
    <>
      <TextField
        label='Name'
        dataTestId={'rd-project-side-drawer-name'}
        onChange={(event: any) =>
          businessComponents.setSelectedProjectName(event.target.value)
        }
        type='text'
        value={selectedProject.name}
        helperText={`Enter a descriptive name (e.g. 'New customer dashboard'), which may be included on IRS forms.`}
      />
      <Content paddingLeftRight={0} paddingTopBottom={24}>
        <Dropdown
          label='Category'
          dataTestId={'rd-project-side-drawer-description'}
          placeholder='Choose from options'
          value={
            categoryOptions.find((o) => o.value === selectedProject?.category)
              ?.name
          }
          options={categoryOptions}
          onInputChange={(option: any) =>
            businessComponents.setSelectedProjectCategory(option.value)
          }
        />
      </Content>
      {selectedProject.category === RdProjectCategoryEnum.COMPUTER_SOFTWARE && (
        <Content paddingLeftRight={0} paddingBottom={24}>
          <Dropdown
            label='For what purpose did you primarily develop this software?'
            dataTestId={'rd-project-software-options'}
            placeholder='Choose from options'
            value={selectedProject.rdProjectSoftware?.purpose}
            options={businessComponents.rdProjectSoftware.map((software) => {
              return { name: software.purpose, value: software.id.toString() };
            })}
            onInputChange={(option: any) => {
              const rdProjectSoftwareId = parseInt(option.value, 10);
              const rdProjectSoftware =
                businessComponents.rdProjectSoftware.find(
                  (software) => software.purpose === option.name,
                );
              businessComponents.setSelectedProjectSoftwareId(
                rdProjectSoftwareId,
              );
              if (rdProjectSoftware)
                businessComponents.setSelectedProjectSoftware(
                  rdProjectSoftware,
                );
            }}
          />
        </Content>
      )}

      {/* If "Other" is selected */}
      {selectedProject.rdProjectSoftware?.purpose ===
        RdProjectSoftwarePurposeEnum.OTHER && (
        <Content paddingBottom={24}>
          <Alert
            text={
              'Software business components must be for one of the specified purposes. Adjust your answer or add a different project to proceed.'
            }
            backgroundColor={Color.semantic.$error10}
            icon={IconEnum.exclamation_triangle}
            iconColor={Color.semantic.$error50}
          />
        </Content>
      )}

      {/* If "Two purposes..." is selected */}
      {selectedProject.rdProjectSoftware?.purpose ===
        RdProjectSoftwarePurposeEnum.TO_ALLOW_CUSTOMERS_TO_INTERACT_WITH_AND_EMPLOYEES_TO_FACILITATE_PRIMARY_PRODUCT_OR_SERVICE && (
        <Content paddingBottom={24}>
          <Alert
            text={
              'Please identify the customer-facing and internal pieces of this software as separate business components.'
            }
            backgroundColor={Color.semantic.$error10}
            icon={IconEnum.exclamation_triangle}
            iconColor={Color.semantic.$error50}
          />
        </Content>
      )}

      {/* If "To facilitate internal work..." is selected */}
      {selectedProject.rdProjectSoftware?.purpose ===
        RdProjectSoftwarePurposeEnum.TO_FACILITATE_INTERNAL_ADMINISTRATIVE_WORK && (
        <Content paddingBottom={24}>
          <Text>
            In order to be eligible for the R&D credit, internal-use tools must
            meet additional criteria. Do ALL of these statements apply to your
            company?
          </Text>
          <ul style={{ padding: '0', marginLeft: '15px' }}>
            <li>
              <Text>
                We developed this tool from scratch, or wrote significant custom
                code to adapt a third-party tool to our use case.
              </Text>
            </li>
            <li>
              <Text>
                No other tool was commercially available, that could have
                achieved the same impact to our business without significant
                alterations.
              </Text>
            </li>
            <li>
              <Text>
                This tool produced substantial, measurable, and economically
                significant improvements to our internal functions.
              </Text>
            </li>
          </ul>
          <Flex gap={8}>
            <Button
              label={'Yes'}
              variant={'outlined'}
              selected={
                selectedProject.eligibleInternalSoftware ===
                RdProjectEligibleInternalSoftwareEnum.YES
              }
              onClick={() => {
                businessComponents.setSelectedProjectEligibleInternalSoftware(
                  RdProjectEligibleInternalSoftwareEnum.YES,
                );
              }}
            />
            <Button
              label={'No, not all apply'}
              variant={'outlined'}
              selected={
                selectedProject.eligibleInternalSoftware ===
                RdProjectEligibleInternalSoftwareEnum.NO
              }
              onClick={() => {
                businessComponents.setSelectedProjectEligibleInternalSoftware(
                  RdProjectEligibleInternalSoftwareEnum.NO,
                );
              }}
            />
          </Flex>
        </Content>
      )}

      {/* If the user selected a software purpose of facilitating internal work AND the software is not eligible */}
      {selectedProject.eligibleInternalSoftware ===
        RdProjectEligibleInternalSoftwareEnum.NO && (
        <Content paddingBottom={24}>
          <Alert
            text={
              'Internal-use software business components must meet all criteria. Adjust your answer or add a different project to proceed.'
            }
            backgroundColor={Color.semantic.$error10}
            icon={IconEnum.exclamation_triangle}
            iconColor={Color.semantic.$error50}
          />
        </Content>
      )}

      <div
        onClick={() => businessComponents.setFourPartTestModalOpen(true)}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          paddingBottom: '40px',
          cursor: 'pointer',
        }}
      >
        <FontAwesome name={'file-lines'} size={15} color={Color.blue._60} />
        <Text text={'Review four-part test'} size={15} color={Color.blue._60} />
      </div>
    </>
  );
});
