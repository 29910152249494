import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Color,
  Flex,
  FontAwesome,
  Heading,
  Image,
  Text,
} from 'component-library';
import { useCommonStores, useFeatureFlags } from 'stores/useStores';
import { PartnerInfo } from 'lib/interfaces';

const useStyles = makeStyles(({ breakpoints }) => ({
  partnershipInfoSection: {
    maxWidth: '1440px',
    [breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
    '& h3': {
      textAlign: 'left',
      [breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
  },
  partnerLogoInline: {
    verticalAlign: 'middle',
    padding: '0 .4em',
    maxHeight: '40px',
  },
  /// Guideline
  guidelineContainer: {
    maxWidth: '1024px',
    margin: '0 auto',
  },
  guidelineInner: {
    borderRadius: '10px',
    overflow: 'hidden',
  },
  retirementContent: {
    backgroundColor: Color.green._80,
  },
  backgroundGradient: {
    background: `url(${process.env.PUBLIC_URL}/images/logo/partners/side-gradient.png)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: 'cover',
  },
  partnerLogo: {
    width: '100%',
    maxHeight: '46px',
    padding: '0 8px 0 0',
    [breakpoints.down('xs')]: {
      maxWidth: '50vw',
    },
  },
  partnerLogoLockup: {
    width: '100%',
    [breakpoints.down('xs')]: {
      maxWidth: '100%',
    },
  },
  mstLogo: {
    width: '95%',
    [breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  centerTextMobile: {
    [breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
}));

interface PartnershipSectionProps {
  referralInfo?: PartnerInfo;
  qbPartnershipInfo?: JSX.Element;
}

export const PartnershipSection: React.FC<PartnershipSectionProps> = observer(
  ({ referralInfo, qbPartnershipInfo }) => {
    const classes = useStyles();
    const { app } = useCommonStores();
    const isGuideline = referralInfo?.partnerReferralName === 'guideline';
    const featureFlags = useFeatureFlags();
    /// Processing Tax Year Feature Flag value
    const PROCESSING_TAX_YEAR = featureFlags.currentProcessingTaxYear;

    return (
      <>
        {isGuideline ? (
          <Flex
            className={classes.guidelineContainer}
            padding={app.isMobile ? [40, 24] : [80, 24]}
          >
            <Flex
              className={classes.guidelineInner}
              direction={app.isMobile ? 'column' : 'row'}
            >
              <Flex
                direction='column'
                className={classes.retirementContent}
                padding={app.isMobile ? 32 : 40}
                basis={'70%'}
                gap={12}
              >
                <Text color={Color.neutral.white} variant='medium'>
                  • FREE&nbsp;CREDIT
                </Text>
                <Heading color={Color.lime._60} size={app.isMobile ? 32 : 40}>
                  Unlock Free 401(k) Tax Credits!
                </Heading>
                <Flex gap={16} padding={[0, 0, 8, 0]} alignItems='flex-start'>
                  <Flex.Cell style={{ paddingTop: '3px' }}>
                    <FontAwesome
                      name='badge-check'
                      variant='regular'
                      color={Color.lime._60}
                    />
                  </Flex.Cell>
                  <Flex.Cell>
                    <Text color={Color.neutral.white} size={18}>
                      Offset federal income taxes for up to five&nbsp;years
                    </Text>
                  </Flex.Cell>
                </Flex>
                <Flex gap={16} padding={[0, 0, 8, 0]} alignItems='flex-start'>
                  <Flex.Cell style={{ paddingTop: '3px' }}>
                    <FontAwesome
                      name='badge-check'
                      variant='regular'
                      color={Color.lime._60}
                    />
                  </Flex.Cell>
                  <Flex.Cell>
                    <Text color={Color.neutral.white} size={18}>
                      Save up to $16,500 over three&nbsp;years with your
                      401(k)&nbsp;plan&nbsp;setup
                    </Text>
                  </Flex.Cell>
                </Flex>
                <Flex gap={16} padding={[0, 0, 16, 0]} alignItems='flex-start'>
                  <Flex.Cell style={{ paddingTop: '3px' }}>
                    <FontAwesome
                      name='badge-check'
                      variant='regular'
                      color={Color.lime._60}
                    />
                  </Flex.Cell>
                  <Flex.Cell>
                    <Text color={Color.neutral.white} size={18}>
                      Earn up to $1,000 per employee yearly for up to
                      five&nbsp;years with
                      employer&nbsp;401(k)&nbsp;contributions
                    </Text>
                  </Flex.Cell>
                </Flex>
              </Flex>
              <Flex.Cell
                basis={app.isMobile ? '120px' : '30%'}
                className={classes.backgroundGradient}
              >
                <></>
              </Flex.Cell>
            </Flex>
          </Flex>
        ) : (
          <Flex justifyContent='center' padding={app.isMobile ? 16 : [80, 24]}>
            <Flex
              direction={app.isMobile ? 'column' : 'row'}
              alignItems='center'
              gap={!app.isMobile ? 16 : 0}
              className={classes.partnershipInfoSection}
            >
              {qbPartnershipInfo ? (
                <>{qbPartnershipInfo}</>
              ) : referralInfo ? (
                <>
                  <Flex
                    alignItems='center'
                    className={classes.partnerLogoLockup}
                    padding={app.isMobile ? [24, 0, 24, 0] : [0, 0, 32, 0]}
                    gap={8}
                    justifyContent={'center'}
                  >
                    <Flex.Cell>
                      <Image
                        src={referralInfo.partnerLogo.url}
                        className={classes.partnerLogo}
                      />
                    </Flex.Cell>
                    <Flex.Cell>
                      <Image
                        className={classes.mstLogo}
                        src={`${process.env.PUBLIC_URL}/images/logo/mainstreet-x-logo.svg`}
                      />
                    </Flex.Cell>
                  </Flex>
                  <Flex.Cell className={classes.centerTextMobile}>
                    <Heading
                      tag={app.isMobile ? 'h4' : 'h3'}
                      size={app.isMobile ? 32 : undefined}
                    >
                      <Heading tag='span'>
                        MainStreet has teamed up with
                      </Heading>
                      <Heading
                        tag='span'
                        color={Color.semantic.$success50}
                        text={` ${referralInfo.partnerName} `}
                      />
                      <Heading tag='span'>
                        to potentially save your business hundreds of thousands
                        of dollars on your {PROCESSING_TAX_YEAR}&nbsp;taxes.
                      </Heading>
                    </Heading>
                  </Flex.Cell>
                </>
              ) : (
                <></>
              )}
            </Flex>
          </Flex>
        )}
      </>
    );
  },
);
