import React, { useContext, useState } from 'react';
import { observer } from 'mobx-react';
import {
  Button,
  Color,
  Divider,
  FileHandle,
  FileUpload,
  Flex,
  Modal,
  Text,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { FileTypes } from 'lib/constants';
import { PayrollSystem, Document, ImportType } from 'lib/interfaces';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import { CompanyContext } from 'pages/CompanyRequired';
import {
  isFileEncrypted,
  isFileSizeWithinLimits,
  useEffectOnce,
} from 'lib/helpers';
import AttentionNeeded from 'components/icons/AttentionNeeded';
import { OnPayUploadInstructions } from './payroll-provider-instructions/OnPayUploadInstructions';
import { DefaultUploadInstructions } from './payroll-provider-instructions/DefaultUploadInstructions';
import { RipplingUploadInstructions } from './payroll-provider-instructions/RipplingUploadInstructions';

const useStyles = makeStyles(() => ({
  documentUploadInfo: {
    '& ol': {
      margin: 0,
      padding: '0 0 0 16px',
      '& > li': {
        padding: '4px 0',
      },
    },
  },
  uploadedDocs: {
    border: `1px solid ${Color.neutral._20}`,

    '&:nth-child(2)': {
      borderTop: 'none',
    },
  },
}));

interface PayrollFileUploaderProps {
  payrollYear: number;
  submitManualPayroll: () => void;
  payrollSystem: PayrollSystem | null;
  setDoThisLaterModal: (value: React.SetStateAction<boolean>) => void;
  storeDropdownSelection?: boolean;
  showDoThisLaterModal?: boolean;
  moveToNextStep?: (option?: PayrollSystem) => Promise<void>;
  skipThisStep: boolean;
  importType: ImportType;
  partner?: string;
  taxYear?: number;
}

interface EncryptedFileProps {
  questionId: string | null;
  fileHandle: FileHandle | null;
}

export const PayrollFileUploader: React.FC<PayrollFileUploaderProps> = observer(
  ({
    payrollYear,
    submitManualPayroll,
    payrollSystem,
    storeDropdownSelection,
    showDoThisLaterModal,
    moveToNextStep,
    setDoThisLaterModal,
    skipThisStep,
    importType,
    partner,
    taxYear,
  }) => {
    const classes = useStyles();
    const { client } = useContext(Auth0FeatureContext);
    const { company } = useContext(CompanyContext);
    const [files, setFiles] = useState<
      Record<string, (FileHandle | Document)[]>
    >({});
    const [uploadedDocuments, setUploadedDocuments] = useState<
      Record<string, Document[]>
    >({});
    const [showEncryptedModal, setShowEncryptedModal] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState<{
      [id: number]: boolean;
    }>({});
    const [currentEncryptedFile, setCurrentEncryptedFile] =
      useState<EncryptedFileProps>({ questionId: null, fileHandle: null });

    const UPLOAD_FILE_SIZE_LIMIT = 1024 * 1024 * 20;
    const GENERIC_VISUAL_ERROR = `Something went wrong, please try again`;
    const payrollString = 'payroll-document';
    const docDescription = 'Payroll document';

    const getUploadedDocuments = async () => {
      const res = await client.GetCompanyDocuments({ source: 'client' });
      const documents = res.data?.documents;
      const uploadedDocs: { [key: string]: Document[] } = {};

      if (documents) {
        const filterPayrollDocs = documents.filter(
          (doc) => doc.description === docDescription,
        );

        if (filterPayrollDocs.length > 0) {
          documents.forEach((doc) => {
            if (uploadedDocs[payrollString]) {
              uploadedDocs[payrollString] = [
                ...uploadedDocs[payrollString],
                doc,
              ];
            } else {
              uploadedDocs[payrollString] = [doc];
            }
          });

          setUploadedDocuments(uploadedDocs);
          setFiles(uploadedDocs);
        }
      }
    };

    const instructions: { [key: string]: React.ReactElement } = {
      rippling: <RipplingUploadInstructions payrollYear={payrollYear} />,
      onpay: <OnPayUploadInstructions payrollYear={payrollYear} />,
      default: <DefaultUploadInstructions payrollYear={payrollYear} />,
    };

    const uploadPayrollDoc = async (fileHandler: FileHandle) => {
      try {
        const response = await client.UploadCompanyDocument(
          company.id,
          fileHandler,
          fileHandler.name,
          docDescription,
          undefined,
          partner || 'other',
          taxYear,
        );

        let hasUploaded = true;
        let documentId;

        // Extract document ID if available in response data
        if (response.data?.document) {
          documentId = response.data.document.id;
        }

        // Handle error message if present
        if (response.errorMsg) {
          fileHandler.setError(response.errorMsg);
          hasUploaded = false;
        }

        return { hasUploaded, documentId };
      } catch (error) {
        fileHandler.setError(GENERIC_VISUAL_ERROR);
        return { hasUploaded: false, documentId: undefined };
      }
    };

    const onFileAdded = async (
      questionId: string,
      fileHandle: FileHandle,
      bypassFileEncryptionValidation: boolean,
    ) => {
      let promise: Promise<void> = Promise.resolve();
      const fileSizeWithinLimits = isFileSizeWithinLimits(fileHandle.file);
      const fileEncrypted = await isFileEncrypted(fileHandle);

      if (!fileSizeWithinLimits) {
        return;
      }

      if (!bypassFileEncryptionValidation && fileEncrypted) {
        setCurrentEncryptedFile({ questionId, fileHandle });
        setShowEncryptedModal(true);
        return;
      } else {
        fileHandle.setError('');
      }

      promise = uploadPayrollDoc(fileHandle).then((success) => {
        // store db document id to fileHandle
        fileHandle.documentId = success.documentId;
      });

      promise
        .then((result) => {
          const questionFileList = files[questionId]
            ? [...files[questionId]]
            : [];
          const updatedFiles = {
            ...files,
            [questionId]: [...questionFileList, fileHandle],
          };
          setFiles(updatedFiles);
        })
        .catch((error) => {
          return error;
        });
    };

    const onFileRemoved = (questionId: string, file: FileHandle) => {
      const questionFileList = files[questionId] ? [...files[questionId]] : [];
      const fileIndex = questionFileList.findIndex(
        (f) => (f as FileHandle).vid === file.vid,
      );
      if (fileIndex >= 0) {
        questionFileList.splice(fileIndex, 1);
      }
      const updatedFiles = {
        ...files,
        [questionId]: questionFileList,
      };
      setFiles(updatedFiles);

      if (file.documentId) {
        const documentId = file.documentId;

        client.DeleteDocument(documentId);
      }
    };

    // this is to delete documents that were already uploaded
    // if customer had already previously visited the company details step
    const deleteDocument = async (
      docId: number,
      questionId: string,
      index: number,
    ) => {
      setDeleteLoading((prev) => ({
        ...prev,
        [index]: !prev[index],
      }));

      await client.DeleteDocument(docId).then(() => {
        const updateDocumentList = uploadedDocuments[questionId].filter(
          (doc) => doc.id !== docId,
        );

        const updatedDocList = {
          ...uploadedDocuments,
          [questionId]: [...updateDocumentList],
        };

        const updatedFiles = {
          ...files,
          [questionId]: updateDocumentList,
        };
        setFiles(updatedFiles);

        setUploadedDocuments(updatedDocList);
        setDeleteLoading((prev) => ({
          ...prev,
          [index]: !prev[index],
        }));
      });
    };

    // get uploaded documents to show customers
    useEffectOnce(async () => await getUploadedDocuments());

    return (
      <Flex direction='column' gap={24} padding={[24, 0, 0, 0]}>
        <Flex.Cell>
          <FileUpload
            acceptedFiletypes={[
              FileTypes.CSV,
              FileTypes.PDF,
              FileTypes.XLS,
              FileTypes.XLSX,
            ]}
            maxFileSize={UPLOAD_FILE_SIZE_LIMIT}
            onFileAdded={async (file: FileHandle) => {
              await onFileAdded(payrollString, file, false);
            }}
            onFileRemoved={(file: FileHandle) =>
              onFileRemoved(payrollString, file)
            }
            onFileCancelled={(file: FileHandle) => {
              onFileRemoved(payrollString, file);
            }}
            dataTestId='connect-payroll-file-upload'
            alignItems='flex-start'
            title={
              partner && partner in instructions
                ? instructions[partner]
                : instructions['default']
            }
            bgColor={Color.neutral.white}
          />
          {uploadedDocuments &&
            uploadedDocuments[payrollString] &&
            uploadedDocuments[payrollString].map((doc, index) => {
              return (
                <Flex
                  padding={24}
                  key={`${doc.id}-${index}`}
                  className={classes.uploadedDocs}
                  alignItems='center'
                  justifyContent='space-between'
                  gap={24}
                >
                  <Flex.Cell>
                    <Text text={doc.name} color={Color.neutral._80} />
                    <Text
                      size={11}
                      text='(Previously uploaded)'
                      variant='italic'
                      color={Color.neutral._60}
                    />
                  </Flex.Cell>
                  <Button
                    onClick={() =>
                      deleteDocument(doc.id, payrollString, doc.id)
                    }
                    label={
                      <Text
                        color={Color.semantic.$error50}
                        size={13}
                        variant='medium'
                        text='Remove'
                      />
                    }
                    variant='tertiary'
                    small
                    loading={deleteLoading[doc.id]}
                  />
                </Flex>
              );
            })}
        </Flex.Cell>

        <Divider variant='no-bottom-margin' />
        <Flex gap={24}>
          {files[payrollString] &&
            files[payrollString].length > 0 &&
            !skipThisStep && (
              <Button
                label={
                  importType === ImportType.UnifiedAssessment
                    ? `Submit and continue assessment`
                    : 'Submit'
                }
                onClick={submitManualPayroll}
                dataTestId='submit-manual-payroll-btn'
              />
            )}
          {storeDropdownSelection &&
            payrollSystem !== null &&
            moveToNextStep && (
              <Button
                label='Do this later'
                variant={'outlined'}
                data-testid='payroll_connect_later'
                onClick={() => {
                  showDoThisLaterModal
                    ? setDoThisLaterModal(true)
                    : moveToNextStep(payrollSystem);
                }}
              />
            )}
        </Flex>

        <Modal
          showModal={showEncryptedModal}
          closeToggle={() => setShowEncryptedModal(false)}
          maxWidth={536}
        >
          <Flex direction='column' alignItems='center' gap={16} padding={24}>
            <AttentionNeeded />
            <Text
              variant='bold'
              size={23}
              text='Does the file you uploaded have a password?'
            />
            <Text variant='regular' size={15} tag='span'>
              <ul>
                <li>
                  If Yes - please remove the password protection and re-upload
                </li>
                <li>If No - you may proceed</li>
              </ul>
            </Text>
            <Flex gap={16} justifyContent='flex-end'>
              <Button
                variant='outlined'
                label='Remove Password'
                onClick={() => setShowEncryptedModal(false)}
                dataTestId={'encrypted-modal-secondary-action'}
              />
              <Button
                label={'Proceed with Upload'}
                onClick={() => {
                  if (
                    currentEncryptedFile &&
                    currentEncryptedFile.questionId &&
                    currentEncryptedFile.fileHandle
                  ) {
                    onFileAdded(
                      currentEncryptedFile.questionId,
                      currentEncryptedFile.fileHandle,
                      true,
                    );
                    setShowEncryptedModal(false);
                  }
                }}
                dataTestId={'encryoted-modal-primary-action'}
              />
            </Flex>
          </Flex>
        </Modal>
      </Flex>
    );
  },
);
