import React, { useState } from 'react';
import {
  Card,
  CardFooter,
  Expandable,
  ExpandableHeader,
  Table,
  TextField,
  Text,
  Color,
  Icon,
  IconEnum,
  Content,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { useRootStore } from 'stores/useStores';
import { RdProject } from 'lib/interfaces';

const useStyles = makeStyles(() => ({
  percentageField: {
    width: '80px',
  },
  totalPercentageRow: {
    borderTop: `1px dashed ${Color.neutral._20}`,
  },
  totalPercentageCell: {
    padding: '16px',
    boxSizing: 'border-box',
    flex: '150 0 auto',
    width: '150px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  totalPercentageWarningRow: {
    borderTop: `1px solid ${Color.neutral._20}`,
    borderLeft: `2px solid ${Color.semantic.$warning50}`,
  },
  totalPercentageErrorRow: {
    borderTop: `1px solid ${Color.neutral._20}`,
    borderLeft: `2px solid ${Color.semantic.$error50}`,
  },
  totalPercentageWarningCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
}));

export interface RdProjectWeightsTableProps {
  projects: Array<RdProject>;
  programId: number;
  onContinue?: () => void;
}

export const RdProjectWeightsTable: React.FC<RdProjectWeightsTableProps> = ({
  projects,
  programId,
  onContinue,
}) => {
  const styles = useStyles();
  const { client } = useRootStore();
  const [editMode, setEditMode] = useState<boolean>(true);
  const [weights, setWeights] = useState<number[]>(
    projects.map((p) => p.percentOfTimeOnRd || 0),
  );

  const getTotalWeightPercentage = () => {
    // If only one project exists, it should be 100% of the total,
    // removing the need for the customer to manually input the value.
    if (projects.length === 1) {
      return 100;
    }

    return weights.reduce((accumulator, value) => {
      if (value) {
        return accumulator + value;
      }
      return accumulator;
    }, 0);
  };

  const tableColumns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: '% of time this project represents of total R&D time',
      accessor: 'percentOfTimeOnRd',
      disableSortBy: true,
    },
  ];

  const tableData = projects.map((project, index) => {
    const percentOfTimeOnRd =
      projects.length === 1
        ? 100
        : weights[index] ?? project.percentOfTimeOnRd ?? 0;

    return {
      name: project.name,
      percentOfTimeOnRd: (
        <TextField
          className={styles.percentageField}
          percentFormat
          small
          error={percentOfTimeOnRd > 100}
          value={percentOfTimeOnRd}
          onChange={(e) => {
            const percentToNumber = parseInt(
              e.target.value.replace('%', '').replace(/,/g, ''),
              10,
            );
            if (!isNaN(percentToNumber)) {
              weights[index] = percentToNumber;
              setWeights([...weights]);
            }
          }}
        />
      ),
    };
  });

  const renderWarningRow = () => {
    const rowClassName =
      totalWeightPercentage < 100
        ? styles.totalPercentageWarningRow
        : styles.totalPercentageErrorRow;
    const rowText =
      totalWeightPercentage < 100
        ? 'All projects should sum up to 100%'
        : 'No project could have more than 100% of total time spent on R&D activities';
    const iconColor =
      totalWeightPercentage < 100
        ? Color.semantic.$warning50
        : Color.semantic.$error50;
    return (
      <Content
        className={rowClassName}
        flex
        alignItems={'center'}
        justifyContent={'flex-start'}
        paddingLeftRight={8}
        paddingTopBottom={16}
      >
        <Text className={styles.totalPercentageWarningCell} variant={'medium'}>
          <Icon name={IconEnum.exclamation_triangle} color={iconColor} />
          {rowText}
        </Text>
      </Content>
    );
  };

  const totalWeightPercentage = getTotalWeightPercentage();
  return (
    <Card>
      <ExpandableHeader
        editMode={editMode}
        setEditMode={setEditMode}
        title='Relative weight of each project to total R&D time'
        subtitle="This is the percentage of time each project represents, out of the total time your company spent on R&D.  There's no need to be precise and this will not affect the total credits you can redeem, so a rough estimate is fine."
      />
      <Expandable expand={editMode}>
        <Table
          columns={tableColumns}
          data={tableData}
          paginationSizePerPage={5}
          paginationHideSinglePage
        />
        <Content
          className={styles.totalPercentageRow}
          flex
          alignItems={'center'}
          justifyContent={'flex-start'}
          paddingLeftRight={8}
        >
          <Text className={styles.totalPercentageCell} variant={'medium'}>
            Total
          </Text>
          <Text className={styles.totalPercentageCell}>
            <Text
              variant={'medium'}
              tag={'span'}
              color={
                totalWeightPercentage > 100
                  ? Color.semantic.$error50
                  : Color.neutral._90
              }
            >
              {totalWeightPercentage}{' '}
            </Text>{' '}
            of 100%
            {totalWeightPercentage !== 100 && (
              <Icon
                name={IconEnum.exclamation_triangle}
                color={
                  totalWeightPercentage < 100
                    ? Color.semantic.$warning50
                    : Color.semantic.$error50
                }
              />
            )}
          </Text>
        </Content>
        <>{totalWeightPercentage !== 100 && renderWarningRow()}</>
        <CardFooter
          primaryCtaLabel='Continue'
          primaryCtaDisabled={getTotalWeightPercentage() !== 100}
          primaryOnClick={() => {
            setEditMode(false);

            projects.forEach((project, index) => {
              const percentOfTimeOnRd = weights[index] as number;
              if (percentOfTimeOnRd) {
                client.UpdateRDProject(programId, project.id, {
                  percentOfTimeOnRd,
                });
              }
            });
            if (onContinue) {
              onContinue();
            }
          }}
          variant='primary'
        />
      </Expandable>
    </Card>
  );
};
