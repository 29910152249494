import { makeSubclassObservable } from 'lib/mobx-utils';
import { BaseTaxCreditsStore } from '../BaseTaxCreditsStore';
import { RootStore } from 'stores/RootStore';
import { RdProject, RdProjectSoftware } from 'lib/interfaces';
import {
  RdProjectCategoryEnum,
  RdProjectEligibleInternalSoftwareEnum,
  RdProjectSoftwarePurposeEnum,
} from 'lib/constants';

export class BusinessComponentsStore extends BaseTaxCreditsStore {
  public projects: RdProject[] = [];
  public projectsSubmitted = false;
  public businessComponentSectionComplete = false;
  public fourPartTestModalOpen = false;
  public projectSideDrawerOpen = false;
  public selectedProjectData: Partial<RdProject> = {};
  public rdProjectSoftware: RdProjectSoftware[] = [];

  constructor(rootStore: RootStore) {
    super(rootStore);
    makeSubclassObservable(this);
  }

  public setProjects(projects: RdProject[]): void {
    this.projects = projects;
  }

  public setProjectsSubmitted(submitted: boolean) {
    this.projectsSubmitted = submitted;
  }

  public setBusinessComponentSectionComplete = (submitted: boolean): void => {
    this.businessComponentSectionComplete = submitted;
  };

  public setFourPartTestModalOpen = (toggle: boolean): void => {
    this.fourPartTestModalOpen = toggle;
  };

  public setEditProjectSideDrawerOpen = (toggle: boolean): void => {
    this.projectSideDrawerOpen = toggle;
  };

  public setSelectedProjectName = (name: string): void => {
    this.selectedProjectData.name = name;
  };

  public setSelectedProjectCategory = (
    category: RdProjectCategoryEnum,
  ): void => {
    this.selectedProjectData.category = category;
  };

  public setSelectedProjectSoftwareId = (id: number): void => {
    this.selectedProjectData.rdProjectSoftwareId = id;
  };

  public setSelectedProjectSoftware = (
    rdProjectSoftware: RdProjectSoftware,
  ): void => {
    this.selectedProjectData.rdProjectSoftware = rdProjectSoftware;
  };

  public setSelectedProjectEligibleInternalSoftware = (
    value: RdProjectEligibleInternalSoftwareEnum,
  ): void => {
    this.selectedProjectData.eligibleInternalSoftware = value;
  };

  public setSelectedProjectPassed4PartTest = (passed: boolean): void => {
    this.selectedProjectData.passed4PartTest = passed;
  };

  public setSelectedProjectData = (data: RdProject): void => {
    this.selectedProjectData = data;
  };

  public resetSelectedProjectData = (): void => {
    this.selectedProjectData = {};
  };

  private get projectCannotBeSubmitted() {
    return (
      !this.selectedProjectData.name ||
      !this.selectedProjectData.category ||
      !this.selectedProjectData.passed4PartTest ||
      (this.selectedProjectData.category ===
        RdProjectCategoryEnum.COMPUTER_SOFTWARE &&
        !this.selectedProjectData.rdProjectSoftwareId) ||
      this.selectedProjectData.rdProjectSoftware?.purpose ===
        RdProjectSoftwarePurposeEnum.OTHER ||
      this.selectedProjectData.rdProjectSoftware?.purpose ===
        RdProjectSoftwarePurposeEnum.TO_ALLOW_CUSTOMERS_TO_INTERACT_WITH_AND_EMPLOYEES_TO_FACILITATE_PRIMARY_PRODUCT_OR_SERVICE ||
      (this.selectedProjectData.rdProjectSoftware?.purpose ===
        RdProjectSoftwarePurposeEnum.TO_FACILITATE_INTERNAL_ADMINISTRATIVE_WORK &&
        !this.selectedProjectData.eligibleInternalSoftware) ||
      this.selectedProjectData.eligibleInternalSoftware ===
        RdProjectEligibleInternalSoftwareEnum.NO
    );
  }

  public get addProjectButtonDisabled() {
    return this.projectCannotBeSubmitted;
  }

  public get saveProjectButtonDisabled() {
    return (
      !this.selectedProjectData.id ||
      !this.selectedProjectData.programId ||
      this.projectCannotBeSubmitted
    );
  }

  public setRdProjectSoftware = (software: RdProjectSoftware[]): void => {
    this.rdProjectSoftware = software;
  };
}
