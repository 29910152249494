import React from 'react';
import { observer } from 'mobx-react';
import { Animate, Flex, InfoBanner } from 'component-library';
import { useTaxCreditsStores } from 'stores/useStores';
import {
  VerifyTaxFilingDateModal,
  MissedQuarterModal,
  RedemptionFlowResultModal,
} from '../components';
import { CarryoverAndAdjustStatusEnum } from 'lib/constants';

export const MissedRedemptionModule = observer(() => {
  const { form8974, redemption } = useTaxCreditsStores();
  const missingQuarters = redemption.missedFilings;

  const projectedMissedFiling =
    redemption.filingStatus ===
    CarryoverAndAdjustStatusEnum.PROJECTED_MISSED_FILING;

  return (
    <Animate enter={'fade-in'}>
      <Flex padding={[0, 0, 24, 0]}>
        <InfoBanner
          title='Please confirm - have you redeemed your R&D tax credits?'
          subtitle={`Please click to dismiss this alert, or get help receiving your tax refund now!`}
          type='error'
          ctaLabel='Confirm filing'
          image={`${process.env.PUBLIC_URL}/images/tax-credit-dashboard-module/missing-information.svg`}
          ctaOnClick={async () => redemption.setShowVerifyFilingDateModal(true)}
        />
      </Flex>
      <VerifyTaxFilingDateModal />
      {form8974.mostRecentFinishedProgram && (
        <>
          <RedemptionFlowResultModal />

          {missingQuarters && missingQuarters.length > 0 && (
            <MissedQuarterModal
              missedQuarters={missingQuarters.length}
              programId={form8974.mostRecentFinishedProgram.id}
            />
          )}
        </>
      )}
    </Animate>
  );
});
