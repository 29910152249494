import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Animate,
  Button,
  Content,
  Flex,
  Spinner,
  Text,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { TokenContext } from 'pages/TokenRequired';
import {
  FinchPayrollProvider,
  ImportType,
  PayrollSystem,
} from 'lib/interfaces';
import { CompanyContext } from 'pages/CompanyRequired';
import { Auth0FeatureContext } from 'components/util/Auth0Feature';
import {
  ConnectPayrollCard,
  PayrollConnectedCard,
} from 'pages/dashboard/integrations';
import { datadogLogs } from '@datadog/browser-logs';
import { logContext } from 'logging';
import TitleHeader from 'components/TitleHeader';
import { Page, ProgramStageEnum, ProgramSubStageEnum } from 'lib/constants';
import { useCommonStores } from 'stores/useStores';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    maxWidth: '1200px',
    margin: '0 auto',
    position: 'relative',
  },
}));

interface ConnectPayrollSystem {
  serviceName: FinchPayrollProvider | 'Manual';
  expired: boolean;
}

interface ConnectToPayrollProps {
  taxYear: number;
}

const ConnectToPayroll = ({ taxYear }: ConnectToPayrollProps) => {
  const classes = useStyles();
  const { client } = useContext(Auth0FeatureContext);
  const { app } = useCommonStores();
  const { company, setCompany } = useContext(CompanyContext);
  const loggedIn = !!company;
  const [loading, setLoading] = useState(true);
  const [skipThisStep, setSkipThisStep] = useState(false);
  const { token, email } = useContext(TokenContext);
  const [payrollSystems, setPayrollSystems] = useState<ConnectPayrollSystem[]>(
    [],
  );
  const hasLinkedPayrollSystem = payrollSystems.length > 0;
  const allSurveysViewed = useMemo(() => {
    return company.programs
      .filter((program) => program.taxYear === taxYear)
      .every(
        (program) =>
          program.subStage ===
            ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED ||
          program.subStage ===
            ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT ||
          (program.subStage === null &&
            program.stage === ProgramStageEnum.DISQUALIFIED),
      );
  }, [company.programs, taxYear]);

  const loadPayrollProviders = useCallback(
    (token?: string, email?: string) => {
      setLoading(true);
      client.FinchLinkedServices(token, email).then((providers) => {
        if (providers.errorMsg) {
          datadogLogs.logger.error(
            'loadPayrollProviders FinchLinkedServices error',
            logContext({
              error: providers.errorMsg,
              company,
            }),
          );
        } else {
          setPayrollSystems([
            ...providers!.data!.services.map((service) => {
              return {
                serviceName: service,
                expired: false,
              };
            }),
            ...providers!.data!.expiredServices.map((service) => {
              return {
                serviceName: service,
                expired: true,
              };
            }),
          ]);
        }
        setLoading(false);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client],
  );

  const onPayrollConnected = async () => {
    setLoading(true);
    loadPayrollProviders(token, email);

    // For logged-in users, we need to refresh the company context so that the "payrollConnected" bit is set in the dashboard
    if (loggedIn) {
      await refreshCompany();
    }
    setLoading(false);
  };

  const refreshCompany = () => {
    return client.CurrentLoggedInCompany().then((company) => {
      if (company) {
        setCompany(company);
      }
    });
  };

  const moveToNextStep = async (option?: PayrollSystem) => {
    if (option) {
      await client.UpdateCompanyPayrollSystem({ payrollSystemId: option.id });
    }
  };

  useEffect(() => {
    loadPayrollProviders(token, email);
  }, [email, token, loadPayrollProviders, hasLinkedPayrollSystem]);

  const labelText = allSurveysViewed
    ? 'Review all assessments'
    : 'Continue assessment';

  return (
    <Flex
      direction='column'
      className={classes.root}
      data-testid={'connect-to-payroll'}
    >
      <Animate enter={['fade-in', 'from-top']}>
        <TitleHeader
          title='Payroll Connection'
          showExitButton
          borderBottom
          onCancelExitText='Cancel'
          showCompanyName
          showTalkToExpertButton
        />
      </Animate>

      <Flex padding={24} className={classes.mainContent} direction='column'>
        <Animate enter={'fade-in'}>
          <Text
            text='Connect your payroll account'
            variant='medium'
            size={32}
            paddingBottom={8}
          />
          <Text text='Your payroll data is required in order to calculate your tax credits. Connect your payroll account so we can take care of the hard part for you.' />

          {loading ? (
            <Content paddingTopBottom={24} flex justifyContent='center'>
              <Spinner size='small' color='emerald' />
            </Content>
          ) : (
            <>
              {payrollSystems.map((system, index) => (
                <PayrollConnectedCard
                  key={index}
                  payrollSystem={system.serviceName}
                  onPayrollConnected={onPayrollConnected}
                  expiredPayrollSystem={system.expired}
                  connectAnother={false}
                  useCardComponent
                  importType={ImportType.UnifiedAssessment}
                  taxYear={taxYear}
                />
              ))}
              {!hasLinkedPayrollSystem && (
                <ConnectPayrollCard
                  onPayrollConnected={onPayrollConnected}
                  required={!hasLinkedPayrollSystem}
                  moveToNextStep={moveToNextStep}
                  showDoThisLaterModal
                  storeDropdownSelection
                  useCardComponent
                  importType={ImportType.UnifiedAssessment}
                  taxYear={taxYear}
                  onDoThisLater={() => setSkipThisStep(true)}
                  onAfterManual={() =>
                    app.history.push(
                      `/${Page.taxCredits}/${Page.assessment}/${taxYear}`,
                    )
                  }
                  subtitle='Connecting your payroll account will save you 30+ minutes on your next steps.'
                  skipThisStep={skipThisStep}
                />
              )}
            </>
          )}
          <Flex justifyContent='end'>
            <Button
              label={labelText}
              disabled={!hasLinkedPayrollSystem && !skipThisStep}
              onClick={async () => {
                if (allSurveysViewed) {
                  app.history.push(
                    `/${Page.taxCredits}/${Page.assessment}/${taxYear}/${Page.unifiedAssessmentReview}`,
                  );
                } else {
                  await client.SetBusinessDetailsHistoricalDataPrefill(taxYear);
                  app.history.push(
                    `/${Page.taxCredits}/${Page.assessment}/${taxYear}`,
                  );
                }
              }}
            />
          </Flex>
        </Animate>
      </Flex>
    </Flex>
  );
};

export default ConnectToPayroll;
