import {
  Color,
  Flex,
  FontAwesome,
  Grid,
  Heading,
  Text,
} from 'component-library';
import React from 'react';

export const FourPartTestModalContent = () => {
  const fourParts = [
    {
      title: 'Permitted Purpose',
      description:
        'Your project should involve the creation or improvement of a product or process. (e.g. improving performance or quality, building new functionality, etc.)',
    },
    {
      title: 'Technical Uncertainty',
      description:
        'You should have initial questions about the feasible technical application of your project (e.g. questions related to technical capabilities, quality of design, methodology, etc.)',
    },
    {
      title: 'Process of Experimentation',
      description:
        'You should be running experiments as you build your project. (e.g. A/B tests, MVPs, prototypes, etc)',
    },
    {
      title: 'Technological in Nature',
      description:
        'Your project should rely on hard sciences. (e.g. chemistry, biology, physics, computer science, engineering, etc.)',
    },
  ];

  const renderTestPart = (
    testInfo: { title: string; description: string },
    index: number,
  ) => {
    return (
      <Flex
        alignItems={'flex-start'}
        gap={8}
        key={index}
        padding={[0, 0, 16, 0]}
      >
        <div style={{ paddingTop: '4px' }}>
          <FontAwesome name={'badge-check'} size={15} color={Color.green._60} />
        </div>
        <div>
          <Text text={testInfo.title} variant={'bold'} tag={'span'} size={15} />
          <Text text={' - '} tag={'span'} size={15} />
          <Text text={testInfo.description} tag={'span'} size={15} />
        </div>
      </Flex>
    );
  };
  return (
    <Grid columns={12}>
      <Grid.Cell columns={4}>
        <div
          style={{
            backgroundColor: Color.blue._20,
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/graphics/document-with-magnify-glass.svg`}
            alt={'document with magnify glass'}
          />
        </div>
      </Grid.Cell>

      <Grid.Cell columns={8} padding={[48, 24, 48, 24]}>
        <Heading text={'Four-part Test'} size={24} variant={'medium'} />
        <Text
          text={
            "For your project to qualify as a business component under R&D tax credit regulations, it must meet the IRS's four-part test requirements:"
          }
          paddingBottom={16}
        />
        {fourParts.map(renderTestPart)}
        {/*<Link*/}
        {/*  size={13}*/}
        {/*  external*/}
        {/*  text={'More details about the four-part test'}*/}
        {/*  href={'https://mainstreet.com'}*/}
        {/*/>*/}
      </Grid.Cell>
    </Grid>
  );
};
