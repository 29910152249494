export interface GraphCmsAnswer {
  html: string;
}

export interface CmsAnswerData {
  id: string;
  text: string;
}

// TODO: This duplicates SurveyQuestionInterface from client/src/lib/interfaces/surveyInterfaces.ts
//  Decide which one is the source of truth and deprecate the other
export interface CmsQuestionData {
  id: string;
  text: string;
  answerType: string;
  answerIDs: CmsAnswerData[];
  tooltip: string;
  subtitle: string;
  answerValue?: string | number | string[];
  placeholder?: string;
  helperText?: string;
  errorText?: string;
  subQuestions?: CmsQuestionData[];
  aiQuestion?: string;
}

export interface CmsRenderTree {
  order: string[];
  subGroups: {
    [name: string]: CmsRenderTreeSubgroup;
  };
}

export type RenderTreeShowCondition =
  | CmsRenderTreeShowCondition
  | CmsRenderTreeShowConditions
  | boolean;

export interface CmsRenderTreeSubgroup {
  questions: CmsRenderTreeQuestion[];
  showCondition: RenderTreeShowCondition;
}

export interface CmsRenderTreeShowConditions {
  [key: string]: CmsRenderTreeShowCondition[];
}

export interface CmsRenderTreeShowCondition {
  [key: string]: CmsRenderTreeShowConditionParameters;
}

export interface CmsRenderTreeShowConditionParameters {
  questionId?: string;
  answerValue?: string | number;
  programName?: string;
}

export interface CmsRenderTreeQuestion {
  id: string;
  showCondition:
    | CmsRenderTreeShowCondition
    | CmsRenderTreeShowConditions
    | boolean;
  subQuestions?: CmsRenderTreeQuestion[];
}

export enum GraphCMSAnswerTypeEnum {
  MULTIPLE_CHOICE = 'multiple_choice',
  MULTIPLE_SELECT = 'multiple_select',
  NUMBER = 'number',
  TEXT_AREA = 'text_area',
  YES_OR_NO = 'yes_or_no',
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
  SUBQUESTIONS = 'sub_questions',
}
