import {
  Day,
  Month,
  OrderForm,
  PayrollSystem,
  ProgramName,
} from '../interfaces';

export enum Page {
  accountantProgram = 'accountant/program',
  companyDocs = 'documents',
  connectedAccount = 'connected-account',
  emailPayrollCredentials = 'connect-payroll',
  fedQreGuide = 'federal-qre-guide',
  fedRdProgram = 'federal-rd-program',
  googleLoginSuccess = 'google-login-success',
  treasuryManagement = 'treasury-management',
  connections = 'connections',
  logout = 'logout',
  mstos = 'mainstreet-guarantee-terms',
  subscription = 'billing/subscription',
  orderForm = 'billing/order',
  orderForms = 'billing/orders',
  payrollIntegrationRippling = 'payroll-integration/rippling',
  privacy = 'privacy',
  qualifyingBusiness = 'tax-processing/qualify/overview',
  refer = 'refer',
  resetPassword = 'reset-password',
  selfSignupCompanyDetails = 'company-details',
  taxCreditEstimate = 'tax-processing/qualify/credit-estimate',
  selfSignupOnboarding = 'onboarding',
  selfSignupPersonInformation = 'about-you',
  selfSignupWelcome = 'welcome',
  selfSignupCreateAccount = 'welcome/create-account',
  selfSignupDisqualify = 'welcome/disqualified',
  settings = 'settings',
  tos = 'terms-of-service',
  warningMobile = 'warning-mobile',
  employees = 'employees',
  employees139Dashboard = 'employees-S139-dashboard',
  expenseClassificationOverview = 'tax-processing/expense-classification',
  expenseClassificationOverviewPage = 'overview',
  expenseClassificationCompanyDetails = 'company-details',
  expenseClassificationSuppliesServices = 'supplies-services',
  expenseClassificationEmployees = 'employees',
  expenseClassificationSubmitReview = 'expert-review',
  reviewTaxProcessing = 'tax-processing/review',
  taxCredits = 'tax-credits',
  taxCreditQualificationRoot = 'tax-processing/qualify',
  taxCreditQualification = 'tax-processing/qualify/:tax_year',
  taxCreditRdOverview = 'rd-overview',
  taxCreditRdActivities = 'rd-activities',
  taxCreditRdPayrollDetails = 'rd-payroll-details',
  taxCreditRd4PartTest = 'rd-4-part-test',
  accountCreationRoot = 'account-creation',
  accountCreationBusiness = 'account-creation/business',
  accountCreationContacts = 'account-creation/contacts',
  accountCreationTaxes = 'account-creation/taxes',
  accountCreationPayrollData = 'account-creation/payroll-data',
  accountCreationBusinessActivities = 'account-creation/business-activities',
  accountCreationPrograms = 'account-creation/programs',
  accountCreationCreditEstimate = 'eligible-tax-credits',
  accountCreationConnectingPayrollAccounting = 'eligibility',
  dashboard = '',
  transitionToDashboard = 'welcome-to-mainstreet',
  educationalOnboarding = 'educate',
  universalOnboardingGetStarted = 'get-started',
  universalOnboarding = 'qualification',
  disqualified = 'disqualified',
  missedQuarterlyRedemptionPage = 'quarterly-redemption',
  quarterlyRedemptionDownloadPage = 'download',
  erc = 'erc',
  ercConfirmGrossReceipts = 'confirm-gross-receipts',
  ercPayrollDetails = 'payroll-details',
  ercConnectToPayroll = 'connect-payroll',
  ercUploadTaxReturn = 'upload-tax-return',
  ercUploadPayrollTaxReturn = 'upload-payroll',
  ercConfirmAddress = 'confirm-address',
  ercCreditEstimate = 'credit-estimate',
  ercBillingSummary = 'billing-summary',
  retirementPlan = 'retirement-plan',
  retirementPlanPayrollConnect = 'connect-payroll',
  retirementPlanNHCEDetails = 'nhce-details',
  retirementPlanCreditEstimate = 'credit-estimate',
  retirementPlanBillingSummary = 'billing-summary',
  disabledAccess = 'disabled-access',
  payment = 'payment',
  smallBusinessHealthcare = 'small-business-healthcare',
  assessment = 'assessment',
  assessments = 'assessments',
  assessmentBusinessDetails = 'business-details',
  healthcare = 'healthcare',
  rdConnectPayroll = 'connect-payroll',
  accountantTaxYear = 'accountant/tax-year',
  redemption = 'redemption',
  creditsRedemption = 'credits-redemption',
  employeeRetentionCredits = 'employee-retention-credits',
  unifiedAssessmentReview = 'review',
  sharedSavings = 'shared-savings',
  discover = 'discover',
  savingsAgreement = 'savings-agreement',
  terms = 'terms',
  beneficialOwnershipInformationReport = 'beneficial-ownership',
}

export const MS_DOMAIN = 'mainstreet.com';
export const AT_MS_DOMAIN = `@${MS_DOMAIN}`;
export const SUPPORT_EMAIL_ADDRESS = `support${AT_MS_DOMAIN}`;
export const API = 'api';
export const APIV1 = 'api/v1';
export const APIV2 = 'api/v2';
export const PROMISSORY_NOTE = 'promissory-note';
export const COMPANY = 'company';
export const COMPANIES = 'companies';
export const QUALIFICATION = 'qualification';
export const DASHBOARD = 'dashboard';
export const DOCUMENTS = 'documents';
export const ACCOUNTING = 'accounting';
export const BILLING = 'billing';
export const PAYROLL = 'payroll';
export const EXPENSE_CLASSIFICATION = 'expense-classification';
export const PROGRAM = 'program';
export const COMPANY_ACCESS_TOKEN = 'company-access-token';
export const RD_PROJECTS = 'rdProjects';
export const RD_PROJECT_SOFTWARE = 'rd-project-software';
export const PROJECT = 'project';
export const WORK_DONE = 'work-done';
export const CONFIG = 'config';
export const OAUTH = 'oauth';
export const ORDER = 'order';
export const AUTH = 'auth';
export const EXTERNAL_SERVICE_TOKEN = 'external-service-token';
export const EMPLOYMENT_RECORD = 'employment-record';
export const PAYROLL_ACCESS_LINK = 'payroll-access-link';
export const EMAIL_LINK = 'email-link';
export const LOADING_STATUS = 'loading-status';
export const SURVEY = 'survey';
export const REQUAL_SURVEY = 'requal-survey';
export const ISSUED_SURVEY = 'issued-survey';
export const ALL = 'all';
export const EMPLOYEE_ACCESS_LINK = 'employee-access-link';
export const EMPLOYEE = 'employee';
export const TREASURY_MANAGEMENT = 'treasury-management';
export const FAQ = 'faq';
export const PLAID = 'plaid';
export const LEDGERS = 'ledgers';
export const FORMS = 'forms';
export const ROOT_8974 = '8974';
export const MODULES = 'modules';
export const TRANSACTIONS = 'transactions';
export const CHATBOT_API = 'chatbot-api';
export const CHATBOT_SETTINGS = 'chatbot-settings';
export const CHATBOT_TOGGLE_AI = 'toggle-ai';
export const ORDER_FORM = 'order-form';
export const TERMS = 'terms';
export const ACCEPTANCE = 'acceptance';
export const CORAL = '#ED7560';
export const GRAY = '#F8F8F8';

export const MONEY_BACK_AMOUNT = '$100,000,000';

export const GENERIC_VISUAL_ERROR = `Something went wrong, please try again`;

export const BANNER_DISMISSED_KEY = 'BANNER_DISMISSED';
export const PROD_BANNER_DISMISSED_KEY = 'PROD_BANNER_DISMISSED';
export const RD_PERCENTAGE = 'rd_percentage';
export const QRE_CONVERSION_PERCENTAGE = 0.04;
export const SUBSCRIPTION = 'subscription';
export const ABORT_ERROR = 'AbortError';
export const KYC = 'kyc';
export const IDEMPOTENCY_HEADER = 'idempotency-key';
export const ICECREAM_INTENT = 'reward';
export const PROMOTIONS = 'promotions';
export const TAX_CREDITS = 'tax-credits';
export const CALCULATE_ESTIMATE = 'calculate-estimate';
export const CUSTOMER_IO = 'customer-io';
export const ORGANIZATION_SESSION_KEY = 'ORGANIZATION_SESSION_KEY';

export const ExperimentalProgramNames = [
  'federal_disabled_access_credit',
  'federal_employer_healthcare_premiums_credit',
];

export enum ProgramNameEnum {
  FED_RD_TAX = 'federal_rd_tax_program',
  FED_RETIREMENT_CREDIT = 'federal_retirement_plans_tax_program',
  STATE_RD_CA = 'state_rd_ca_tax_program',
  ERC = 'employee_retention_credit',
  FED_DISABLED_ACCESS = 'federal_disabled_access_credit',
  FED_EMPLOYER_HEALTHCARE = 'federal_employer_healthcare_premiums_credit',
  // Deprecated programs; should no longer be created for new customers.
  DISASTER_RELIEF = 'disaster_relief_credit',
  WOTC = 'work_opportunity_credit',
  PAID_SICK_LEAVE = 'paid_sick_leave_credit',
  MAGIC_MONEY = 'magic_money',
  STATE_RD_AZ = 'state_rd_az_tax_program',
  STATE_RD_GA = 'state_rd_ga_tax_program',
  STATE_RD_MA = 'state_rd_ma_tax_program',
  PROCUREMENT = 'procurement',
  BENEFICIAL_OWNERSHIP_INFORMATION_REPORT = 'beneficial_ownership_information_report',
}

export const StatePrograms = [
  ProgramNameEnum.STATE_RD_AZ,
  ProgramNameEnum.STATE_RD_CA,
  ProgramNameEnum.STATE_RD_GA,
  ProgramNameEnum.STATE_RD_MA,
];

// This allows us to iterate over a series of Programs, instead of hardcoding their names & details.
export const Programs: Record<
  ProgramName,
  {
    display: string;
    displayShort: string;
    description?: string;
    geo: string;
    form: string;
    iconLocation?: string;
    rd: boolean;
  }
> = {
  [ProgramNameEnum.FED_RD_TAX]: {
    display: 'Federal R&D Tax Credit',
    displayShort: 'Fed. R&D',
    description:
      'A federal income and payroll tax incentive for companies with qualified R&D expenditures in the United States.',
    geo: 'federal',
    form: 'Form 6765',
    iconLocation: '/images/rd-tax-credit.svg',
    rd: true,
  },
  [ProgramNameEnum.FED_RETIREMENT_CREDIT]: {
    display: 'Retirement Plan Credit',
    displayShort: 'Fed. Retirement',
    description:
      ' A federal tax incentive for companies establishing retirement plans for their employees.',
    geo: 'federal',
    form: 'Form 8881',
    iconLocation: '/images/rd-tax-credit.svg', // TODO: Placeholder image, replace
    rd: false,
  },
  [ProgramNameEnum.STATE_RD_CA]: {
    display: 'California R&D Tax Credit',
    displayShort: 'CA R&D',
    description:
      'A state income and franchise tax incentive for companies with qualified R&D expenditures in the state of California.',
    geo: 'California',
    form: 'Form 3523',
    iconLocation: '/images/rd-tax-credit.svg',
    rd: true,
  },
  [ProgramNameEnum.STATE_RD_AZ]: {
    display: 'Arizona R&D Tax Credit',
    displayShort: 'AZ R&D',
    description:
      'A state income tax incentive for companies with qualified R&D expenditures in the state of Arizona.',
    geo: 'Arizona',
    form: 'Form 308',
    iconLocation: '/images/rd-tax-credit.svg',
    rd: true,
  },
  [ProgramNameEnum.STATE_RD_GA]: {
    display: 'Georgia R&D Tax Credit',
    displayShort: 'GA R&D',
    description:
      'A state income and payroll tax incentive for companies with qualified R&D expenditures in the state of Georgia.',
    geo: 'Georgia',
    form: 'Form IT-RD',
    iconLocation: '/images/rd-tax-credit.svg',
    rd: true,
  },
  [ProgramNameEnum.STATE_RD_MA]: {
    display: 'Massachusetts R&D Tax Credit',
    displayShort: 'MA R&D',
    description:
      'A state corporate excise tax incentive for companies with qualified R&D expenditures in the state of Massachusetts.',
    geo: 'Massachusetts',
    form: 'Schedule RC',
    iconLocation: '/images/rd-tax-credit.svg',
    rd: true,
  },
  [ProgramNameEnum.ERC]: {
    display: 'Employee Retention Credit',
    displayShort: 'ERC',
    description:
      'A refundable payroll tax incentive for companies with qualified wages paid in the United States.',
    geo: 'federal',
    form: 'Form 941',
    iconLocation: '/images/erc-handshake-icon.svg',
    rd: false,
  },
  [ProgramNameEnum.FED_DISABLED_ACCESS]: {
    display: 'Disabled Access Credit',
    displayShort: 'Fed. Disabled Access',
    description:
      'A federal income tax credit for small businesses in the United States who invest in ADA compliance.',
    geo: 'federal',
    form: 'Form 8826',
    iconLocation: '/images/erc-handshake-icon.svg',
    rd: false,
  },
  [ProgramNameEnum.FED_EMPLOYER_HEALTHCARE]: {
    display: 'Small Business Healthcare Credit',
    displayShort: 'Fed. Business Healthcare',
    description:
      "A federal income tax credit for small businesses in the United States who pay at least 50% of their employees' health insurance premiums.",
    geo: 'federal',
    form: 'Form 8941',
    iconLocation: '/images/erc-handshake-icon.svg',
    rd: false,
  },
  [ProgramNameEnum.DISASTER_RELIEF]: {
    display: 'Section 139 Plan',
    displayShort: 'Sec. 139',
    geo: 'federal',
    form: '',
    iconLocation: '/images/rd-tax-credit.svg',
    rd: false,
  },
  [ProgramNameEnum.WOTC]: {
    display: 'Work Opportunity',
    displayShort: 'Work Opp.',
    geo: 'federal',
    form: '',
    iconLocation: '/images/rd-tax-credit.svg',
    rd: false,
  },
  [ProgramNameEnum.PAID_SICK_LEAVE]: {
    display: 'Credit for Paid Sick Leave',
    displayShort: 'Credit for Paid Sick Leave',
    description:
      'A refundable payroll tax credit that covers wages your employees took off for covid-related issues including receiving and recovering from the vaccine.',
    geo: 'federal',
    form: '',
    iconLocation: '/images/erc-handshake-icon.svg',
    rd: false,
  },
  [ProgramNameEnum.MAGIC_MONEY]: {
    display: 'MainStreet cash-saving tools [Pilot]',
    displayShort: 'MainStreet cash-saving tools [Pilot]',
    description: 'We’re looking for more opportunities to save you money',
    geo: 'federal',
    form: '',
    iconLocation: '/images/erc-handshake-icon.svg',
    rd: false,
  },
  [ProgramNameEnum.PROCUREMENT]: {
    display: 'Procurement',
    displayShort: 'Procurement',
    description:
      "We're working with you to monitor your spend and help you find ways to save.",
    geo: 'federal',
    form: '',
    iconLocation: '/images/erc-handshake-icon.svg',
    rd: false,
  },
  [ProgramNameEnum.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT]: {
    display: 'Beneficial Ownership Information Report',
    displayShort: 'Beneficial Ownership',
    description:
      'You may need to file a Beneficial Ownership Information Report.',
    geo: 'federal',
    form: '',
    iconLocation: '/images/erc-handshake-icon.svg',
    rd: false,
  },
};

export const SupportedProgramTaxYears = [2019, 2020, 2021, 2022];

/**
 * @DeprecatedStatuses: IN_PROGRESS, DATA_COLLECTION, PRODUCING_DOCS
 */
export enum ProgramStageEnum {
  QUALIFYING = 'qualifying',
  EXPENSE_CLASSIFICATION = 'expense_classification',
  MS_REVIEW = 'ms_review',
  CLIENT_REVIEW = 'client_review',
  FINISHED = 'finished',
  DISQUALIFIED = 'disqualified',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  IN_PROGRESS = 'in_progress', // Deprecated
  DATA_COLLECTION = 'data_collection', // Deprecated
  PRODUCING_DOCS = 'producing_documentation', // Deprecated
}

export enum ProgramSubStageEnum {
  WAITING_FOR_FILING_CONF = 'waiting_for_filing_confirmation',
  UPDATING_PAYROLL_SYSTEM = 'updating_payroll_system',
  READY_FOR_BILLING = 'ready_for_billing',
  FILING_UPLOADED = 'filing_uploaded',
  RD_GENERAL_QUESTIONS = 'rd_general_questions',
  RD_INDIVIDUAL_CONTRIBUTIONS = 'rd_individual_contributions',
  ADDITIONAL_EMPLOYEES_TO_BE_ADDED = 'additional_employees_to_be_added',
  REVIEW_IN_PROGRESS = 'review_in_progress',
  ATTENTION_NEEDED = 'attention_needed',
  INSTRUCTIONS_8974_SENT = 'instructions_8974_sent',
  EXPENSE_CLASSIFICATION_OVERVIEW = 'expense_classification_overview',
  EXPENSE_CLASSIFICATION_COMPANY_DETAILS = 'expense_classification_company_details',
  EXPENSE_CLASSIFICATION_RD_EXPENSES = 'expense_classification_rd_expenses',
  EXPENSE_CLASSIFICATION_RD_EMPLOYEES = 'expense_classification_rd_employees',
  EXPENSE_CLASSIFICATION_READY_TO_SUBMIT = 'expense_classification_ready_to_submit',
  EXPENSE_CLASSIFICATION_SURVEY_SKIPPED = 'expense_classification_survey_skipped',
  READY_TO_REDEEM = 'ready_to_redeem',
  REDEEMING = 'redeeming',
  DISQUALIFIED_BY_OPS = 'disqualified_by_ops',
}

export enum ProgramActionStageEnum {
  ADD_MISSING_DATA = 'add_missing_data',
  UPLOAD_EXPENSES = 'upload_expenses',
  REVIEW_DATA = 'review_data',
  ENTER_EXPENSES = 'enter_expenses',
  ADJUST_ACTIVITIES = 'adjust_activities',
  PROVIDE_TAX_INFO = 'provide_tax_info',
}

export const ProgramActionStageToText: Record<ProgramActionStageEnum, string> =
  {
    [ProgramActionStageEnum.ADD_MISSING_DATA]: 'Add missing data',
    [ProgramActionStageEnum.UPLOAD_EXPENSES]: 'Upload expenses',
    [ProgramActionStageEnum.REVIEW_DATA]: 'Review and confirm data',
    [ProgramActionStageEnum.ENTER_EXPENSES]: 'Enter expense data',
    [ProgramActionStageEnum.ADJUST_ACTIVITIES]: 'Adjust R&D activities',
    [ProgramActionStageEnum.PROVIDE_TAX_INFO]: 'Provide tax filing information',
  };

export enum SignupTypeEnum {
  SELF_SIGNUP = 'self_signup',
}

export enum SignupStageEnum {
  COMPANY_DETAILS_IN_PROGRESS = 'company_details_in_progress',
  FINISHED = 'finished',
  NA = 'N/A',
}

export const IncompleteSignupStages = [
  SignupStageEnum.COMPANY_DETAILS_IN_PROGRESS,
];

export enum QualificationStatusEnum {
  QUALIFICATION_IN_PROGRESS = 'qualification_in_progress',
  QUALIFICATION_SUBMITTED = 'qualification_submitted',
  QUALIFIED = 'qualified',
  DISQUALIFIED = 'disqualified',
  NA = 'na',
}

export enum AutoqualificationStatusEnum {
  QUALIFIED = 'qualified',
  DISQUALIFIED = 'disqualified',
  UNSURE = 'unsure',
}

export enum ExpectedCreditTypeEnum {
  PAYROLL_TAX = 'payroll_tax',
  INCOME_TAX = 'income_tax',
  UNDETERMINED = 'undetermined',
  DEFERRED_INCOME = 'deferred_income',
}

export enum CreditCategoryEnum {
  RESEARCH_AND_DEV = 'Research and Development',
  HIRING_COMPANY_EXPANSION = 'Hiring and Company Expansion',
  TRAINING_WORKFORCE_DEV = 'Training and Workforce Development',
}

export enum BillingScheduleStatusEnum {
  ACTIVE = 'Active',
  COMPLETED = 'Completed',
  SUSPENDED = 'Suspended',
  CANCELLED = 'Cancelled',
}

export enum BillingScheduleTypeEnum {
  BILLING = 'billing',
  FUNDING = 'funding',
  PAYBACK = 'payback',
}

// Server Version: BusinessTypesEnum @see /client/src/lib/constants/globalConstants.ts
export const BusinessTypes = [
  'C Corp',
  'S Corp',
  'LLC',
  'Partnership / LLP',
  'Sole Proprietorship',
  'Nonprofit Corporation',
  'B Corp',
  'Close Corporation',
  'Cooperative',
];

export const SUPPORTED_PAYROLL_SYSTEMS = [
  'Gusto',
  'Justworks',
  'QuickBooks Payroll',
  'Rippling',
  'Zenefits',
];

// See finch supported Payroll. We only support the ones with Pay Data
// https://developer.tryfinch.com/docs/documentation/3tucvi0qnrbzy-providers
// WARNING: Do not add payroll providers that we have direct connections for
//          to this list. There is a separate constant for those providers.
export const FINCH_PAYROLL_SYSTEMS: PayrollSystem[] = [
  { id: 'gusto', displayName: 'Gusto', manual: false },
  { id: 'justworks', displayName: 'Justworks', manual: false },
  { id: 'quickbooks', displayName: 'QuickBooks', manual: false },
  { id: 'zenefits', displayName: 'Zenefits', manual: false },
  { id: 'paylocity', displayName: 'Paylocity', manual: false },
  { id: 'adp_run', displayName: 'Run Powered by ADP', manual: false },
  { id: 'sequoia_one', displayName: 'Sequoia One', manual: false },
  { id: 'paycom', displayName: 'Paycom', manual: false },
  { id: 'paychex_flex', displayName: 'Paychex Flex', manual: false },
  {
    id: 'adp_workforce_now',
    displayName: 'ADP Workforce Now',
    manual: false,
  },
  { id: 'trinet', displayName: 'TriNet', manual: false },
  {
    id: 'square_payroll',
    displayName: 'Square Payroll',
    manual: false,
  },
  { id: 'insperity', displayName: 'Insperity', manual: false },
  { id: 'paycor', displayName: 'Paycor', manual: true },
  { id: 'isolved', displayName: 'isolved', manual: true },
  { id: 'wave', displayName: 'Wave', manual: false },
  { id: 'toast_payroll', displayName: 'Toast Payroll', manual: true },
  { id: 'patriot', displayName: 'Patriot', manual: true },
  { id: 'onpay', displayName: 'OnPay', manual: true },
  {
    id: 'alphastaff',
    displayName: 'AlphaStaff',
    manual: false,
  },
  {
    id: 'ukg_ready',
    displayName: 'UKG Ready',
    manual: false,
  },
  {
    id: 'ulti_pro',
    displayName: 'UltiPro (UKG Pro)',
    manual: false,
  },
  {
    id: 'adp_comprehensive',
    displayName: 'ADP Comprehensive Services',
    manual: true,
  },
  {
    id: 'ceridian_dayforce',
    displayName: 'Ceridian Dayforce',
    manual: true,
  },
  {
    id: 'horizonpayroll',
    displayName: 'Horizon Payroll',
    manual: false,
  },
  {
    id: 'paynw',
    displayName: 'PayNorthwest',
    manual: true,
  },
  {
    id: 'payroll_office_of_america',
    displayName: 'Payroll Office of America',
    manual: true,
  },
  {
    id: 'payroll_solutions',
    displayName: 'Payroll Solutions',
    manual: true,
  },
  {
    id: 'paytime',
    displayName: 'Paytime',
    manual: true,
  },
  {
    id: 'vensure_hr',
    displayName: 'VensureHR',
    manual: true,
  },
  {
    id: 'venture_workforce',
    displayName: 'Venture Workforce',
    manual: true,
  },
  {
    id: 'vfficient',
    displayName: 'Vfficient',
    manual: true,
  },
];

export const NON_FINCH_PAYROLL_SYSTEMS: PayrollSystem[] = [
  { id: 'rippling', displayName: 'Rippling', manual: false },
];

export const ALL_PAYROLL_SYSTEMS: PayrollSystem[] = [
  ...FINCH_PAYROLL_SYSTEMS,
  ...NON_FINCH_PAYROLL_SYSTEMS,
];

export const Days: Day[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const Months: Month[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const MonthsToQuarter: Record<string, number> = {
  January: 1,
  February: 1,
  March: 1,
  April: 2,
  May: 2,
  June: 2,
  July: 3,
  August: 3,
  September: 3,
  October: 4,
  November: 4,
  December: 4,
};

export enum LoadingStatusTypeEnum {
  PAYROLL = 'payroll',
}

export enum LoadingStatusEnum {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  ERRORED = 'errored',
  NOT_STARTED = 'not_started',
}

export enum SubGroupNameEnum {
  VENDOR_CLOUD_EXPENSES = 'vendorCloudExpenses',
  VENDOR_SUPPLY_EXPENSES = 'vendorSupplyExpenses',
  VENDOR_PROJECTS = 'vendorProjects',
  INCOME_AND_RESEARCH_SPEND = 'incomeAndResearchSpend',
  EXTRA_DETAILS = 'extraDetails',
}

export enum JobGroupEnum {
  SOFTWARE_ENGINEER = 'software_engineer',
  ENGINEER = 'engineer_non_software',
  SCIENTIST = 'scientist',
  LAB_TECHNICIAN = 'lab_technician',
  CLINICAL_OPS_PROGRAM_MANAGER = 'clinical_operations_program_manager',
  DATA_SCIENTIST = 'data_scientist',
  PRODUCT_MANAGER = 'product_manager',
  DESIGNER = 'designer',
  USER_RESEARCHER = 'user_researcher',
  QA_TESTING = 'qa_testing',
  MANUFACTURING = 'manufacturing',
  CEO = 'ceo',
  FOUNDER = 'founder',
  CUSTOMER_FACING_ROLE = 'customer_facing_roles',
  REGULATORY_AFFAIRS = 'regulatory_affairs',
  MARKETING = 'marketing',
  OPS_PROGRAM_MANAGER = 'operations_program_manager',
  BUSINESS = 'business',
  EXECUTIVE_SUPPORT = 'executive_support',
  COMMUNICATIONS = 'communications',
  SUPPLY_CHAIN_LOGISTICS = 'supply_chain_logistics',
  HUMAN_RESOURCES = 'human_resources',
  LEGAL = 'legal',
  OTHER = 'other',
  SCIENTIST_MANAGEMENT = 'scientist_management',
  CONTENT = 'content',
  MEDICAL = 'medical',
  ADVISOR_BOARD_MEMBER = 'advisor_board_member',
}

export const JobGroupEnumToEducationTitle: { [key in JobGroupEnum]: string } = {
  [JobGroupEnum.ADVISOR_BOARD_MEMBER]: 'board member',
  [JobGroupEnum.BUSINESS]: 'strategy or finance professional',
  [JobGroupEnum.CEO]: 'CEO',
  [JobGroupEnum.CLINICAL_OPS_PROGRAM_MANAGER]: 'clinical operations manager',
  [JobGroupEnum.COMMUNICATIONS]: 'communications professional',
  [JobGroupEnum.CONTENT]: 'content creator',
  [JobGroupEnum.CUSTOMER_FACING_ROLE]: 'customer-facing employee',
  [JobGroupEnum.DATA_SCIENTIST]: 'data scientist',
  [JobGroupEnum.DESIGNER]: 'designer',
  [JobGroupEnum.ENGINEER]: 'engineer',
  [JobGroupEnum.EXECUTIVE_SUPPORT]: 'executive support professional',
  [JobGroupEnum.FOUNDER]: 'technical leader',
  [JobGroupEnum.SCIENTIST_MANAGEMENT]: 'technical leader',
  [JobGroupEnum.HUMAN_RESOURCES]: 'human resources professional',
  [JobGroupEnum.LAB_TECHNICIAN]: 'lab technician',
  [JobGroupEnum.LEGAL]: 'legal professional',
  [JobGroupEnum.MANUFACTURING]: 'manufacturer',
  [JobGroupEnum.MARKETING]: 'marketer',
  [JobGroupEnum.MEDICAL]: 'medical professional',
  [JobGroupEnum.OPS_PROGRAM_MANAGER]: 'operations manager',
  [JobGroupEnum.OTHER]: 'average employee',
  [JobGroupEnum.PRODUCT_MANAGER]: 'product manager',
  [JobGroupEnum.QA_TESTING]: 'QA tester',
  [JobGroupEnum.REGULATORY_AFFAIRS]: 'regulatory affairs specialist',
  [JobGroupEnum.SCIENTIST]: 'scientist',
  [JobGroupEnum.SOFTWARE_ENGINEER]: 'software engineer',
  [JobGroupEnum.SUPPLY_CHAIN_LOGISTICS]: 'supply chain professional',
  [JobGroupEnum.USER_RESEARCHER]: 'user researcher',
};

export const JobGroupEnumToString: { [key in JobGroupEnum]: string } = {
  [JobGroupEnum.SOFTWARE_ENGINEER]: 'Software Engineering',
  [JobGroupEnum.ENGINEER]: 'Non-Software Engineering',
  [JobGroupEnum.SCIENTIST]: 'Science',
  [JobGroupEnum.LAB_TECHNICIAN]: 'Lab Technicians',
  [JobGroupEnum.CLINICAL_OPS_PROGRAM_MANAGER]:
    'Clinical Operations/Program Management',
  [JobGroupEnum.DATA_SCIENTIST]: 'Data Science',
  [JobGroupEnum.PRODUCT_MANAGER]: 'Product Management',
  [JobGroupEnum.DESIGNER]: 'Design',
  [JobGroupEnum.USER_RESEARCHER]: 'User Research',
  [JobGroupEnum.QA_TESTING]: 'QA Testing',
  [JobGroupEnum.MANUFACTURING]: 'Manufacturing',
  [JobGroupEnum.CEO]: 'CEO',
  [JobGroupEnum.FOUNDER]: 'Technical Leadership',
  [JobGroupEnum.CUSTOMER_FACING_ROLE]: 'Customer-Facing Roles',
  [JobGroupEnum.REGULATORY_AFFAIRS]: 'Regulatory Affairs',
  [JobGroupEnum.MARKETING]: 'Marketing',
  [JobGroupEnum.OPS_PROGRAM_MANAGER]: 'Operations/Program Management',
  [JobGroupEnum.BUSINESS]: 'Strategy, Finance, and Partnerships',
  [JobGroupEnum.EXECUTIVE_SUPPORT]: 'Executive Support',
  [JobGroupEnum.COMMUNICATIONS]: 'Communications',
  [JobGroupEnum.SUPPLY_CHAIN_LOGISTICS]: 'Supply Chain/Logistics',
  [JobGroupEnum.HUMAN_RESOURCES]: 'Human Resources',
  [JobGroupEnum.LEGAL]: 'Legal',
  [JobGroupEnum.OTHER]: 'Other',
  [JobGroupEnum.SCIENTIST_MANAGEMENT]: 'Science - Management',
  [JobGroupEnum.CONTENT]: 'Content',
  [JobGroupEnum.MEDICAL]: 'Medical',
  [JobGroupEnum.ADVISOR_BOARD_MEMBER]: 'Advisor/Board Members',
};

export type JobGroupPercents = { percent: number; low: number; high: number };

export enum JobGroupVersion {
  V1 = 'V1',
  V2 = 'V2',
}

export const JobGroupV1CutoffDate = new Date('2024-01-09');

// JobGroupVersion: V1
export const JobGroupToPercents: Record<JobGroupEnum, JobGroupPercents> = {
  [JobGroupEnum.SOFTWARE_ENGINEER]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.ENGINEER]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.SCIENTIST]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.DESIGNER]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.LAB_TECHNICIAN]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.CLINICAL_OPS_PROGRAM_MANAGER]: {
    percent: 80,
    low: 75,
    high: 100,
  },
  [JobGroupEnum.DATA_SCIENTIST]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.PRODUCT_MANAGER]: { percent: 80, low: 75, high: 80 },
  [JobGroupEnum.USER_RESEARCHER]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.QA_TESTING]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.MANUFACTURING]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.CEO]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.FOUNDER]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.CUSTOMER_FACING_ROLE]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.REGULATORY_AFFAIRS]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.MARKETING]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.OPS_PROGRAM_MANAGER]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.BUSINESS]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.EXECUTIVE_SUPPORT]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.COMMUNICATIONS]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.SUPPLY_CHAIN_LOGISTICS]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.HUMAN_RESOURCES]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.LEGAL]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.OTHER]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.SCIENTIST_MANAGEMENT]: { percent: 0, low: 0, high: 0 },
  [JobGroupEnum.CONTENT]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.MEDICAL]: { percent: 80, low: 75, high: 100 },
  [JobGroupEnum.ADVISOR_BOARD_MEMBER]: { percent: 80, low: 75, high: 100 },
};

// This must be kept in sync with constant in server/src/serverConstants.ts
const percentageGroups: Record<string, JobGroupPercents> = {
  HIGH: {
    percent: 80,
    high: 100,
    low: 60,
  },
  MEDIUM: {
    percent: 50,
    high: 75,
    low: 25,
  },
  LOW: {
    percent: 25,
    high: 40,
    low: 10,
  },
  NO: {
    percent: 5,
    high: 25,
    low: 0,
  },
};

export const JobGroupToPercentsV2: Record<JobGroupEnum, JobGroupPercents> = {
  // HIGH
  [JobGroupEnum.DESIGNER]: percentageGroups.HIGH,
  [JobGroupEnum.ENGINEER]: percentageGroups.HIGH,
  [JobGroupEnum.PRODUCT_MANAGER]: percentageGroups.HIGH,
  [JobGroupEnum.SCIENTIST]: percentageGroups.HIGH,
  [JobGroupEnum.SOFTWARE_ENGINEER]: percentageGroups.HIGH,
  // MEDIUM
  [JobGroupEnum.CEO]: percentageGroups.MEDIUM,
  [JobGroupEnum.DATA_SCIENTIST]: percentageGroups.MEDIUM,
  [JobGroupEnum.FOUNDER]: percentageGroups.MEDIUM,
  [JobGroupEnum.LAB_TECHNICIAN]: percentageGroups.MEDIUM,
  [JobGroupEnum.OPS_PROGRAM_MANAGER]: percentageGroups.MEDIUM,
  [JobGroupEnum.OTHER]: percentageGroups.MEDIUM,
  [JobGroupEnum.USER_RESEARCHER]: percentageGroups.MEDIUM,
  // LOW
  [JobGroupEnum.CLINICAL_OPS_PROGRAM_MANAGER]: percentageGroups.LOW,
  [JobGroupEnum.CUSTOMER_FACING_ROLE]: percentageGroups.LOW,
  [JobGroupEnum.MANUFACTURING]: percentageGroups.LOW,
  [JobGroupEnum.QA_TESTING]: percentageGroups.LOW,
  [JobGroupEnum.SUPPLY_CHAIN_LOGISTICS]: percentageGroups.LOW,
  // NO
  [JobGroupEnum.ADVISOR_BOARD_MEMBER]: percentageGroups.NO,
  [JobGroupEnum.BUSINESS]: percentageGroups.NO,
  [JobGroupEnum.COMMUNICATIONS]: percentageGroups.NO,
  [JobGroupEnum.CONTENT]: percentageGroups.NO,
  [JobGroupEnum.EXECUTIVE_SUPPORT]: percentageGroups.NO,
  [JobGroupEnum.HUMAN_RESOURCES]: percentageGroups.NO,
  [JobGroupEnum.LEGAL]: percentageGroups.NO,
  [JobGroupEnum.MARKETING]: percentageGroups.NO,
  [JobGroupEnum.MEDICAL]: percentageGroups.NO,
  [JobGroupEnum.REGULATORY_AFFAIRS]: percentageGroups.NO,
  [JobGroupEnum.SCIENTIST_MANAGEMENT]: percentageGroups.NO,
};

export const JobGroupToPercentVersions: Record<
  JobGroupVersion,
  Record<JobGroupEnum, JobGroupPercents>
> = {
  [JobGroupVersion.V1]: JobGroupToPercents,
  [JobGroupVersion.V2]: JobGroupToPercentsV2,
};

export const JobGroupEnumToEducationText: {
  [key in JobGroupEnum]: { summary: string; bullets: string[] };
} = {
  [JobGroupEnum.ADVISOR_BOARD_MEMBER]: {
    summary:
      'An advisor may spend part of their time supervising, supporting, and conducting R&D-qualifying activities like these:',
    bullets: [
      'Providing technical guidance to the R&D team',
      'Evaluating R&D results to determine future development direction',
      'Acting as a subject matter expert to identify new technical uncertainties',
      'Engaging in R&D activities to create informed decisions about future product innovations',
      'Attending board meetings, preparing financial reports, and participating in legal and administrative matters would not qualify.',
    ],
  },
  [JobGroupEnum.BUSINESS]: {
    summary:
      'A strategy or finance professional may spend part of their time supervising, supporting, and conducting R&D-qualifying activities like these:',
    bullets: [
      'Ensuring R&D efforts align with business strategy, and tracking progress against those efforts',
      'Identifying key areas of the business that require R&D in order to achieve company goals',
      'Making plans with the R&D team on how to conduct research, and advising on iterations on those plans',
      'Developing a business plan, building a team of employees, raising capital, and creating partnerships would not qualify.',
    ],
  },
  [JobGroupEnum.CEO]: {
    summary:
      'A CEO may spend part of their time supervising, supporting, and conducting R&D-qualifying activities like these:',
    bullets: [
      'Identifying new opportunities for innovation, and setting the overall R&D strategy and goals',
      'Developing, testing, and providing feedback on new products',
      'Coordinating with external vendors to integrate their products, and defining requirements for how those products will be used',
      'Tracking and analyzing R&D progress and performance',
      'Fundraising, networking, marketing, legal, and financial activities would not qualify.',
    ],
  },
  [JobGroupEnum.CLINICAL_OPS_PROGRAM_MANAGER]: {
    summary:
      'A clinical operations manager likely spends much of their time supervising, supporting, and conducting R&D-qualifying activities like these:',
    bullets: [
      'Overseeing clinical trials and data collection',
      'Holding meetings to track and communicate R&D progress and results',
      'Ensuring compliance with relevant regulatory requirements',
      'Analyzing and interpreting feedback and R&D data to inform future research',
      'Purchasing supplies, hiring employees, maintaining equipment, and performing administrative tasks would not qualify.',
    ],
  },
  [JobGroupEnum.COMMUNICATIONS]: {
    summary:
      'Although most typical communications activities don’t qualify towards the R&D credit, job titles don’t always tell the whole story! Regardless of role, any employee could spend part of their time supporting, supervising, and conducting R&D-qualifying activities like these:',
    bullets: [
      'Conducting user research to determine the optimal specifications for new product features',
      'Developing new products or processes, or substantially improving existing ones',
      'Communicating progress on R&D projects to internal stakeholders',
      'Testing and analyzing data to inform future development',
      'Participating in marketing and advertising efforts, managing the company’s online presence, and developing general communications strategies would not qualify.',
    ],
  },
  [JobGroupEnum.CONTENT]: {
    summary:
      'A content creator may spend part of their time supporting, supervising, and conducting R&D-qualifying activities like these:',
    bullets: [
      'Conducting user research to understand the needs and preferences of the target audience',
      'Writing technical documentation for the R&D team',
      'Designing and implementing tracking and analytics on the performance of content to inform future R&D efforts',
      'Creating content for non-technical publications such as emails, company newsletters, onboarding materials, and company-wide meetings would not qualify.',
    ],
  },
  [JobGroupEnum.CUSTOMER_FACING_ROLE]: {
    summary:
      'A customer-facing employee may spend much of their time supporting, conducting, and supervising R&D-qualifying activities like these:',
    bullets: [
      'Gathering, analyzing, and sharing customer insights on the product',
      'Collaborating with R&D teams to develop and implement customer-centric design processes',
      'Participating in meetings to discuss R&D progress and goals',
      'Providing feedback on product designs, and testing new features to ensure that they meet customer needs',
      'Handling day-to-day customer support, managing customer relationships, and generating sales leads would not qualify.',
    ],
  },
  [JobGroupEnum.DATA_SCIENTIST]: {
    summary:
      'A data scientist like spends much of their time conducting, supporting, and supervising R&D-qualifying activities like these:',
    bullets: [
      'Designing and executing experiments to test hypotheses',
      'Identifying patterns and insights to inform product development',
      'Developing, implementing, and iterating on machine learning models and data pipelines',
      'Leading or providing technical guidance to junior data scientists',
      'Creating and maintaining sales KPI dashboards, answering customer service inquiries, and performing routine data collection would not qualify.',
    ],
  },
  [JobGroupEnum.DESIGNER]: {
    summary:
      'A designer may spends much of their time conducting, supporting, and supervising R&D-qualifying activities like these:',
    bullets: [
      'Conceptualizing, designing, and prototyping new products and features',
      'Conducting user testing and gathering feedback',
      'Identifying and sourcing new technologies and materials',
      'Collaborating cross-functionally to ensure products are developed effectively',
      'Designing aesthetic improvements like rebranding, maintaining websites and social media, and performing administrative tasks would not qualify.',
    ],
  },
  [JobGroupEnum.ENGINEER]: {
    summary:
      'An engineer likely spends the majority of their time conducting, supervising, and supporting R&D-qualifying activities like these:',
    bullets: [
      'Designing, testing, and experimenting with new products and processes',
      'Providing technical guidance to junior engineers',
      'Developing new materials or additives to enhance product performance and functionality',
      'Conducting simulations and analysis to determine feasibility',
      'Routine data entry, quality control, and administrative tasks would not qualify.',
    ],
  },
  [JobGroupEnum.EXECUTIVE_SUPPORT]: {
    summary:
      'An executive support professional may spend part of their time supporting, supervising, and conducting R&D-qualifying activities like these:',
    bullets: [
      'Facilitating communication and coordination between R&D teams and other departments',
      'Assisting with the preparation of R&D project proposals and reports',
      'Monitoring and reporting on the progress of R&D projects',
      'Helping to create and maintain R&D-related documentation and records',
      'Preparing financial reports or budget forecasts, organizing company conferences and tradeshows, and providing general administrative support to non-R&D departments would not qualify.',
    ],
  },
  [JobGroupEnum.FOUNDER]: {
    summary:
      'A technical leader likely spends much of their time supervising, supporting, and conducting R&D-qualifying activities like these:',
    bullets: [
      'Setting R&D goals and objectives, often as the product visionary',
      'Participating in product design and development',
      'Ensuring compliance with R&D policies and procedures',
      'Administrative tasks, hiring, budgeting, and financial planning activities would not qualify.',
    ],
  },
  [JobGroupEnum.SCIENTIST_MANAGEMENT]: {
    summary:
      'A manager of scientists likely spends the majority of their time supervising, conducting, and supporting R&D-qualifying activities like these:',
    bullets: [
      'Overseeing the design, testing, and refinement of a new product',
      'Evaluating and communicating the progress of R&D projects',
      'Collecting and sharing feedback from cross-functional teams to inform the direction of future research',
      'Supervising and providing technical feedback to scientists',
      'Developing employee evaluation criteria, preparing financial reports, and reviewing routine data collection would not qualify.',
    ],
  },
  [JobGroupEnum.HUMAN_RESOURCES]: {
    summary:
      'Although most typical HR activities don’t qualify towards the R&D credit, job titles don’t always tell the whole story! Regardless of role, any employee could spend part of their time supporting, conducting, and supervising R&D-qualifying activities like these:',
    bullets: [
      'Providing subject matter expertise to the R&D team, to influence requirements for R&D projects',
      'Providing on-the-job technical training to R&D employees',
      'Developing new products or processes, or substantially improving existing ones',
      'Testing and analyzing data to inform future development',
      'Managing payroll and employee benefits administration, handling employee relations issues, and maintaining employee databases would not qualify.',
    ],
  },
  [JobGroupEnum.LAB_TECHNICIAN]: {
    summary:
      'A lab technician may spend the majority of their time conducting, supporting, and supervising R&D-qualifying activities like these:',
    bullets: [
      'Designing and conducting experiments, or providing technical guidance to junior lab technicians to do so',
      'Developing and testing prototypes',
      'Analyzing test data, writing reports, and presenting results to the R&D teams',
      'Testing the composition of new materials to determine how they might be used to improve a product',
      'Ordering supplies, cleaning the lab, and performing administrative tasks such as routine data entry would not qualify.',
    ],
  },
  [JobGroupEnum.LEGAL]: {
    summary:
      'Although most typical law activities don’t qualify towards the R&D credit, job titles don’t always tell the whole story! Regardless of role, any employee could spend part of their time supporting, conducting, and supervising R&D-qualifying activities like these:',
    bullets: [
      'Performing advanced product research as a subject matter expert',
      'Developing new products or processes, or substantially improving existing ones',
      'Testing and analyzing data to inform future development',
      'Gathering and sharing feedback to development teams',
      'Drafting legal documents, conducting legal research, and providing legal support for day-to-day operations would not qualify.',
    ],
  },
  [JobGroupEnum.MANUFACTURING]: {
    summary:
      'A manufacturer may spend part of their time conducting, supporting, and supervising R&D-qualifying activities like these:',
    bullets: [
      'Designing and testing prototypes',
      'Improving production processes through technical experimentation',
      'Developing new materials or techniques for manufacturing',
      'Evaluating the feasibility of new product ideas',
      'Routine manufacturing operations, market research, and activities related to purchasing, packaging, and distribution of goods for sale would not qualify.',
    ],
  },
  [JobGroupEnum.MARKETING]: {
    summary:
      'Although most typical marketing activities don’t qualify towards the R&D credit, job titles don’t always tell the whole story! Regardless of role, any employee could spend part of their time supporting, conducting, and supervising R&D-qualifying activities like these:',
    bullets: [
      'Gathering and analyzing customer feedback',
      'Providing subject matter expertise to the R&D team, to influence requirements for R&D projects',
      'Participating in product design and development',
      'Overseeing the progress of R&D projects',
      'Public relations, branding, advertising, and administrative tasks would not qualify.',
    ],
  },
  [JobGroupEnum.MEDICAL]: {
    summary:
      'A medical professional may spend much of their time conducting, supervising, and supporting R&D-qualifying activities like these:',
    bullets: [
      'Developing new medical devices or technologies',
      'Conducting, reviewing, and analyzing clinical trials and studies',
      'Providing medical expertise and guidance to R&D teams',
      'Ensuring that products in development meet regulatory requirements',
      'Providing routine patient care, participating in marketing activities, and managing administrative tasks would not qualify.',
    ],
  },
  [JobGroupEnum.OPS_PROGRAM_MANAGER]: {
    summary:
      'An operations manager may spend much of their time supporting, supervising, and conducting R&D-qualifying activities like these:',
    bullets: [
      'Identifying and mitigating technical risks in the R&D process',
      'Analyzing data to influence product development',
      'Performing product research as subject matter experts',
      'Participating in R&D project status meetings',
      'Budgeting, compliance work, and facility management would not qualify.',
    ],
  },
  [JobGroupEnum.OTHER]: {
    summary:
      'Any employee regardless of job title could spend part of their time supporting, conducting, and supervising R&D-qualifying activities like these:',
    bullets: [
      'Developing new products or processes, or substantially improving existing ones',
      'Testing and analyzing data to inform future development',
      'Gathering and sharing feedback to development teams',
      'Participating in R&D project status meetings',
      'Financial analysis, maintenance of existing products or processes, and administrative tasks would not qualify.',
    ],
  },
  [JobGroupEnum.PRODUCT_MANAGER]: {
    summary:
      'A product manager likely spends the majority of their time supervising, conducting, and supporting R&D-qualifying activities like these:',
    bullets: [
      'Defining product requirements and specifications',
      'Tracking and analyzing product performance, progress, and goals to influence future iterations',
      'Collaborating with cross-functional teams to develop and launch new products',
      'Coordinating with external vendors to integrate their products and iterate on those integrations',
      'Conducting competitive analysis, managing budgets, and hiring and managing employees would not qualify.',
    ],
  },
  [JobGroupEnum.QA_TESTING]: {
    summary:
      'A QA tester may spend part of their time supporting, conducting, and supervising R&D-qualifying activities like these:',
    bullets: [
      'Identifying and analyzing root causes of issues in a new product or technology',
      'Conducting usability testing, and developing automated testing scripts',
      'Providing guidance and feedback to other QA engineers and technicians',
      'Participating in design and code reviews',
      'Quality control such as testing without involving experimentation, documenting routine processes and procedures, and performing regular maintenance on test environments would not qualify.',
    ],
  },
  [JobGroupEnum.REGULATORY_AFFAIRS]: {
    summary:
      'A regulatory affairs specialist may spend part of their time supporting, supervising, and conducting R&D-qualifying activities like these:',
    bullets: [
      'Providing regulatory guidance, resources, and expertise to support R&D teams on defining the development requirements/specifications',
      'Following a process of technical experimentation to develop new processes and procedures that meet regulatory requirements',
      'Researching regulatory changes, that will introduce new technical uncertainties in the development of the product',
      'Submitting applications to regulatory bodies, monitoring changes in regulations, and performing administrative tasks would not qualify.',
    ],
  },
  [JobGroupEnum.SCIENTIST]: {
    summary:
      'A scientist likely spends the majority of their time conducting, supervising, and supporting R&D-qualifying activities like these:',
    bullets: [
      'Developing new product designs, or substantially improving existing ones',
      'Writing technical reports and presenting research findings',
      'Conducting laboratory experiments, or supervising lab technicians to do so',
      'Analyzing data from experiments to inform future product development',
      'Routine data collection, administrative tasks, and production of goods for sale would not qualify.',
    ],
  },
  [JobGroupEnum.SOFTWARE_ENGINEER]: {
    summary:
      'A software engineer likely spends the majority of their time conducting, supporting, and supervising R&D-qualifying activities like these:',
    bullets: [
      'Designing, developing, and documenting new software',
      'Providing technical feedback to junior engineers',
      'Developing, testing, and improving APIs with external softwares',
      'Participating in meetings to discuss R&D progress and goals',
      'Customizing off-the-shelf software, using pre-existing website development templates, and implementing internal IT systems would not qualify.',
    ],
  },
  [JobGroupEnum.SUPPLY_CHAIN_LOGISTICS]: {
    summary:
      'A supply chain professional may spends part of their time supporting, supervising, and conducting R&D-qualifying activities like these:',
    bullets: [
      'Procuring raw materials for prototyping, testing, and analyzing R&D activities',
      'Designing and testing new products and processes',
      'Analyzing product data to inform product and process design',
      'Managing the supply chain for the production of goods for sale, executing quality control procedures, and performing routine data entry would not qualify.',
    ],
  },
  [JobGroupEnum.USER_RESEARCHER]: {
    summary:
      'A user researcher may spend much of their time supporting, conducting, and supervising R&D-qualifying activities like these:',
    bullets: [
      'Conducting user research to validate hypotheses and inform product development',
      'Creating user research reports to provide feedback to the R&D team',
      'Analyzing user behavior to identify pain points, to suggest new features and product opportunities',
      'Conducting market research to understand industry trends and competitors, creating user tutorials, and performing general administrative tasks would not qualify.',
    ],
  },
};

export enum BadStandingReasonEnum {
  BILLING_PAYMENT_FAILED = 'billing_payment_failed',
  ON_HOLD = 'on_hold',
}

export enum NonRdActivityEnum {
  FUNDRAISING_OR_FINANCE = 'fundraising_or_finance',
  NO_TECHNICAL_UNCERTAINTIES = 'no_technical_uncertainties',
  CODE_NO_UNCERTAINTIES = 'code_no_uncertainties',
  HUMAN_RESOURCES = 'human_resources',
  PARTNERSHIPS_OR_THIRD_PARTY_SOFTWARE = 'partnerships_or_third_party_software',
  CUSTOMER_SUPPORT = 'customer_support',
  ROUTINE_PRODUCTION_ISSUES = 'routine_production_issues',
  RESEARCH_NO_IMPROVEMENTS = 'research_no_improvements',
  EDUCATIONAL_DOCUMENTATION = 'educational_documentation',
  NON_CORE_WORK_ACTIVITIES = 'non_core_work_activities',
  HIGH_LEVEL_STRATEGY_WORK = 'high_level_strategy_work',
  PROJECT_TRACKING = 'project_tracking',
  UI_IMPROVEMENTS = 'ui_improvements',
  GOVERNMENT_APPROVALS = 'government_approvals',
  BUSINESS_TRAVEL = 'business_travel',
}

export const NonRdActivitiesEnumToString: {
  [key in NonRdActivityEnum]: string;
} = {
  [NonRdActivityEnum.FUNDRAISING_OR_FINANCE]:
    'Fundraising or finance and accounting work unrelated to product development, or building internal tools for finance work',
  [NonRdActivityEnum.NO_TECHNICAL_UNCERTAINTIES]:
    'Designing, building and executing sales, marketing or PR content, strategies, processes or websites with no technical uncertainties',
  [NonRdActivityEnum.CODE_NO_UNCERTAINTIES]:
    'Writing straightforward code with no uncertainties',
  [NonRdActivityEnum.HUMAN_RESOURCES]:
    'Human resources work (including performance reviews, resource allocation, interviewing and training new workers)',
  [NonRdActivityEnum.PARTNERSHIPS_OR_THIRD_PARTY_SOFTWARE]:
    'Forming partnerships or setting up and using third party software off the shelf',
  [NonRdActivityEnum.CUSTOMER_SUPPORT]:
    'Providing customer support, or selling to prospective customers',
  [NonRdActivityEnum.ROUTINE_PRODUCTION_ISSUES]:
    "Working on routine production issues or monitoring that don't involve technical uncertainties",
  [NonRdActivityEnum.RESEARCH_NO_IMPROVEMENTS]:
    'Conduct research or analysis that does not directly lead to product improvements or recommendations',
  [NonRdActivityEnum.EDUCATIONAL_DOCUMENTATION]:
    'Developing educational content or documentation for internal or external consumption',
  [NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES]:
    'Non core-work activities, such as participating in trade shows or conferences or all hands meetings or team fun events',
  [NonRdActivityEnum.HIGH_LEVEL_STRATEGY_WORK]:
    'High level strategy work that is conducted before technical uncertainties are well understood',
  [NonRdActivityEnum.PROJECT_TRACKING]:
    'Project tracking, scoping and planning without input into technical uncertainties',
  [NonRdActivityEnum.UI_IMPROVEMENTS]:
    "Designing or implementing pure aesthetic improvements to a product that don't directly impact functionality",
  [NonRdActivityEnum.GOVERNMENT_APPROVALS]:
    'Liaising with the government for approvals, once uncertainties are resolved',
  [NonRdActivityEnum.BUSINESS_TRAVEL]:
    'Business travel or working from home in a country outside the US',
};

export const NonRdActivitiesForJobGroup: {
  [key in JobGroupEnum]: NonRdActivityEnum[];
} = {
  [JobGroupEnum.SOFTWARE_ENGINEER]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.UI_IMPROVEMENTS,
    NonRdActivityEnum.EDUCATIONAL_DOCUMENTATION,
    NonRdActivityEnum.ROUTINE_PRODUCTION_ISSUES,
    NonRdActivityEnum.CODE_NO_UNCERTAINTIES,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.PROJECT_TRACKING,
    NonRdActivityEnum.HIGH_LEVEL_STRATEGY_WORK,
    NonRdActivityEnum.HUMAN_RESOURCES,
  ],
  [JobGroupEnum.ENGINEER]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.UI_IMPROVEMENTS,
    NonRdActivityEnum.EDUCATIONAL_DOCUMENTATION,
    NonRdActivityEnum.ROUTINE_PRODUCTION_ISSUES,
  ],
  [JobGroupEnum.SCIENTIST]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.PROJECT_TRACKING,
    NonRdActivityEnum.HIGH_LEVEL_STRATEGY_WORK,
    NonRdActivityEnum.HUMAN_RESOURCES,
  ],
  [JobGroupEnum.LAB_TECHNICIAN]: [NonRdActivityEnum.BUSINESS_TRAVEL],
  [JobGroupEnum.CLINICAL_OPS_PROGRAM_MANAGER]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.GOVERNMENT_APPROVALS,
    NonRdActivityEnum.PROJECT_TRACKING,
  ],
  [JobGroupEnum.DATA_SCIENTIST]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.RESEARCH_NO_IMPROVEMENTS,
    NonRdActivityEnum.ROUTINE_PRODUCTION_ISSUES,
    NonRdActivityEnum.CODE_NO_UNCERTAINTIES,
    NonRdActivityEnum.FUNDRAISING_OR_FINANCE,
  ],
  [JobGroupEnum.PRODUCT_MANAGER]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NO_TECHNICAL_UNCERTAINTIES,
    NonRdActivityEnum.PROJECT_TRACKING,
    NonRdActivityEnum.HIGH_LEVEL_STRATEGY_WORK,
    NonRdActivityEnum.EDUCATIONAL_DOCUMENTATION,
    NonRdActivityEnum.RESEARCH_NO_IMPROVEMENTS,
  ],
  [JobGroupEnum.DESIGNER]: [
    NonRdActivityEnum.UI_IMPROVEMENTS,
    NonRdActivityEnum.RESEARCH_NO_IMPROVEMENTS,
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.HIGH_LEVEL_STRATEGY_WORK,
    NonRdActivityEnum.HUMAN_RESOURCES,
  ],
  [JobGroupEnum.USER_RESEARCHER]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.RESEARCH_NO_IMPROVEMENTS,
  ],
  [JobGroupEnum.QA_TESTING]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.ROUTINE_PRODUCTION_ISSUES,
  ],
  [JobGroupEnum.MANUFACTURING]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.ROUTINE_PRODUCTION_ISSUES,
  ],
  [JobGroupEnum.CEO]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.PARTNERSHIPS_OR_THIRD_PARTY_SOFTWARE,
    NonRdActivityEnum.HIGH_LEVEL_STRATEGY_WORK,
    NonRdActivityEnum.HUMAN_RESOURCES,
    NonRdActivityEnum.NO_TECHNICAL_UNCERTAINTIES,
    NonRdActivityEnum.FUNDRAISING_OR_FINANCE,
  ],
  [JobGroupEnum.FOUNDER]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.PARTNERSHIPS_OR_THIRD_PARTY_SOFTWARE,
    NonRdActivityEnum.HIGH_LEVEL_STRATEGY_WORK,
    NonRdActivityEnum.HUMAN_RESOURCES,
    NonRdActivityEnum.NO_TECHNICAL_UNCERTAINTIES,
    NonRdActivityEnum.FUNDRAISING_OR_FINANCE,
  ],
  [JobGroupEnum.CUSTOMER_FACING_ROLE]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.EDUCATIONAL_DOCUMENTATION,
    NonRdActivityEnum.CUSTOMER_SUPPORT,
    NonRdActivityEnum.NO_TECHNICAL_UNCERTAINTIES,
  ],
  [JobGroupEnum.REGULATORY_AFFAIRS]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.GOVERNMENT_APPROVALS,
  ],
  [JobGroupEnum.MARKETING]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.UI_IMPROVEMENTS,
    NonRdActivityEnum.NO_TECHNICAL_UNCERTAINTIES,
  ],
  [JobGroupEnum.OPS_PROGRAM_MANAGER]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.PROJECT_TRACKING,
    NonRdActivityEnum.RESEARCH_NO_IMPROVEMENTS,
    NonRdActivityEnum.PARTNERSHIPS_OR_THIRD_PARTY_SOFTWARE,
    NonRdActivityEnum.HIGH_LEVEL_STRATEGY_WORK,
    NonRdActivityEnum.FUNDRAISING_OR_FINANCE,
  ],
  [JobGroupEnum.BUSINESS]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.HIGH_LEVEL_STRATEGY_WORK,
    NonRdActivityEnum.RESEARCH_NO_IMPROVEMENTS,
    NonRdActivityEnum.PARTNERSHIPS_OR_THIRD_PARTY_SOFTWARE,
    NonRdActivityEnum.NO_TECHNICAL_UNCERTAINTIES,
    NonRdActivityEnum.FUNDRAISING_OR_FINANCE,
  ],
  [JobGroupEnum.EXECUTIVE_SUPPORT]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.PROJECT_TRACKING,
  ],
  [JobGroupEnum.COMMUNICATIONS]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
  ],
  [JobGroupEnum.SUPPLY_CHAIN_LOGISTICS]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.PROJECT_TRACKING,
  ],
  [JobGroupEnum.HUMAN_RESOURCES]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.HUMAN_RESOURCES,
  ],
  [JobGroupEnum.LEGAL]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
  ],
  [JobGroupEnum.OTHER]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
  ],
  [JobGroupEnum.SCIENTIST_MANAGEMENT]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
    NonRdActivityEnum.PROJECT_TRACKING,
    NonRdActivityEnum.HIGH_LEVEL_STRATEGY_WORK,
    NonRdActivityEnum.HUMAN_RESOURCES,
  ],
  [JobGroupEnum.MEDICAL]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.GOVERNMENT_APPROVALS,
    NonRdActivityEnum.PROJECT_TRACKING,
  ],
  [JobGroupEnum.ADVISOR_BOARD_MEMBER]: [
    NonRdActivityEnum.HIGH_LEVEL_STRATEGY_WORK,
    NonRdActivityEnum.NO_TECHNICAL_UNCERTAINTIES,
    NonRdActivityEnum.FUNDRAISING_OR_FINANCE,
  ],
  [JobGroupEnum.CONTENT]: [
    NonRdActivityEnum.BUSINESS_TRAVEL,
    NonRdActivityEnum.NON_CORE_WORK_ACTIVITIES,
  ],
};

export enum RdQuestionEnum {
  BUSINESS_DESCRIPTION = 'business_description',
  ENERGY_RESEARCH = 'energy_research',
  RENT_OR_LEASE_COMPUTER = 'rent_or_lease_computer',
  SUPPLIES = 'supplies',
  QRE_AMOUNTS_SINGULAR = 'qre_amounts_singluar',
  QRE_AMOUNTS_PLURAL = 'qre_amounts_plural',
  GROSS_RECEIPTS_SINGULAR = 'gross_receipts_singular',
  GROSS_RECEIPTS_PLURAL = 'gross_receipts_plural',
  SCIENTIFIC_RESEARCH = 'scientific_research',
  ACQUIRED_OR_DISPOSED = 'acquired_or_disposed',
  SUBSIDIARY = 'subsidiary',
  MORE_THAN_5M_REVENUE = 'more_than_5m_revenue',
}

export const RdQuestionEnumToString: { [key in RdQuestionEnum]: string } = {
  [RdQuestionEnum.BUSINESS_DESCRIPTION]:
    'We’ve drafted a description of your business and R&D activities. Please review it and make any necessary edits.',
  [RdQuestionEnum.SCIENTIFIC_RESEARCH]:
    'Did you fund any basic scientific research (i.e. research that has no short term commercial use) through a university?',
  [RdQuestionEnum.ENERGY_RESEARCH]:
    'Did you conduct or fund energy-related research through either contractors or a third party?',
  [RdQuestionEnum.RENT_OR_LEASE_COMPUTER]:
    'Did you spend any amount on cloud computing services (eg. AWS) for development (not production) purposes?',
  [RdQuestionEnum.SUPPLIES]:
    'Did you spend any amount on supplies or materials for your R&D work?',
  [RdQuestionEnum.QRE_AMOUNTS_SINGULAR]:
    'Have you filed for the federal R&D credit in the past year? If so, enter the Qualified Research Expenses amounts from the year that you filed.',
  [RdQuestionEnum.QRE_AMOUNTS_PLURAL]:
    'Have you filed for the federal R&D credit in the past {nbYears} years? If so, enter the Qualified Research Expenses amounts from the years that you filed.',
  [RdQuestionEnum.GROSS_RECEIPTS_SINGULAR]:
    'What was your total income (ie. gross receipts) from the last year? Income includes revenue, interest payments etc.',
  [RdQuestionEnum.GROSS_RECEIPTS_PLURAL]:
    'What was your total income (ie. gross receipts) from the last {nbYears} years? Income includes revenue, interest payments etc. Enter $0 if you had no income.',
  [RdQuestionEnum.ACQUIRED_OR_DISPOSED]:
    "Have you ever acquired a company (in whole or in part), disposed of a portion of your business (e.g. sold a division), or restructured your business's corporate structure (eg. changed from LLC to C corp)?",
  [RdQuestionEnum.SUBSIDIARY]:
    "Does your company share any common control with another company through any of the following ways: Your company has an ownership stake in another company that does R&D, your company is a subsidiary of another company, or a majority owner (>50% ownership of your company) of your company also has a majority stake in any other company? An 'owner' can be either a company or an individual investor etc.",
  [RdQuestionEnum.MORE_THAN_5M_REVENUE]:
    'Have you made more than $5,000,000 in revenue in {programYear}?',
};

export enum RdActivityEnum {
  WRITING_TECHNICAL_DOCUMENTS = 'writing_technical_documents',
  PROVIDING_SOFTWARE_FEEDBACK = 'providing_software_feedback',
  QA_TESTING = 'qa_testing',
  WRITING_CODE = 'writing_code',
  MANUFACTURING_TECHNIQUES = 'manufacturing_techniques',
  DEVELOP_SCIENTIFIC_PROCESSES = 'develop_scientific_processes',
  MAINTAINING_EQUIPMENT = 'maintaining_equipment',
  RECOMMENDATION_RESEARCH = 'recommendation_research',
  EXPERIMENT_RESULT_DOCUMENTS = 'experiment_result_documents',
  CORE_TECHNOLOGY_DESIGNS = 'core_technology_designs',
  TECHNICAL_DEVELOPMENT_MANAGEMENT = 'technical_development_management',
  CORE_TECHNOLOGY_MEETINGS = 'core_technology_meetings',
  PROTOTYPING = 'prototyping',
  USER_EXPERIENCE_DESIGN = 'user_experience_design',
  BETA_TESTING = 'beta_testing',
  RESEARCH_SUPPLIES = 'research_supplies',
  DESIGNING_EXPERIMENTS = 'designing_experiments',
}

export const RdActivityEnumToString: { [key in RdActivityEnum]: string } = {
  [RdActivityEnum.WRITING_TECHNICAL_DOCUMENTS]:
    'Writing specs and product / technical requirements documents',
  [RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK]:
    'Provide feedback on design, functionality, or business logic of core product or internal tools critical to the core product, including participating in design reviews',
  [RdActivityEnum.QA_TESTING]:
    'Conducting or designing quality assurance testing of core platform under development (includes unit testing, regression testing, performance testing, and functional testing)',
  [RdActivityEnum.WRITING_CODE]:
    'Writing code for the core product or for internal tools critical to the core product',
  [RdActivityEnum.MANUFACTURING_TECHNIQUES]:
    'Designing and developing new production or manufacturing processes and techniques, or improving existing ones',
  [RdActivityEnum.DEVELOP_SCIENTIFIC_PROCESSES]:
    'Develops new scientific or clinical processes or workflows',
  [RdActivityEnum.MAINTAINING_EQUIPMENT]:
    'Calibrating and maintaining equipment used for qualifying research',
  [RdActivityEnum.RECOMMENDATION_RESEARCH]:
    'Research, analysis, or modeling that directly leads to recommendations for improving the core product',
  [RdActivityEnum.EXPERIMENT_RESULT_DOCUMENTS]:
    'Writing emails or documents to document experiment results, lab reports or meeting outcomes directly related to core product development',
  [RdActivityEnum.CORE_TECHNOLOGY_DESIGNS]:
    'Creating system designs, algorithms, software, formulas or mechanisms for the core product technology',
  [RdActivityEnum.TECHNICAL_DEVELOPMENT_MANAGEMENT]:
    'Direct management or supervision of technical development',
  [RdActivityEnum.CORE_TECHNOLOGY_MEETINGS]:
    'Having meetings to make project decisions related to the functionality or design of the core product or related to discussing technical progress and open technical questions',
  [RdActivityEnum.PROTOTYPING]:
    'Building pilot or prototype models of the product',
  [RdActivityEnum.USER_EXPERIENCE_DESIGN]:
    'Creating user experience designs, interactions, or interfaces affecting core product functionality or usability',
  [RdActivityEnum.BETA_TESTING]:
    'Supporting beta testing of an early version of the product  by gathering user feedback',
  [RdActivityEnum.RESEARCH_SUPPLIES]:
    'Procuring supplies used for qualifying research',
  [RdActivityEnum.DESIGNING_EXPERIMENTS]:
    'Designing and executing experiments, pilots, trials, or betas to test hypotheses and inform product development',
};

export const RdActivitiesForJobGroup: {
  [key in JobGroupEnum]: RdActivityEnum[];
} = {
  [JobGroupEnum.SOFTWARE_ENGINEER]: [
    RdActivityEnum.WRITING_TECHNICAL_DOCUMENTS,
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.QA_TESTING,
    RdActivityEnum.CORE_TECHNOLOGY_DESIGNS,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.DESIGNING_EXPERIMENTS,
    RdActivityEnum.PROTOTYPING,
  ],
  [JobGroupEnum.ENGINEER]: [
    RdActivityEnum.WRITING_TECHNICAL_DOCUMENTS,
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.QA_TESTING,
    RdActivityEnum.CORE_TECHNOLOGY_DESIGNS,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.PROTOTYPING,
    RdActivityEnum.DESIGNING_EXPERIMENTS,
  ],
  [JobGroupEnum.SCIENTIST]: [
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.DESIGNING_EXPERIMENTS,
    RdActivityEnum.CORE_TECHNOLOGY_DESIGNS,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.DEVELOP_SCIENTIFIC_PROCESSES,
  ],
  [JobGroupEnum.DESIGNER]: [
    RdActivityEnum.WRITING_TECHNICAL_DOCUMENTS,
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.USER_EXPERIENCE_DESIGN,
    RdActivityEnum.BETA_TESTING,
    RdActivityEnum.PROTOTYPING,
  ],
  [JobGroupEnum.LAB_TECHNICIAN]: [
    RdActivityEnum.EXPERIMENT_RESULT_DOCUMENTS,
    RdActivityEnum.QA_TESTING,
    RdActivityEnum.MAINTAINING_EQUIPMENT,
    RdActivityEnum.DESIGNING_EXPERIMENTS,
  ],
  [JobGroupEnum.CLINICAL_OPS_PROGRAM_MANAGER]: [
    RdActivityEnum.DESIGNING_EXPERIMENTS,
    RdActivityEnum.EXPERIMENT_RESULT_DOCUMENTS,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.DEVELOP_SCIENTIFIC_PROCESSES,
  ],
  [JobGroupEnum.DATA_SCIENTIST]: [
    RdActivityEnum.RECOMMENDATION_RESEARCH,
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.DESIGNING_EXPERIMENTS,
  ],
  [JobGroupEnum.PRODUCT_MANAGER]: [
    RdActivityEnum.WRITING_TECHNICAL_DOCUMENTS,
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.EXPERIMENT_RESULT_DOCUMENTS,
    RdActivityEnum.USER_EXPERIENCE_DESIGN,
    RdActivityEnum.RECOMMENDATION_RESEARCH,
    RdActivityEnum.BETA_TESTING,
  ],
  [JobGroupEnum.USER_RESEARCHER]: [
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.DESIGNING_EXPERIMENTS,
    RdActivityEnum.BETA_TESTING,
  ],
  [JobGroupEnum.QA_TESTING]: [
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.DESIGNING_EXPERIMENTS,
    RdActivityEnum.QA_TESTING,
  ],
  [JobGroupEnum.MANUFACTURING]: [
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.PROTOTYPING,
    RdActivityEnum.MANUFACTURING_TECHNIQUES,
  ],
  [JobGroupEnum.CEO]: [
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.TECHNICAL_DEVELOPMENT_MANAGEMENT,
    RdActivityEnum.BETA_TESTING,
  ],
  [JobGroupEnum.FOUNDER]: [
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.TECHNICAL_DEVELOPMENT_MANAGEMENT,
    RdActivityEnum.BETA_TESTING,
    RdActivityEnum.RECOMMENDATION_RESEARCH,
  ],
  [JobGroupEnum.CUSTOMER_FACING_ROLE]: [
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.BETA_TESTING,
  ],
  [JobGroupEnum.REGULATORY_AFFAIRS]: [
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
  ],
  [JobGroupEnum.MARKETING]: [
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.BETA_TESTING,
    RdActivityEnum.RECOMMENDATION_RESEARCH,
  ],
  [JobGroupEnum.OPS_PROGRAM_MANAGER]: [
    RdActivityEnum.EXPERIMENT_RESULT_DOCUMENTS,
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.RECOMMENDATION_RESEARCH,
  ],
  [JobGroupEnum.BUSINESS]: [
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.RECOMMENDATION_RESEARCH,
  ],
  [JobGroupEnum.EXECUTIVE_SUPPORT]: [
    RdActivityEnum.EXPERIMENT_RESULT_DOCUMENTS,
  ],
  [JobGroupEnum.COMMUNICATIONS]: [RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK],
  [JobGroupEnum.SUPPLY_CHAIN_LOGISTICS]: [
    RdActivityEnum.PROTOTYPING,
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.RESEARCH_SUPPLIES,
  ],
  [JobGroupEnum.MEDICAL]: [
    RdActivityEnum.DESIGNING_EXPERIMENTS,
    RdActivityEnum.WRITING_TECHNICAL_DOCUMENTS,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
    RdActivityEnum.DESIGNING_EXPERIMENTS,
    RdActivityEnum.DEVELOP_SCIENTIFIC_PROCESSES,
  ],
  [JobGroupEnum.ADVISOR_BOARD_MEMBER]: [
    RdActivityEnum.PROVIDING_SOFTWARE_FEEDBACK,
    RdActivityEnum.CORE_TECHNOLOGY_MEETINGS,
  ],
  [JobGroupEnum.HUMAN_RESOURCES]: [],
  [JobGroupEnum.LEGAL]: [],
  [JobGroupEnum.OTHER]: [],
  [JobGroupEnum.SCIENTIST_MANAGEMENT]: [],
  [JobGroupEnum.CONTENT]: [],
};

export const USStates = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Federated States of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Island',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export enum SurveyNameEnum {
  REQUALIFICATION = 'requalification',
  ONBOARDING = 'onboarding',
  COMPANY_QUALIFICATION = 'company_qualification',
  SECTION_139_QUALIFICATION = 'section_139_qualification',
  SECTION_139_QUALIFICATION_V2 = 'section_139_qualification_v2',
  SECTION_139_QUALIFICATION_STAGING = 'section_139_qualification_staging',
  ONBOARDING_STAGING = 'onboarding_staging',
  REQUALIFICATION_STAGING = 'requalification_staging',
  MAGIC_MONEY_QUALIFICATION = 'magic_money_qualification',
  WORK_FROM_HOME_ACCOUNTABLE_PLAN = 'work_from_home_accountable_plan',
  STUDENT_LOAN_REPAYMENT = 'student_loan_repayment',
  SECTION_139 = 'section_139',
  KNOW_YOUR_CUSTOMER_APPLICATION = 'know_your_customer_application',
  EXPENSE_QUALIFICATION = 'expense_qualification',
  EXPENSE_QUALIFICATION_COMPANY_DETAIL = 'expense_classification_company_detail',
  EXPENSE_QUALIFICATION_VENDOR_EXPENSES = 'expense_classification_vendor_expenses',
  NEGOTIATIONS_ENTRY = 'negotiations_entry',
  NEGOTIATIONS_NEW_VENDOR_DETAIL = 'negotiations_new_vendor_detail',
  NEGOTIATIONS_EXISTING_VENDOR_DETAIL = 'negotiations_existing_vendor_detail',
  NEGOTIATIONS_NEW_VENDOR_PLAN_DETAIL = 'negotiations_new_vendor_plan_detail',
  NEGOTIATIONS_EXISTING_VENDOR_PLAN_DETAIL = 'negotiations_existing_vendor_plan_detail',
  BULK_NEGOTIATIONS_INTRO = 'bulk_negotiations_intro',
  BULK_NEGOTIATIONS_EXISTING_VENDOR = 'bulk_negotiations_existing_vendor',
  BULK_NEGOTIATIONS_NEW_VENDOR = 'bulk_negotiations_new_vendor',
  PAYROLL_SYSTEM_GAP = 'payroll_system_gap',
  ONBOARDING_COMPANY_DETAILS = 'onboarding_company_details',
  RD_CREDIT_AUTOQUAL_PAYROLL_DETAILS = 'rd_credit_autoqual_payroll_details',
  RD_CREDIT_AUTOQUAL_OVERVIEW = 'rd_credit_autoqual_rd_credit_overview',
  RD_CREDIT_AUTOQUAL_FOUR_PART_TEST = 'rd_credit_autoqual_four_part_test',
  RD_CREDIT_AUTOQUAL_ACTIVITIES = 'rd_credit_autoqual_rd_activities',
  UNIFIED_QUALIFICATION_SURVEY = 'unified_qualification_survey',
  ERC_QUALIFYING_QUESTIONS_SURVEY = 'erc_qualification_questions_survey',
  ERC_EMPLOYEE_DETAILS_SURVEY = 'erc_employee_details_survey',
  ERC_UPLOAD_TAX_RETURN = 'erc_upload_tax_return_survey',
  ERC_UPLOAD_PAYROLL_TAX_RETURN = 'erc_upload_payroll_tax_return_survey',
  RETIREMENT_PLAN_BUSINESS_DETAILS_SURVEY = 'retirement_plan_business_details_survey',
  RETIREMENT_PLAN_NHCE_DETAILS_SURVEY = 'retirement_plan_nhce_details_survey',
  UNIFIED_ACCOUNT_CREATION_SURVEY = 'unified_account_creation_survey',
  DISABLED_ACCESS_CREDIT_SURVEY = 'disabled_access_credit_survey',
  SMALL_BUSINESS_HEALTHCARE_SURVEY = 'small_business_healthcare_credit_survey',
  GENERAL_BUSINESS_DETAILS = 'general_business_details_survey',
  UNIFIED_DISABLED_ACCESS_CREDIT_SURVEY = 'unified_disabled_access_credit_survey',
  UNIFIED_SMALL_BUSINESS_HEALTHCARE_SURVEY = 'unified_small_business_healthcare_credit_survey',
  UNIFIED_RD_SUPPLIES_AND_SERVICES = 'unified_rd_supplies_and_services',
  UNIFIED_RD_COMPANY_DETAILS_SURVEY = 'unified_rd_company_details_survey',
  UNIFIED_RETIREMENT_PLAN = 'unified_retirement_plan_survey',
  UNIFIED_RETIREMENT_COMPANY_DETAILS = 'unified_retirement_company_details',
  UNIFIED_RETIREMENT_BENEFIT_DETAILS = 'unified_retirement_benefit_details',
  BENEFICIAL_OWNERSHIP_INFORMATION_REPORT = 'beneficial_ownership_information_report_survey',
}

export enum IssuedSurveyStatusEnum {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  SUBMITTED = 'submitted',
}

export enum EmployeeTokenPurposeEnum {
  PAID_SICK_LEAVE = 'paid-sick-leave',
  S139 = 'S139',
}

export enum InputFormatTypeEnum {
  CURRENCY = 'currency',
  YEAR = 'year',
}

export interface ProgramCardData {
  name: ProgramNameEnum;
  taxYear: number;
  stage: ProgramStageEnum;
  subStage: ProgramSubStageEnum | null;
  id: number;
  linkedPayrollSystem: boolean;
  optedInProducts: ProductTypeEnum[] | null;
  qualificationStatus?: string;
  orderForm?: OrderForm | null;
  filingCreditType?: ExpectedCreditTypeEnum;
}

export interface DashboardData {
  showRequalSurvey: boolean;
  showProcurementSurvey: boolean;
  showMeMagicMoney: 'qualification' | 'selection' | null;
  showCreditAdvanceCard: boolean;
  isOnboardingDone: boolean;
  dashboardState: DashboardState;
  programCards: ProgramCardData[];
}

export type OtherAccountState =
  | 'OTHER_PROBLEM'
  | 'DISQUALIFIED'
  | 'BILLING_FAILED'
  | 'ON_HOLD';
export type DashboardState = OtherAccountState | 'NORMAL_DASHBOARD';

export const TaxTypesList = [
  { value: '1120', name: 'C Corp (File Form 1120)' },
  { value: '1120s', name: 'S Corp (File Form 1120-S)' },
  { value: '1065', name: 'Partnership (File Form 1065)' },
  {
    value: 'personal',
    name: 'Single Member LLC (File with personal taxes)',
  },
  {
    value: '990t',
    name: 'Tax-exempt (File Form 990-T)',
  },
];

// Used in old onboarding flow.
export const LegacyTaxTypesList = [
  { value: '1120', name: 'Corporation (File form 1120)' },
  { value: '1120s', name: 'S corporation (File form 1120s)' },
  { value: '1065', name: 'Partnership (File form 1065)' },
  {
    value: 'personal',
    name: 'Single member LLC (File with personal taxes)',
  },
];

export const IndustriesList = [
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel & Fashion',
  'Architecture & Planning',
  'Arts and Crafts',
  'Automotive',
  'Aviation & Aerospace',
  'Banking',
  'Biotechnology',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies and Equipment',
  'Capital Markets',
  'Chemicals',
  'Civic & Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer & Network Security',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense & Space',
  'Design',
  'Education Management',
  'E-Learning',
  'Electrical/Electronic Manufacturing',
  'Entertainment',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food & Beverages',
  'Food Production',
  'Fund-Raising',
  'Furniture',
  'Gambling & Casinos',
  'Glass, Ceramics & Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design',
  'Health, Wellness and Fitness',
  'Higher Education',
  'Hospital & Health Care',
  'Hospitality',
  'Human Resources',
  'Import and Export',
  'Individual & Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology and Services',
  'Insurance',
  'International Affairs',
  'International Trade and Development',
  'Internet',
  'Investment Banking',
  'Investment Management',
  'Judiciary',
  'Law Enforcement',
  'Law Practice',
  'Legal Services',
  'Legislative Office',
  'Leisure, Travel & Tourism',
  'Libraries',
  'Logistics and Supply Chain',
  'Luxury Goods & Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing and Advertising',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Devices',
  'Medical Practice',
  'Mental Health Care',
  'Military',
  'Mining & Metals',
  'Motion Pictures and Film',
  'Museums and Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers',
  'Non-Profit Organization Management',
  'Oil & Energy',
  'Online Media',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging and Containers',
  'Paper & Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training & Coaching',
  'Program Development',
  'Public Policy',
  'Public Relations and Communications',
  'Public Safety',
  'Publishing',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate',
  'Recreational Facilities and Services',
  'Religious Institutions',
  'Renewables & Environment',
  'Research',
  'Restaurants',
  'Retail',
  'Security and Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing and Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation and Localization',
  'Transportation/Trucking/Railroad',
  'Utilities',
  'Venture Capital & Private Equity',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine and Spirits',
  'Wireless',
  'Writing and Editing',
];

export enum CompanyDetailsEnum {
  adminJobTitle = 'Your role at the company',
  businessType = 'Business type',
  businessPhoneNumber = 'Business phone number',
  doingBusinessAs = 'DBA (doing business as; if applicable)',
  fiscalYearEndDate = 'End of tax year',
  industry = 'Industry',
  legalName = 'Company name',
  secondaryEmail = 'Secondary contact',
  taxType = 'How your business is taxed',
  website = 'Website',
  yearFounded = 'Year of incorporation',
}

export const AdminJobTitleOptions = [
  { name: 'CEO', value: 'CEO' },
  {
    name: 'Technical leadership (eg. CTO, head of product)',
    value: 'Technical leadership',
  },
  {
    name: 'Business leadership (eg. Head of ops, CFO)',
    value: 'Business leadership',
  },
  { name: 'Other', value: 'Other' },
];

export const AdminJobTitleOptionsBusinessStep = [
  { name: 'An executive', value: 'Executive' },
  {
    name: 'An executive assistant',
    value: 'Executive Assistant',
  },
  {
    name: 'An HR professional',
    value: 'HR Professional',
  },
  {
    name: 'A tax preparer working on behalf of a client',
    value: 'Tax Preparer',
  },
  { name: 'Other', value: 'Other' },
];

export enum ProductTypeEnum {
  HIGH_YIELD_SAVINGS = 'high_yield_savings',
  VENDOR_MANAGEMENT = 'vendor_management',
  BENEFITS = 'benefits',
}

export const ProductTypeEnumToString: {
  [key in ProductTypeEnum]: string;
} = {
  [ProductTypeEnum.BENEFITS]: 'Benefits',
  [ProductTypeEnum.HIGH_YIELD_SAVINGS]: 'High Yield Savings',
  [ProductTypeEnum.VENDOR_MANAGEMENT]: 'Procurement',
};

export enum EmployeeNumberSourceEnum {
  ER_RECORDS_2021 = '2021_employment_records', // highest fidelity
  AVG_EMPLOYEE_COUNT_FROM_ONBOARDING = 'average_employee_count_from_onboarding',
  ER_RECORDS_2020 = '2020_employment_records', // lowest fidelity
}

export const GovernmentProgramBundle = [
  ProgramNameEnum.FED_RD_TAX,
  ProgramNameEnum.PAID_SICK_LEAVE,
  ProgramNameEnum.ERC,
  ProgramNameEnum.STATE_RD_AZ,
  ProgramNameEnum.STATE_RD_CA,
  ProgramNameEnum.STATE_RD_GA,
  ProgramNameEnum.STATE_RD_MA,
];

export const ExpenseClassificationProcessingSteps = [
  'Overview & Integrations',
  'Company Details',
  'Supplies & Services',
  'Employees',
  'Expert Review',
];

export const TAXYEAR2021 = 2021;

export enum ExpenseClassificationFlowSteps {
  OVERVIEW = 1,
  COMPANY_DETAILS = 2,
  RD_EXPENSES = 3,
  RD_EMPLOYEES = 4,
  MS_REVIEW = 5,
}

export enum RdVendorExpenseType {
  CLOUD = 'cloud',
  SERVICES_SUPPLY = 'services_supply',
}

export enum PayrollStatus {
  NOT_CONNECTED = 'not-connected',
  CONNECTED = 'connected',
  GAP = 'gap',
}

export const UnifiedAccountCreationSteps = [
  'Business',
  'Taxes',
  'Payroll Data',
  'Business Activities',
];

export enum AccountCreationStepsIndex {
  LANDING_PAGE = 0,
  BUSINESS = 1,
  CONTACTS = 2,
  TAXES = 3,
  PAYROLL_DATA = 4,
  BUSINESS_ACTIVITIES = 5,
  PROGRAMS = 6,
}

export enum UnifiedAccountCreationStepsIndex {
  LANDING_PAGE = 0,
  BUSINESS = 1,
  TAXES = 2,
  PAYROLL_DATA = 3,
  BUSINESS_ACTIVITIES = 4,
  PROGRAMS = 5,
}

export enum CompanyDataEnum {
  ACCOUNT_STATE = 'accountState',
  ACCREDITATION_STATUS = 'accreditationStatus',
  ADMIN_EMAIL = 'adminEmail',
  ADMIN_JOB_TITLE = 'adminJobTitle',
  ADMIN_NAME = 'adminName',
  ADMIN_FIRST_NAME = 'adminFirstName',
  ADMIN_LAST_NAME = 'adminLastName',
  BAD_STANDING_REASON = 'badStandingReason',
  BILLING_SCHEDULES = 'billingSchedules',
  BUSINESS_TYPE = 'businessType',
  BUSINESS_PHONE_NUMBER = 'businessPhoneNumber',
  CODAT_CUSTOMER_ID = 'codatCustomerId',
  DESCRIPTION = 'description',
  EMAIL_VERIFIED = 'emailVerified',
  EMPLOYMENT_RECORDS = 'employmentRecords',
  EXPECTED_FILING_DATES = 'expectedFilingDates',
  FINANCIAL_INFORMATION = 'financialInformation',
  FISCAL_YEAR_END_DATE = 'fiscalYearEndDate',
  ID = 'id',
  INDUSTRY = 'industry',
  ISSUED_SURVEYS = 'issuedSurveys',
  LAST_UPDATED = 'lastUpdated',
  LEGAL_NAME = 'legalName',
  LINKED_ACCOUNTING_SYSTEM = 'linkedAccountingSystem',
  LINKED_PAYROLL_SYSTEM = 'linkedPayrollSystem',
  LOGO_FILE_NAME = 'logoFileName',
  LOGO_URL = 'logoUrl',
  MISC = 'misc',
  NAME = 'name',
  NUM_EMPLOYEES = 'numEmployees',
  ORDER_FORMS = 'orderForms',
  PAYMENT_METHOD_ON_FILE = 'paymentMethodOnFile',
  PLAN = 'plan',
  PLAN_PERCENTAGE = 'planPercentage',
  PROGRAMS = 'programs',
  QUALIFICATION_QUESTIONS_BY_YEAR = 'qualificationQuestionsByYear',
  SECONDARY_EMAIL = 'secondaryEmail',
  SIGNUP_STAGE = 'signupStage',
  SIGNUP_TYPE = 'signupType',
  STRIPE_CONNECTED_ACCOUNT_ID = 'stripeConnectedAccountId',
  STRIPE_CUSTOMER_ID = 'stripeCustomerId',
  SURVEY_ANSWERS = 'surveyAnswers',
  TAX_ID = 'taxId',
  TAX_TYPE = 'taxType',
  TEST_ACCOUNT = 'testAccount',
  TREASURY_MANAGEMENT_ENABLED = 'treasuryManagementEnabled',
  WEBSITE = 'website',
  YEAR_FOUNDED = 'yearFounded',
}

export enum FinancialTxnTypeEnum {
  FEE = 'fee',
  ADVANCE = 'advance',
  WITHDRAWAL = 'withdrawal',
  PAYBACK_MS_BALANCE = 'payback_via_mainstreet_balance',
  PAYBACK_BANK_ACCOUNT = 'payback_via_bank_account',
  WRITE_OFF = 'write_off',
  FORGIVEN = 'forgiven',
}

export enum FinancialTxnStatusEnum {
  FEE_PAID = 'fee_paid', // Could include partial refund
  FEE_REFUNDED = 'fee_refunded', // Full refunds
  FEE_FAILED = 'fee_failed', // Failed charge, to be updated to paid once we get the notification
  CHARGE_PENDING = 'charge_pending', // For ACH payments, charge takes 1-5 days to settle
  WITHDRAWAL_TRANSFER_CREATED = 'withdrawal_transfer_created', // Applied to debit when Stripe transfer created
  WITHDRAWAL_TRANSFER_REVERSED = 'withdrawal_transfer_reversed', // Applied to debit when Stripe reports transfer reversed
  WITHDRAWAL_PAYOUT_CREATED = 'withdrawal_payout_created', // Applied to debit when Stripe payout object created
  WITHDRAWAL_PAYOUT_PAID = 'withdrawal_payout_paid', // Applied to debit when Stripe reports payout paid
  WITHDRAWAL_PAYOUT_FAILED = 'withdrawal_payout_failed', // Applied to debit when Stripe reports payout failed
  ADVANCE_PENDING = 'advance_pending', // Applied to credit txns when credit relies on a paid invoice
  ADVANCE_CREATED = 'advance_created', // Applied to credit/referral_credit when created
  ADVANCE_CANCELLED = 'advance_cancelled', // Applied to credit/referral_credit when cancelled, retains history
  IGNORED = 'ignored', // May apply to both a fee and advance if the difference is positive for the customer
  PAYBACK = 'payback',
  N_A = 'n/a',
}

export enum PayoutAccountTxnTypeEnum {
  CREDIT = 'credit',
  DEBIT = 'debit',
  REFERRAL_CREDIT = 'referral_credit',
}

export enum PayoutAccountTxnStatusEnum {
  TRANSFER_CREATED = 'transfer_created', // Applied to debit when Stripe transfer created
  TRANSFER_REVERSED = 'transfer_reversed', // Applied to debit when Stripe reports transfer reversed
  PAYOUT_CREATED = 'payout_created', // Applied to debit when Stripe payout object created
  PAYOUT_PAID = 'payout_paid', // Applied to debit when Stripe reports payout paid
  PAYOUT_FAILED = 'payout_failed', // Applied to debit when Stripe reports payout failed
  PAYBACK = 'payback', // Applied to debit when MainStreet balance is used to cover some/all payback
  CREDIT_PENDING = 'credit_pending', // Applied to credit txns when credit relies on a paid invoice
  CREDIT_CREATED = 'credit_created', // Applied to credit/referral_credit when created
  CREDIT_CANCELLED = 'credit_cancelled', // Applied to credit/referral_credit when cancelled, retains history
  RECLAIMED_BALANCE = `reclaimed_balance`, // Applied to debit for zeroing out a customers MS Balance
}

export enum EducationalOnboardingSteps {
  GETTING_STARTED = 0,
  QUALIFY = 1,
  RD_ASSESSMENT = 2,
  MS_REVIEW = 3,
  FILES_IRS = 4,
  USE_CREDITS = 5,
}

export interface OrderFormPromotionMappingResponse {
  created: Date;
  id: number;
  lastUpdated: Date;
  orderFormId: number;
  promotionName: OrderFormPromotionNameEnum;
  source: string;
}

export enum OrderFormPromotionNameEnum {
  OFF_1000 = 'OFF_1000', // $1,000 off MST fee
  OFF_500 = 'OFF_500', // $500 off MST fee
  BNPL = 'BNPL', // Buy now pay later
  BNPL_AND_500_OFF = 'BNPL_AND_500_OFF', // Buy now pay later and $500 off MST fee
  BOGO = 'BOGO', // Buy 2023, get 2022 free
}

export enum AttestationEventTypeEnum {
  YEAR_END_ASSESSMENT_RETIREMENT_PLAN = 'year_end_assessment_retirement_plan_complete',
  YEAR_END_ASSESSMENT_BUSINESS_HEALTHCARE = 'year_end_assessment_business_healthcare_complete',
  YEAR_END_ASSESSMENT_DISABLED_ACCESS = 'year_end_assessment_disabled_access_complete',
  YEAR_END_ASSESSMENT_EMPLOYEES_COMPLETE = 'year_end_assessment_employees_complete',
  YEAR_END_ASSESSMENT_COMPANY_DETAILS_COMPLETE = 'year_end_assessment_company_details_complete',
  YEAR_END_ASSESSMENT_VENDOR_EXPENSES_COMPLETE = 'year_end_assessment_vendor_expenses_complete',
  CLIENT_REVIEW_DOCUMENTS_DOWNLOADED = 'client_review_documents_downloaded',
}

type AttestationEventTypeEnumKeys = keyof typeof AttestationEventTypeEnum;
export type AttestationEventType =
  (typeof AttestationEventTypeEnum)[AttestationEventTypeEnumKeys];

export enum PayrollTierEnum {
  TIER_1 = 'tier_1',
  TIER_2 = 'tier_2',
  TIER_3 = 'tier_3',
  INCOME_TIER = 'income_tier',
}

export interface ProgramLedgerTxnUpdateRequest {
  amountCents?: number;
  occurred?: string;
  operation?: string;
  metadata?: ProgramLedgerMetadata;
}

export interface ProgramLedgerMetadata {
  wageTaxCents?: number;
  socialSecurityTaxCredit?: number;
  medicareTaxCents?: number;
}

export enum TaxInputValueEnum {
  SOCIAL_SECURITY = 'social_security',
  MEDICARE = 'medicare',
}

export interface AddProgramLedgerTxnRequest {
  companyId: number;
  programId: number;
  amountCents: number;
  occurred?: string;
}

export enum FileTypes {
  CSV = 'text/csv',
  PDF = 'application/pdf',
  GIF = 'image/gif',
  JPG = 'image/jpeg',
  PNG = 'image/png',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export const CommonEmailClients = [
  'gmail.com',
  'yahoo.com',
  'outlook.com',
  'hotmail.com',
  'icloud.com',
  'mac.com',
  'aol.com',
  'protonmail.com',
];

export enum AccountantScope {
  taxYear = 'taxYear',
  program = 'program',
}

export interface CompanyAccessToken {
  token: string;
  adminEmail: string;
  scope: string;
}

export const RIPPLING_TAX_YEAR_KEY = 'rippling-tax-year';
export const RIPPLING_IMPORT_TYPE_KEY = 'rippling-import-type';
export const RIPPLING_REDIRECT_PATHNAME = 'rippling-redirect-pathname';
export const RIPPLING_REDIRECT_SEARCH = 'rippling-redirect-search';
export const PARTNER_REFERRAL_SSU_KEY = 'partner-ssu';

export enum Partners {
  XERO = 'xero',
}

export enum TermNames {
  CORE = 'terms-of-service',
  PROMOTIONAL = 'promotional-terms-of-service',
}
