import { Flex, Text } from 'component-library';
import React from 'react';

interface RipplingUploadInstructionsProps {
  payrollYear: number;
}

export const RipplingUploadInstructions = ({
  payrollYear,
}: RipplingUploadInstructionsProps) => {
  return (
    <Flex gap={8} direction='column'>
      <Flex alignItems='flex-start'>
        <Flex.Cell basis={40}>
          <img
            src={`${process.env.PUBLIC_URL}/images/disclaimer.png`}
            alt='Upload Disclaimer'
          />
        </Flex.Cell>
        <Flex.Cell>
          <Text variant='medium'>
            <a
              href='https://docs.google.com/spreadsheets/d/1_aW1pn8dVBYWRn1MySbThIYWhlqf1fU7d-NhjWJHDyA/export?gid=1842529205#gid=1842529205'
              target='_blank'
              rel='noopener noreferrer'
            >
              Please fill out this spreadsheet
            </a>{' '}
            with your payroll data for the {payrollYear} tax year, then upload
            the completed spreadsheet.
          </Text>
          <br />
          <Text variant='medium'>
            <a
              href='https://mainstreet-help.force.com/help/s/article/Rippling-Payroll-Report'
              target='_blank'
              rel='noopener noreferrer'
            >
              How do I get a payroll report from Rippling?
            </a>{' '}
          </Text>
          <br />
          <Text variant='medium'>Your payroll report should include:</Text>
        </Flex.Cell>
      </Flex>
      <Flex>
        <Flex.Cell basis={30}>
          <></>
        </Flex.Cell>
        <Flex.Cell>
          <Text>
            • all US-based employees and contractors you paid in {payrollYear}
          </Text>
          <Text>• their job titles</Text>
          <Text>• the state in which they work</Text>
          <Text>
            • the total amount you paid each individual in {payrollYear}
          </Text>
          <Text>
            • any benefits contributions you made for each employee in{' '}
            {payrollYear}
          </Text>
        </Flex.Cell>
      </Flex>
    </Flex>
  );
};
