import {
  GraphCmsQuestionIdEnum,
  GraphCmsQuestionIdToAnswers,
} from 'lib/constants';
import { QualificationQuestionsByYear } from 'lib/interfaces';

export const completedCompanyDetailsSection = (
  qualificationQuestionsByYear: QualificationQuestionsByYear,
  taxYear: number,
) => {
  return (
    qualificationQuestionsByYear?.[taxYear][
      GraphCmsQuestionIdEnum.TIME_TRACKING
    ] !== undefined
  );
};

export const completedCloudSpendSection = (
  qualificationQuestions: QualificationQuestionsByYear,
  taxYear: number,
) => {
  const cloudSpendQuestion =
    qualificationQuestions[taxYear][
      GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_CLOUD
    ];
  const cloudSpendYesAnswer =
    GraphCmsQuestionIdToAnswers[GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_CLOUD]
      .YES;
  const cloudSpendRdPercentageQuestion =
    qualificationQuestions[taxYear][
      GraphCmsQuestionIdEnum.WHAT_PERCENT_CLOUD_RD
    ];

  // If the answer to the cloud spend question is yes, and the RD percentage question is answered, the section is complete
  return (
    cloudSpendQuestion !== undefined &&
    (cloudSpendQuestion !== cloudSpendYesAnswer ||
      (cloudSpendQuestion === cloudSpendYesAnswer &&
        cloudSpendRdPercentageQuestion !== undefined))
  );
};

export const completedSuppliesSpendSection = (
  qualificationQuestions: QualificationQuestionsByYear,
  taxYear: number,
) => {
  const suppliesSpendQuestion =
    qualificationQuestions[taxYear][
      GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_SUPPLIES
    ];
  const suppliesSpendYesAnswer =
    GraphCmsQuestionIdToAnswers[
      GraphCmsQuestionIdEnum.SPEND_ANY_AMOUNT_SUPPLIES
    ].YES;
  const suppliesSpendRdPercentageQuestion =
    qualificationQuestions[taxYear][
      GraphCmsQuestionIdEnum.WHAT_PERCENT_SUPPLIES_RD
    ];

  // If the answer to the supplies spend question is yes, and the RD percentage question is answered, the section is complete
  return (
    suppliesSpendQuestion !== undefined &&
    (suppliesSpendQuestion !== suppliesSpendYesAnswer ||
      (suppliesSpendQuestion === suppliesSpendYesAnswer &&
        suppliesSpendRdPercentageQuestion !== undefined))
  );
};

export const completedSuppliesAndServicesSection = (
  qualificationQuestions: QualificationQuestionsByYear,
  taxYear: number,
) => {
  return (
    completedCloudSpendSection(qualificationQuestions, taxYear) &&
    completedSuppliesSpendSection(qualificationQuestions, taxYear)
  );
};
