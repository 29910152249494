import React from 'react';
import { observer } from 'mobx-react';
import { Button, Flex, Link, Modal, Text } from 'component-library';
import { useTaxCreditsStores, useCommonStores } from 'stores/useStores';
import { FINCH_PAYROLL_SYSTEMS, Page, PayrollTierEnum } from 'lib/constants';
import { formatPayrollString } from 'lib/helpers';

interface MissedQuarterModalProps {
  missedQuarters: number;
  programId: number;
}

export const MissedQuarterModal = observer(
  ({ missedQuarters, programId }: MissedQuarterModalProps) => {
    const { form8974, redemption } = useTaxCreditsStores();
    const { app } = useCommonStores();
    const { payrollTier, payrollProvider } = form8974;
    const payrollProviderDisplay = payrollProvider
      ? FINCH_PAYROLL_SYSTEMS.find((p) => p.id === payrollProvider)
          ?.displayName || formatPayrollString(payrollProvider)
      : 'payroll software ';

    const isTier1or2Provider =
      payrollTier === PayrollTierEnum.TIER_1 ||
      payrollTier === PayrollTierEnum.TIER_2;

    return (
      <Modal
        showModal={redemption.showMissedQuarterDeadlineModal}
        maxWidth={504}
      >
        <Flex padding={24} direction='column' gap={16} alignItems='center'>
          <img
            src={`${process.env.PUBLIC_URL}/images/tax-credit-dashboard-module/form-941.svg`}
            alt='Form 941 Icon'
            width={100}
          />
          <Text
            text='Past deadline - action needed'
            variant='medium'
            size={23}
          />
          <Text
            textAlign='center'
            text={`Have you redeemed your R&D credits for the last ${missedQuarters} quarters? Please click below to start redeeming now, or update your records with MainStreet.`}
            paddingBottom={24}
          />
          <Button
            label='Set up redemption'
            onClick={() => {
              redemption.setShowMissedQuarterDeadlineModal(false);
              redemption.isUpdatingFilingDate = false;
              app.history.push(
                `/${Page.taxCredits}/${programId}/${Page.missedQuarterlyRedemptionPage}`,
              );
            }}
          />
          {isTier1or2Provider && (
            <Flex justifyContent='center' padding={[16, 24]}>
              <Text>
                <Link
                  onClick={async () => {
                    redemption.setShowMissedQuarterDeadlineModal(false);
                    await redemption.updateProgramsToCompletePayrollSetup();
                    redemption.setShowOnTimeToRedeemYourCreditModal(true);
                  }}
                >
                  We already set up our redemption with {payrollProviderDisplay}
                </Link>
              </Text>
            </Flex>
          )}
        </Flex>
      </Modal>
    );
  },
);
