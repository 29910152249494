import React from 'react';
import {
  RouteConfiguration,
  UNAUTH_ROUTE_CONFIG,
} from 'routes/RouteConfiguration';
import {
  AssessmentSurveyStepsIndex,
  EmployeeTokenPurposeEnum,
  EXPENSE_CLASSIFICATION,
  Page,
} from 'lib/constants';
import {
  useFeatureFlags,
  useCommonStores,
  useTaxCreditsStores,
} from 'stores/useStores';
import { AccountantDocumentsPage } from 'pages/tax-processing/client-review/accountant/AccountantDocumentsPage';

import { EmployeeS139Page } from 'pages/dashboard/employees/EmployeeS139Page';
import { EmployeeSickLeavePage } from 'pages/dashboard/employees/EmployeeSickLeavePage';
import { EmployeesS139Dashboard } from 'pages/dashboard/employees/EmployeesS139Dashboard';
import { EmployeesSickLeaveDashboard } from 'pages/dashboard/employees/EmployeesSickLeaveDashboard';
import DisasterReliefQualifyingBusinessFlow from 'pages/DisasterReliefQualifyingBusinessFlow';
import DisasterReliefQualifyingBusinessFlow2 from 'pages/DisasterReliefQualifyingBusinessFlow2';
import QreGuide from 'pages/QreGuide';
import { MultiOrderFormPage } from 'pages/dashboard/billing';
import { RDQualification } from 'pages/tax-processing';
import { TaxCreditsPage } from 'pages/dashboard/taxCredits/TaxCreditsPage';
import { CompanyDetailsStep } from '../pages/tax-processing/expense-classification/steps/CompanyDetails';
import { OverviewStep } from '../pages/tax-processing/expense-classification/steps/Overview';
import RDExpensesStep from '../pages/tax-processing/expense-classification/steps/RDExpenses';
import {
  DisabledAccess,
  ERCLandingPage,
  OrderForm,
  RetirementPlanCredit,
  SmallBusinessHealthcare,
  UnifiedTaxCreditsPage,
} from 'products/tax-credits';
import { EmployeesStep } from '../pages/tax-processing/expense-classification/steps/Employees';
import { ExpenseClassificationRedirect } from 'pages/tax-processing/expense-classification/ExpenseClassificationRedirect';
import { Disqualified, Qualification } from 'products/onboarding';
import { CreditEstimate } from 'products/onboarding/features/qualification/components/CreditEstimate';
import {
  MissedQuarterlyRedemptionPage,
  QuarterlyRedemptionDownloadPage,
} from 'products/tax-credits/features/redemption';
import { EmployeeRetention } from 'products/tax-credits/features/employee-retention/EmployeeRetention';
import HttpErrorPage from 'pages/HttpErrorPage';
import { Contract } from 'products/tax-credits/features/contract/Contract';
import { AssessmentSurvey } from '../products/tax-credits/features/unified-tax-credits/AssessmentSurvey/AssessmentSurvey';
import ConnectToPayroll from 'products/tax-credits/features/unified-tax-credits/assessments/ConnectToPayroll';
import { AccountantDocumentsPage as UnifiedAccountantDocumentsPage } from 'products/onboarding/features/unified-account-creation/accountant/AccountantDocumentsPage';
import { AssessmentReviewPage } from '../products/tax-credits/features/unified-tax-credits/assessments/review/AssessmentReviewPage';
import { UnifiedCreditPaymentPage } from 'components/payments/UnifiedCreditPaymentPage';
import { RedemptionPage } from '../pages/dashboard/redemption/RedemptionsPage';
import { BoirSurvey } from '../products/tax-credits/features/unified-tax-credits/boir-survey/BoirSurvey';

export const useTaxCreditsRoutes = (): RouteConfiguration[] => {
  const featureFlags = useFeatureFlags();
  const { unifiedTaxCredits } = useTaxCreditsStores();
  const assessmentSurveyRootPath = `/${Page.taxCredits}/${Page.assessment}`;
  const qualificationTaxYear = featureFlags.use2022TaxYear ? 2022 : 2021;
  const { app, companyStore } = useCommonStores();

  return [
    {
      path: `/${Page.taxCredits}`,
      render: () => <UnifiedTaxCreditsPage />,
      documentPageTitle: 'Tax Credits',
    },
    {
      path: `/${Page.taxCreditEstimate}/:tax_year`,
      render: (params) => (
        <CreditEstimate taxYear={parseInt(params.tax_year, 10)} />
      ),
      hideTopNav: true,
      hideSideNav: true,
      bypassMobilePrint: true,
      documentPageTitle: 'Credit Estimate',
    },
    {
      path: `/${Page.accountantProgram}/:program_id`,
      render: (params, query) => {
        return (
          <AccountantDocumentsPage
            programId={parseInt(params.program_id, 10) || 0}
            companyEmail={(query.company as string) || ''}
            token={(query.token as string) || ''}
          />
        );
      },
      ...UNAUTH_ROUTE_CONFIG,
    },
    {
      path: `/${Page.taxCredits}/${EXPENSE_CLASSIFICATION}/:program_id/overview`,
      render: (params) => (
        <OverviewStep
          programId={parseInt(params.program_id, 10) || 0}
          hasPayrollGap={false}
        />
      ),
      bypassMobilePrint: true,
      hideTopNav: true,
      hideSideNav: true,
      documentPageTitle: 'R&D Assessment - Overview',
    },
    {
      path: `/${Page.taxCredits}/${EXPENSE_CLASSIFICATION}/:program_id/employees`,
      render: (params) => (
        <EmployeesStep
          programId={parseInt(params.program_id, 10) || 0}
          onError={() => null}
          hasPayrollGap={false}
          possiblePayrollMigration={false}
        />
      ),
      bypassMobilePrint: true,
      hideTopNav: true,
      hideSideNav: true,
      documentPageTitle: 'R&D Assessment - Employee Details',
    },
    {
      path: `/${Page.taxCredits}/${EXPENSE_CLASSIFICATION}/:program_id/supplies-services`,
      render: (params) => (
        <RDExpensesStep
          programId={parseInt(params.program_id, 10) || 0}
          onError={() => null}
        />
      ),
      hideTopNav: true,
      hideSideNav: true,
      documentPageTitle: 'R&D Assessment - Supplies and Services',
    },
    {
      path: `/${Page.taxCredits}/${EXPENSE_CLASSIFICATION}/:program_id/company-details`,
      render: (params) => (
        <CompanyDetailsStep
          programId={parseInt(params.program_id, 10) || 0}
          onError={() => null}
        />
      ),
      bypassMobilePrint: true,
      hideTopNav: true,
      hideSideNav: true,
      documentPageTitle: 'R&D Assessment - Company Details',
    },
    {
      path: `/${Page.taxCredits}/${EXPENSE_CLASSIFICATION}/:program_id`,
      render: (params) => (
        <ExpenseClassificationRedirect
          programId={parseInt(params.program_id, 10) || 0}
        />
      ),
      hideTopNav: true,
      hideSideNav: true,
      bypassMobilePrint: true,
      exact: false,
      documentPageTitle: 'R&D Assessment',
    },
    {
      path: `/company/:company_id/employee/:employee_id/:token_purpose`,
      render: (params, query) => {
        return params.token_purpose ===
          EmployeeTokenPurposeEnum.PAID_SICK_LEAVE ? (
          <EmployeeSickLeavePage
            companyId={parseInt(params.company_id, 10) || 0}
            employeeId={parseInt(params.employee_id, 10) || 0}
            tokenPurpose={params.token_purpose || ''}
            token={(query.token as string) || ''}
          />
        ) : (
          <EmployeeS139Page
            companyId={parseInt(params.company_id, 10) || 0}
            employeeId={parseInt(params.employee_id, 10) || 0}
            tokenPurpose={params.token_purpose || ''}
            token={(query.token as string) || ''}
          />
        );
      },
      ...UNAUTH_ROUTE_CONFIG,
    },
    {
      path: `/${Page.qualifyingBusiness}/:tax_year/disaster-relief/:page`,
      render: (params) => {
        return params.page === '2' ? (
          <DisasterReliefQualifyingBusinessFlow2
            qualificationTaxYear={qualificationTaxYear}
          />
        ) : (
          <DisasterReliefQualifyingBusinessFlow />
        );
      },
    },
    // New Auto Qual Flow Routes
    {
      path: `/${Page.taxCreditQualification}`,
      render: (params) =>
        featureFlags.showUnifiedQualification ? (
          <Qualification taxYear={parseInt(params.tax_year, 10)} />
        ) : (
          <RDQualification taxYear={parseInt(params.tax_year, 10)} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.orderForm}/:programId`,
      render: () => <OrderForm />,
      hideSideNav: app.isMobile ? true : undefined,
      hideTopNav: app.isMobile ? true : undefined,
      bypassMobilePrint: true,
      documentPageTitle: 'Order Form',
    },
    {
      path: `/${Page.subscription}`,
      render: () => <Contract />,
      hideSideNav: true,
      hideTopNav: true,
      bypassMobilePrint: true,
      documentPageTitle: 'Order Form',
    },
    {
      path: `/${Page.fedQreGuide}`,
      render: () => <QreGuide />,
    },
    {
      path: `/${Page.employees}`,
      render: () => (
        <EmployeesSickLeaveDashboard
          qualificationTaxYear={qualificationTaxYear}
        />
      ),
    },
    {
      path: `/${Page.employees139Dashboard}`,
      render: () => (
        <EmployeesS139Dashboard qualificationTaxYear={qualificationTaxYear} />
      ),
    },
    {
      path: `/${Page.orderForms}/:tax_year`,
      render: (params) => (
        <MultiOrderFormPage
          qualificationTaxYear={parseInt(params.tax_year, 10) || 0}
        />
      ),
      hideSideNav: app.isMobile ? true : undefined,
      hideTopNav: app.isMobile ? true : undefined,
      bypassMobilePrint: true,
      documentPageTitle: 'All Order Forms',
    },
    {
      path: `/${Page.taxCredits}/${Page.disqualified}/:tax_year`,
      render: (params) => (
        <Disqualified taxYear={parseInt(params.tax_year, 10) || 0} />
      ),
      hideSideNav: true,
      hideTopNav: true,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.taxCredits}/:program_id/${Page.missedQuarterlyRedemptionPage}`,
      render: (params) => (
        <MissedQuarterlyRedemptionPage
          programId={parseInt(params.program_id, 10) || 0}
        />
      ),
      hideSideNav: true,
      hideTopNav: true,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.taxCredits}/:program_id/${Page.missedQuarterlyRedemptionPage}/${Page.quarterlyRedemptionDownloadPage}`,
      render: (params) => (
        <QuarterlyRedemptionDownloadPage
          programId={parseInt(params.program_id, 10) || 0}
        />
      ),
      hideSideNav: true,
      hideTopNav: true,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.taxCredits}/${Page.erc}`,
      render: () =>
        featureFlags.showERCQualification ? (
          <EmployeeRetention />
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.taxCredits}/:tax_year/${Page.retirementPlan}`,
      render: (params) =>
        featureFlags.showRetirementPlanCredit ? (
          <RetirementPlanCredit taxYear={parseInt(params.tax_year, 10) || 0} />
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.taxCredits}/:tax_year/${Page.disabledAccess}`,
      render: (params) =>
        featureFlags.showDisabledAccessCredit ? (
          <DisabledAccess taxYear={parseInt(params.tax_year, 10) || 0} />
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.taxCredits}/:tax_year/${Page.smallBusinessHealthcare}`,
      render: (params) =>
        featureFlags.showSmallBusinessHealthcareCredit ? (
          <SmallBusinessHealthcare
            taxYear={parseInt(params.tax_year, 10) || 0}
          />
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.accountantTaxYear}/:tax_year`,
      render: (params, query) => {
        return (
          <UnifiedAccountantDocumentsPage
            taxYear={parseInt(params.tax_year, 10) || 0}
            companyEmail={(query.company as string) || ''}
            token={(query.token as string) || ''}
          />
        );
      },
      ...UNAUTH_ROUTE_CONFIG,
    },
    {
      path: `/${Page.taxCredits}/${Page.assessment}/:tax_year/${Page.rdConnectPayroll}`,
      render: (params) =>
        featureFlags.showUnifiedAssessmentExperience ? (
          <ConnectToPayroll taxYear={parseInt(params.tax_year, 10) || 0} />
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.taxCredits}/${Page.assessment}/:tax_year/${Page.unifiedAssessmentReview}`,
      render: (params) => (
        <AssessmentReviewPage
          onNext={() => {
            app.history.push(
              `${assessmentSurveyRootPath}/${params.tax_year}/${Page.payment}`,
            );
            unifiedTaxCredits.setFlowIndex(AssessmentSurveyStepsIndex.PAYMENT);
          }}
          onBack={() => {
            app.history.push(
              `${assessmentSurveyRootPath}/${params.tax_year}/${Page.disabledAccess}`,
            );
            unifiedTaxCredits.setFlowIndex(
              AssessmentSurveyStepsIndex.DISABLED_ACCESS,
            );
          }}
          taxYear={parseInt(params.tax_year, 10) || 0}
        />
      ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
    },
    {
      path: `/${Page.taxCredits}/${Page.assessment}/:tax_year/${Page.payment}`,
      render: (params) => (
        <UnifiedCreditPaymentPage
          onNext={async () => {
            app.history.push(`/${Page.taxCredits}`);
          }}
          onBack={() => {
            app.history.push(
              `${assessmentSurveyRootPath}/${params.tax_year}/${Page.unifiedAssessmentReview}`,
            );
            unifiedTaxCredits.setFlowIndex(AssessmentSurveyStepsIndex.REVIEW);
          }}
          taxYear={parseInt(params.tax_year, 10) || 0}
        />
      ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
    },
    // Unified Assessment Survey
    {
      path: `/${Page.taxCredits}/${Page.assessment}/:tax_year`,
      render: (params) =>
        featureFlags.showUnifiedAssessmentExperience ? (
          <AssessmentSurvey taxYear={parseInt(params.tax_year, 10) || 0} />
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
      ...(companyStore.accessToken ? UNAUTH_ROUTE_CONFIG : {}),
    },
    {
      path: `/${Page.redemption}`,
      render: () => <TaxCreditsPage />,
      documentPageTitle: 'Tax Credits - Redemption',
    },
    {
      path: `/${Page.creditsRedemption}`,
      render: () => <RedemptionPage />,
      documentPageTitle: 'Credits Redemption',
    },
    {
      path: `/${Page.employeeRetentionCredits}`,
      render: () =>
        featureFlags.showERCCreditInEstimates ? (
          <ERCLandingPage />
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      documentPageTitle: 'Employee Retention Credit',
    },
    // Beneficial Ownership Information Report
    {
      path: `/${Page.assessments}/${Page.beneficialOwnershipInformationReport}`,
      render: (params) =>
        featureFlags.showBoirSurvey ? (
          <BoirSurvey />
        ) : (
          <HttpErrorPage errorCode={404} />
        ),
      hideSideNav: true,
      hideTopNav: true,
      exact: false,
      bypassMobilePrint: true,
      ...(companyStore.accessToken ? UNAUTH_ROUTE_CONFIG : {}),
    },
  ];
};
