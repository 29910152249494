import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core';
import {
  Animate,
  Button,
  Checkbox,
  Divider,
  Flex,
  Link,
  Modal,
  Spinner,
  Text,
  TextField,
} from 'component-library';
import { useTaxCreditsStores, useCompany } from 'stores/useStores';
import { IsValidDate, IsValidEinNumber, useEffectOnce } from 'lib/helpers';
import { ProgramNameEnum, ProgramStageEnum } from 'lib/constants';
import { FilingDateTextField } from './FilingDateTextField';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  checkbox: {
    '& label': {
      margin: 0,
    },
  },
}));

export const VerifyTaxFilingDateModal = observer(() => {
  const classes = useStyles();
  const { company } = useCompany();
  const [companyTaxId, setCompanyTaxId] = useState<string | null>(
    company.taxId,
  );
  const [taxFilingDates, setTaxFilingDates] = useState<Map<number, string>>(
    new Map(),
  );
  const [hasFilingDateError, setHasFilingDateError] = useState<
    Map<number, boolean>
  >(new Map());
  const [hasNotFiledYet, setHasNotFiledYet] = useState<Map<number, boolean>>(
    new Map(),
  );
  const { form8974, redemption } = useTaxCreditsStores();

  const clientReviewAndOnPrograms = form8974.programs
    .filter(
      (p) =>
        p.name === ProgramNameEnum.FED_RD_TAX &&
        (p.stage === ProgramStageEnum.CLIENT_REVIEW ||
          p.stage === ProgramStageEnum.FINISHED),
    )
    .sort((a, b) => b.taxYear - a.taxYear);

  const handleTaxFilingDateInput = useCallback(
    (value: string, taxYear: number) => {
      const filingDate = new Date(value);
      const currentDate = new Date();

      const filingDateError =
        !IsValidDate(filingDate) ||
        !filingDate ||
        ![taxYear + 1, taxYear + 2].includes(filingDate.getFullYear()) ||
        filingDate > currentDate;

      // validate if there is an error
      setHasFilingDateError((prevState) => {
        return new Map(prevState).set(taxYear, filingDateError);
      });

      setTaxFilingDates((prevState) => {
        return new Map(prevState).set(taxYear, value);
      });
    },
    [],
  );

  const handleHasNotFiledYetCheckbox = (taxYear: number, check: boolean) => {
    const hasAlreadyEnteredValue = taxFilingDates.has(taxYear);

    if (check && hasAlreadyEnteredValue) {
      const updatedFilingDates = new Map(taxFilingDates);
      updatedFilingDates.delete(taxYear);

      setTaxFilingDates(updatedFilingDates);
      setHasNotFiledYet((prevState) => {
        return new Map(prevState).set(taxYear, check);
      });
    } else {
      setHasNotFiledYet((prevState) => {
        return new Map(prevState).set(taxYear, check);
      });
    }
  };

  const handleCompanyTaxId = (value: string) => {
    setCompanyTaxId(value);
    form8974.setCompanyTaxId(value);
  };

  const onContinue = async () => {
    await redemption.updateTaxFilingDates(
      clientReviewAndOnPrograms,
      taxFilingDates,
    );
    if (hasNotFiledYet.size > 0 && taxFilingDates.size === 0) {
      redemption.setShowVerifyFilingDateModal(false);
      redemption.isUpdatingFilingDate = false;
    }
  };

  const mayHaveNotFiledYet = Array.from(hasNotFiledYet.values()).some(
    (value) => value === true,
  );
  const hasError = Array.from(hasFilingDateError.values()).some(
    (value) => value === true,
  );
  const hasNotFilledOutAllDates = mayHaveNotFiledYet
    ? clientReviewAndOnPrograms.length - hasNotFiledYet.size !==
      taxFilingDates.size
    : clientReviewAndOnPrograms.length !== taxFilingDates.size;

  // disable continue button if there's an error with any filing date
  const disableContinue =
    hasError ||
    !IsValidEinNumber(companyTaxId ?? '') ||
    hasNotFilledOutAllDates;

  useEffectOnce(() => {
    // check onload if filing dates already exist
    const hasFilingDate = clientReviewAndOnPrograms.filter(
      (p) => p.taxFilingDate,
    );

    if (hasFilingDate.length > 0) {
      hasFilingDate.forEach((p) => {
        const programDate =
          p.taxFilingDate &&
          new Date(p.taxFilingDate).toISOString().replace('.000Z', '');
        const filingDateTimezoneOffset = programDate && new Date(programDate);

        const filingDate =
          programDate && moment(filingDateTimezoneOffset).format('MM/DD/YYYY');

        // to validate all dates exist to enable continue button
        if (filingDate) {
          setTaxFilingDates((prevState) => {
            return new Map(prevState).set(p.taxYear, filingDate);
          });
        }
      });
    }
  });

  return (
    <Modal
      showModal={redemption.showVerifyFilingDateModal}
      closeToggle={() => redemption.setShowVerifyFilingDateModal(false)}
      maxWidth={536}
    >
      <Flex padding={24} direction='column' gap={24}>
        <Text text='Verify tax filing dates' variant='medium' size={18} />
        {redemption.errorHandingLedgerTransactions ? (
          <>
            <Text>
              We ran into some technical difficulty verifying your tax filing
              date. Please reach out to{' '}
              <Link
                external
                href='mailto:support@mainstreet.com'
                variant='medium'
              >
                support@mainstreet.com
              </Link>{' '}
              for further assistance.
            </Text>
          </>
        ) : (
          <>
            <Text text='According to our records, you may have missed your deadline to redeem your R&D credits. Please confirm your income tax filing details so we can help with your next steps.' />
            {redemption.isUpdatingFilingDate ? (
              <Animate enter={'fade-in'}>
                <Flex padding={24} justifyContent='center'>
                  <Spinner size='small' />
                </Flex>
                <Flex padding={[40, 24, 24, 24]}>
                  <Divider variant='no-bottom-margin' />
                </Flex>
              </Animate>
            ) : (
              <>
                {clientReviewAndOnPrograms.map((program, index) => {
                  const taxYear = program.taxYear;
                  const taxFilingDate = taxFilingDates.get(taxYear);
                  const hasError = hasFilingDateError.get(taxYear) || false;
                  // show hasFiledCheckbox only if program is last year and on
                  const currentYear = new Date().getFullYear();
                  const hasFiledCheck = hasNotFiledYet.get(taxYear) || false;

                  return (
                    <Flex key={index} gap={8} direction='column'>
                      {index !== 0 && <Divider />}
                      <FilingDateTextField
                        federalRDProgram={program}
                        taxFilingDate={taxFilingDate || ''}
                        handleTaxFilingDateInput={handleTaxFilingDateInput}
                        filingDateError={hasError}
                        hasFiledCheck={hasFiledCheck}
                      />
                      {taxYear >= currentYear - 1 && (
                        <Checkbox
                          className={classes.checkbox}
                          dataTestId={`${taxYear}-have-not-filed-yet-checkbox`}
                          onChange={() =>
                            handleHasNotFiledYetCheckbox(
                              taxYear,
                              !hasFiledCheck,
                            )
                          }
                          options={[
                            {
                              checked: hasFiledCheck,
                              text: `We haven't filed our ${taxYear} tax return yet.`,
                              value: 'checked',
                            },
                          ]}
                        />
                      )}
                    </Flex>
                  );
                })}
                <Divider variant='no-bottom-margin' />
                <Flex>
                  <TextField
                    dataTestId={'company-tax-id-EIN'}
                    helperText='Please confirm the EIN shown on your tax return.'
                    einNumberFormat
                    label='EIN'
                    value={companyTaxId}
                    onChange={(e) => handleCompanyTaxId(e.target.value)}
                  />
                </Flex>
                <Flex justifyContent='flex-end' gap={12}>
                  <Button
                    label='Cancel'
                    variant='outlined'
                    onClick={() => {
                      redemption.setShowVerifyFilingDateModal(false);
                    }}
                  />
                  <Button
                    label='Confirm'
                    disabled={disableContinue}
                    loading={redemption.isUpdatingFilingDate}
                    onClick={onContinue}
                  />
                </Flex>
              </>
            )}
          </>
        )}
      </Flex>
    </Modal>
  );
});
