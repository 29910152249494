import { z } from 'zod';

const Auth0OrganizationSchema = z.object({
  id: z.string(),
  name: z.string(),
});

export type Auth0Organization = z.infer<typeof Auth0OrganizationSchema>;

const OrgMembership = z.object({
  auth0_org_id: z.string(),
  company_id: z.string(),
});

export const UserJWTSchema = z.intersection(
  z.object({
    sub: z.string(),
    azp: z.string(),
  }),
  z
    .object({
      'https://mainstreet.com/org_membership': z.array(OrgMembership),
      'https://mainstreet.com/user_email': z.string(),
      'https://mainstreet.com/user_organizations': z.array(
        Auth0OrganizationSchema,
      ),
    })
    .partial(),
);
