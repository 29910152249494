import React, { useEffect, useMemo, useState } from 'react';
import {
  Animate,
  AnswerType,
  AnswerValueType,
  Color,
  Flex,
  Spinner,
  SurveyQuestion,
} from 'component-library';
import { makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useSurveyQuestions } from 'lib/useSurveyQuestions';
import {
  AttestationEventTypeEnum,
  LoadingStatusEnum,
  Page,
  ProgramNameEnum,
  ProgramStageEnum,
  ProgramSubStageEnum,
  QualificationModalStatusEnum,
  RetirementPlanCreditQuestions,
  RetirementPlanCreditQuestionsToAnswers,
  SurveyNameEnum,
} from 'lib/constants';
import {
  CmsQuestionData,
  CmsRenderTree,
  EmploymentRecordData,
} from 'lib/interfaces';
import {
  useCommonStores,
  useDashboardStores,
  useFeatureFlags,
  useLegacyClients,
  useTaxCreditsStores,
} from 'stores/useStores';
import _ from 'lodash';
import { SurveyFlowContainer } from 'products/tax-credits/components';
import { QualificationStatusModal } from '../../../retirement-plan/components';
import DOMPurify from 'dompurify';
import { useEffectOnce } from 'lib/helpers';
import { PrefillAlert } from '../../components/PrefillAlert';
import { AccumulatedModal } from '../../components';
import { SurveyAttestation } from 'pages/tax-processing/expense-classification/components/';
import { useExpenseClassificationAnswerPrefill } from 'lib/useExpenseClassificationAnswerPrefill';
import { EmployerContributionTable } from './EmployerContributionTable';
import { buildCommonHandlers, handleContinueText } from '../commonHandlers';
import renderTreeJson from '../../../../../../hygraph/renderTree/retirementPlanBusinessDetails.json';

// Maybe pull out to util
const scrollIntoViewOnKeyDown = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth',
  });
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  mainContent: {
    margin: '0 auto',
    position: 'relative',
  },
  hasDropdown: {
    position: 'relative',
    zIndex: 2,
  },
  surveyListText: {
    '& > ul': {
      margin: 0,
      padding: '0 24px 16px',

      '& > li': {
        paddingBottom: '0 8px 8px',
        fontSize: '15px',
        lineHeight: '24px',
        color: Color.neutral._80,
      },
    },
  },
}));

interface UnifiedRetirementPlanProps {
  onNext: () => void;
  onBack: () => void;
  taxYear: number;
}

export const UnifiedRetirementPlan: React.FC<UnifiedRetirementPlanProps> =
  observer(({ taxYear, onNext, onBack }) => {
    const classes = useStyles();
    const [isDQd, setIsDQd] = useState<boolean>(false);
    const { app, auth, chatbot, companyStore } = useCommonStores();
    const { surveyFlow, unifiedTaxCredits } = useTaxCreditsStores();
    const featureFlags = useFeatureFlags();
    const [hasConfirmedAnswers, setHasConfirmedAnswers] =
      useState<boolean>(false);
    const [employees, setEmployees] = useState<EmploymentRecordData[]>([]);
    const [dataImportStatus, setDataImportStatus] = useState<
      LoadingStatusEnum | undefined
    >(undefined);
    const [hasLoadedSurveyAnswers, setHasLoadedSurveyAnswers] = useState(false);
    const { modules } = useDashboardStores();
    const [hasContributionInTaxYear, setHasContributionInTaxYear] =
      useState<boolean>(false);
    const [loadingPrefill, setLoadingPrefill] = useState(true);

    const allSurveysViewed = useMemo(() => {
      return companyStore.company.programs
        .filter((program) => program.taxYear === taxYear)
        .every(
          (program) =>
            program.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_SURVEY_SKIPPED ||
            program.subStage ===
              ProgramSubStageEnum.EXPENSE_CLASSIFICATION_READY_TO_SUBMIT ||
            (program.subStage === null &&
              program.stage === ProgramStageEnum.DISQUALIFIED),
        );
    }, [companyStore.company.programs, taxYear]);

    const program = useMemo(() => {
      return companyStore.company.programs.find(
        (item) =>
          item.taxYear === taxYear &&
          item.name === ProgramNameEnum.FED_RETIREMENT_CREDIT,
      );
    }, [companyStore.company.programs, taxYear]);

    const { client } = useLegacyClients();

    const surveyName: SurveyNameEnum =
      SurveyNameEnum.UNIFIED_RETIREMENT_COMPANY_DETAILS;

    const {
      isSurveyComplete,
      isLoading,
      surveyAnswers,
      questionsToRender,
      addSurveyAnswers,
      saveAnswers,
      addSurveyAnswersWithoutRerender,
    } = useSurveyQuestions(
      surveyName,
      taxYear,
      undefined,
      renderTreeJson as CmsRenderTree,
    );

    useEffectOnce(async () => {
      if (!companyStore.accessToken) {
        // create program if doesn't exist
        await surveyFlow.createTaxCreditProgram(
          taxYear,
          ProgramNameEnum.FED_RETIREMENT_CREDIT,
        );
        // update program stage to expense_classification onload
        // TODO: Does this need to be awaited? This useEffect already takes a
        //       long time to resolve. It has to happen after the program
        //       creation fallback, but it can be done in parallel with the
        //       loading status check
        await unifiedTaxCredits.updateProgramStageOnSurveyEntry(
          taxYear,
          ProgramNameEnum.FED_RETIREMENT_CREDIT,
        );

        // Get Payroll Loading Status
        const resp = await client.CheckCreditEstimateLoaded();
        const loadingStatus = resp.data?.loadingStatus;
        setDataImportStatus(loadingStatus as LoadingStatusEnum);
      }

      // The pre-fill endpoint doesn't return the new prefill questions, so...
      await client.SetYeaPrefill(taxYear);
      // ...we have to refresh the company in order to get the pre-filled questions
      await app.common.companyStore.refreshCurrentCompany();

      setLoadingPrefill(false);

      if (featureFlags.showShareAssessment) {
        unifiedTaxCredits.setShareAssessmentOnNext(onNext);
      }
    });

    useEffect(() => {
      if (!program) return;

      const { employmentRecords } = companyStore.company;

      const w2Employees = employmentRecords.filter(
        (employee) =>
          employee.employmentType === 'w2' &&
          employee.taxYear === program.taxYear,
      );

      setEmployees(w2Employees);
    }, [program, companyStore.company]);

    useEffect(() => {
      if (!companyStore.accessToken) return;
      if (!program) return;

      const { qualificationQuestionsByYear } = companyStore.company;

      if (featureFlags.showShareAssessment && !hasLoadedSurveyAnswers) {
        const taxYearQuestions = qualificationQuestionsByYear?.[taxYear];
        if (taxYearQuestions) {
          const answers = Object.entries(taxYearQuestions).map(
            ([questionId, answerValue]) => ({
              questionId,
              answerValue,
            }),
          );

          addSurveyAnswersWithoutRerender(answers);
          setHasLoadedSurveyAnswers(true);
        }
      }
    }, [
      companyStore.company,
      companyStore.accessToken,
      companyStore.company.qualificationQuestionsByYear,
      program,
      taxYear,
      featureFlags.showShareAssessment,
      hasLoadedSurveyAnswers,
      addSurveyAnswersWithoutRerender,
    ]);

    useEffect(() => {
      // scroll page down as user answers each question
      // NOTE: This doesn't work well when going back and editing answers
      scrollIntoViewOnKeyDown();

      // checking survey answers to display qualification modal
      surveyFlow.checkQualificationStatus(
        surveyAnswers,
        SurveyNameEnum.UNIFIED_RETIREMENT_PLAN,
      );

      const hasRetirementDQ = surveyFlow.allQualificationStatuses.find(
        (status) =>
          status.status === QualificationModalStatusEnum.UNIFIED_RETIREMENT_DQ,
      );

      if (hasRetirementDQ) {
        setIsDQd(true);
      } else {
        setIsDQd(false);
      }

      const hasEmployeeContribution =
        surveyAnswers[
          RetirementPlanCreditQuestions.HAS_EMPLOYER_CONTRIBUTIONS
        ] ===
        RetirementPlanCreditQuestionsToAnswers[
          RetirementPlanCreditQuestions.HAS_EMPLOYER_CONTRIBUTIONS
        ].YES;

      if (hasEmployeeContribution) {
        setHasContributionInTaxYear(true);
      } else {
        setHasContributionInTaxYear(false);
      }
    }, [surveyAnswers, surveyFlow]);

    const { handleOnContinue, handleOnDisqualified } = buildCommonHandlers(
      surveyFlow,
      unifiedTaxCredits,
      companyStore,
      auth,
      modules,
      app,
      client,
      taxYear,
      program,
      QualificationModalStatusEnum.UNIFIED_RETIREMENT_DQ,
      AttestationEventTypeEnum.YEAR_END_ASSESSMENT_RETIREMENT_PLAN,
      hasConfirmedAnswers,
      featureFlags.saveYeaSurveyAttestation,
      onNext,
      saveAnswers,
    );

    // Prefill answers from other EC/Qual questions
    useExpenseClassificationAnswerPrefill(
      surveyName,
      program || null,
      addSurveyAnswersWithoutRerender,
    );

    const continueText = handleContinueText(
      companyStore.accessToken,
      allSurveysViewed,
      'Continue to Small Business Healthcare Credit',
    );

    const handleAnswerChange = (
      question: CmsQuestionData,
      answerValue: AnswerValueType,
      key: string,
    ) => {
      question.answerValue = answerValue;
      addSurveyAnswers(question.id, key, [
        { questionId: question.id, answerValue: answerValue },
      ]);
      // save answers per question answered
      if (
        question.answerType === 'multiple_select' ||
        question.answerType === 'multiple_choice' ||
        question.answerType === 'yes_or_no' ||
        question.answerType === 'dropdown_select'
      ) {
        saveAnswers && saveAnswers();
      }
    };

    const renderQuestions = () =>
      Object.keys(questionsToRender).map((key) => {
        return questionsToRender[key].map((question) => {
          const subtitleList =
            question.subtitle && question.subtitle.includes('<ul>');
          return (
            <Animate
              key={question.id}
              enter={['fade-in', 'from-bottom']}
              duration={0.5}
              className={`${
                question.answerType === 'dropdown_select'
                  ? classes.hasDropdown
                  : ''
              }`}
            >
              <SurveyQuestion
                dataTestId={question.id}
                answerType={question.answerType as AnswerType}
                text={question.text}
                subtitle={
                  subtitleList ? (
                    <div
                      className={classes.surveyListText}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(question.subtitle),
                      }}
                    />
                  ) : (
                    question.subtitle
                  )
                }
                answerOptions={question.answerIDs}
                onChange={(answer) => handleAnswerChange(question, answer, key)}
                onBlur={() => saveAnswers && saveAnswers()}
                placeholder={question.placeholder}
                answerValue={question.answerValue}
                tooltip={question.tooltip}
                questionTextSize={app.isMobile ? 15 : 18}
                questionSubtitleSize={app.isMobile ? 13 : 15}
                withCardMargin={false}
                helpLinkText={question.helperText}
                chatBotQuestion={chatbot.isAiQuestionEnabled(
                  question.aiQuestion,
                )}
                chatBotPrompt={() =>
                  chatbot.autoSendAiMessage(question.aiQuestion)
                }
                onHelpLinkClick='https://mainstreet1.my.site.com/help/s/article/Retirement-Plan-Startup-Cost'
                error={
                  question.id ===
                    RetirementPlanCreditQuestions.HOW_MUCH_SPENT_STARTUP_COST &&
                  question.answerValue === 0
                }
                errorText={
                  question.id ===
                    RetirementPlanCreditQuestions.HOW_MUCH_SPENT_STARTUP_COST &&
                  question.answerValue === 0
                    ? 'This value cannot be $0'
                    : ''
                }
              />
            </Animate>
          );
        });
      });

    const showEmployerContributionTable =
      isSurveyComplete && !isDQd && hasContributionInTaxYear;

    const survey = (
      <>
        {isLoading || loadingPrefill ? (
          <Spinner size='small' />
        ) : (
          !_.isEmpty(questionsToRender) && renderQuestions()
        )}
        {isDQd && (
          <QualificationStatusModal
            showModal={surveyFlow.showQualificationStatusModal}
            modalOnClick={async () => {
              if (
                surveyFlow.allQualificationStatuses.find(
                  (status) =>
                    status.status ===
                    QualificationModalStatusEnum.UNIFIED_RETIREMENT_DQ,
                )
              ) {
                await handleOnDisqualified();
              } else {
                surveyFlow.setShowQualificationStatusModal(false);
                onNext();
              }
            }}
            programName={ProgramNameEnum.FED_RETIREMENT_CREDIT}
          />
        )}
        {showEmployerContributionTable && (
          <EmployerContributionTable
            programTaxYear={taxYear}
            employees={
              dataImportStatus === LoadingStatusEnum.IN_PROGRESS
                ? []
                : employees
            }
            setEmployees={setEmployees}
            onError={() => {
              console.log('onError');
            }}
            isPayrollConnected={
              companyStore.company.linkedPayrollSystem &&
              !!companyStore.company.misc?.payrollSystem
            }
            isPayrollPending={
              dataImportStatus === LoadingStatusEnum.IN_PROGRESS
            }
            isRetirementTable
          />
        )}
      </>
    );

    return (
      <Flex
        direction='column'
        className={classes.root}
        data-testid={'unified-retirement-plan'}
      >
        <Flex className={classes.mainContent} direction='column'>
          <Animate enter={'fade-in'}>
            <SurveyFlowContainer
              title='Retirement Plan Credit'
              onContinue={handleOnContinue}
              isDisabled={
                (showEmployerContributionTable &&
                  dataImportStatus === LoadingStatusEnum.IN_PROGRESS) ||
                !isSurveyComplete ||
                (featureFlags.saveYeaSurveyAttestation && !hasConfirmedAnswers)
              }
              isLoading={surveyFlow.surveyContinueLoading}
              continueText={continueText}
              currentPage={Page.retirementPlan}
              onBack={companyStore.accessToken ? undefined : onBack}
              onSkip={() =>
                surveyFlow.skipSurveyStep(
                  taxYear,
                  program?.id ?? -1,
                  ProgramNameEnum.FED_RETIREMENT_CREDIT,
                  onNext,
                )
              }
            >
              <Flex direction='column' gap={24}>
                <PrefillAlert />
                {survey}
                {featureFlags.saveYeaSurveyAttestation && (
                  <SurveyAttestation
                    checked={hasConfirmedAnswers}
                    onAttestation={() =>
                      setHasConfirmedAnswers(!hasConfirmedAnswers)
                    }
                    disabled={
                      showEmployerContributionTable &&
                      dataImportStatus === LoadingStatusEnum.IN_PROGRESS
                    }
                  />
                )}
              </Flex>
            </SurveyFlowContainer>
          </Animate>
        </Flex>
        <AccumulatedModal
          programName='Retirement Plan'
          subtitle="Ready to uncover more credits? Let's continue and maximize your benefits."
        />
      </Flex>
    );
  });
