import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Document, InactiveProgramStages } from 'lib/interfaces';
import {
  Flex,
  Color,
  Text,
  Card,
  CardHeader,
  Expandable,
  Icon,
  IconEnum,
} from 'component-library';
import { DocumentList } from 'pages/dashboard/documents';
import { MainStreetDocumentsColumns } from 'pages/dashboard/documents/components/documentList/MainStreetDocumentsColumns';
import { CentsToDisplayString } from 'lib/helpers';
import { Program } from '../../../../../entities/Program';
import { AccountantScope } from '../../../../../lib/constants';

interface Step {
  title: string;
  subtitle: string;
  substeps: string[];
}

const steps: Step[] = [
  {
    title:
      'Form 8881, Credit for Small Employer Pension Plan Startup Costs and Auto-Enrollment',
    subtitle: 'You need to make the following updates:',
    substeps: [
      'Input the correct business taxpayer name and federal identification number at the top of Form 8881.',
      'The tax credits from partnerships and S-Corporations on Form 8881, Part I, Line 7, Part II, Line 10 and Part III, Line 14 are intentionally left blank. Please update if applicable.',
      'Check the applicable box on Form 3800, Part III (usually box A for current year credit generated from non-passive business activity).',
      'Input the credit amount on Form 8881, Part I, Line 8 to Form 3800, Part III, Line 1j, if applicable.',
      'Input the credit amount on Form 8881, Part II, Line 11 to Form 3800, Part III, Line 1dd, if applicable.',
      'Input the credit amount on Form 8881, Part III, Line 15 to Form 3800, Part III, Line 1ee, if applicable.',
      'Reduce deductions to account for these tax credits.',
    ],
  },
  {
    title: 'Form 8826, Disabled Access Credit',
    subtitle: 'You need to make the following updates:',
    substeps: [
      'Input the correct business taxpayer name and federal identification number at the top of Form 8826.',
      'The tax credit from partnerships and S-Corporations on Form 8826, Line 7 is intentionally left blank. Please update if applicable.',
      'Check the applicable box in Part III (usually box A for current year credit generated from non-passive business activity).',
      'Input the credit amount on Form 8826, Line 8 to Form 3800, Part III, Line 1e.',
      'Reduce deductions to account for this tax credit.',
    ],
  },
  {
    title: 'Form 8941, Credit for Small Employer Health Insurance Premiums',
    subtitle: 'You need to make the following updates:',
    substeps: [
      'Input the correct business taxpayer name and federal identification number at the top of Form 8941.',
      'The tax credit from partnerships and S-Corporations on Form 8941, Line 15 is intentionally left blank. Please update if applicable.',
      'Check the applicable box in Part III (usually box A for current year credit generated from non-passive business activity).',
      'Input the credit amount on Form 8941, Line 16 to Form 3800, Part III, Line 4h.',
      'Reduce deductions to account for this tax credit.',
    ],
  },
  {
    title: 'Form 6765, R&D Credit',
    subtitle: 'You need to make the following updates:',
    substeps: [
      'Input the correct business taxpayer name and federal identification number at the top of Form 6765.',
      'Add the amount on Form 6765, Line 30 to Form 3800, Part III, Line 1c.',
      'Add the information from Form 3800 to Form 6765, Line 35.',
      'Reduce deductions to account for the R&D credit, if applicable.',
    ],
  },
];

const useStyles = makeStyles(() => ({
  steps: {
    maxWidth: '900px',
    gap: '40px',
    zIndex: 1,
  },
  expandedStep: {
    borderBottom: `1px solid ${Color.neutral._20}`,
  },
  cell: {
    padding: '16px',
    whiteSpace: 'unset',
  },
  iconSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 16px',
  },
}));

interface DisplayProps {
  taxYear: number;
  documents: Document[];
  companyName: string;
  token?: string;
  companyEmail?: string;
  scopeLevel?: AccountantScope;
  programs: Program[];
}

export const AccountantDocuments = ({
  taxYear,
  companyName,
  documents,
  token,
  companyEmail,
  scopeLevel,
  programs,
}: DisplayProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(Array(steps.length).fill(false));
  const expand = (i: number) => {
    const newExpanded = [...expanded];
    newExpanded[i] = !newExpanded[i];
    setExpanded(newExpanded);
  };

  const applicablePrograms = programs.filter(
    (p) => p.taxYear === taxYear && !InactiveProgramStages.includes(p.stage!),
  );

  const totalCreditAmountCents = applicablePrograms.reduce(
    (acc, p) => acc + p.creditAmountCents,
    0,
  );
  return (
    <Flex className={classes.steps} direction='column'>
      <Flex gap={8}>
        <Text variant='medium' size={32} text={`${companyName} can claim `} />
        <Text
          variant='medium'
          size={32}
          text={CentsToDisplayString(totalCreditAmountCents, 0, 0)}
          color={Color.green._60}
        />
        <Text
          variant='medium'
          size={32}
          text={` across ${applicablePrograms.length} credit${
            applicablePrograms.length === 1 ? '' : 's'
          }`}
        />
      </Flex>
      <Card>
        <CardHeader title='Next Steps' noBgColor />
        {steps.map((step, i) => (
          <>
            <div key={step.title} onClick={() => expand(i)}>
              <Flex padding={24} justifyContent='space-between'>
                <Text text={step.title} size={15} variant='medium' />
                <Icon
                  name={expanded ? IconEnum.chevron_up : IconEnum.chevron_down}
                  onClick={() => expand(i)}
                />
              </Flex>
            </div>
            <Expandable expand={expanded[i]} className={classes.expandedStep}>
              <Flex padding={[0, 24, 24, 24]} direction='column'>
                <Text size={15} text={step.subtitle} paddingBottom={8} />
                <Flex padding={0} direction='column'>
                  <ol>
                    {step.substeps.map((substep) => (
                      <li key={substep}>
                        <Text size={15} text={substep} paddingBottom={8} />
                      </li>
                    ))}
                  </ol>
                </Flex>
              </Flex>
            </Expandable>
          </>
        ))}
      </Card>
      <DocumentList
        dataTestId={'accountant-documents'}
        title={`Documents for ${taxYear} tax year`}
        documents={documents}
        token={token}
        companyEmail={companyEmail}
        loading={false}
        columns={MainStreetDocumentsColumns(
          classes,
          [],
          token,
          companyEmail,
          scopeLevel,
        )}
      />
    </Flex>
  );
};
